import React, { Component } from "react";
import Slider from "react-slick";
import './SliderSection.css';
import slide1 from '../Images/slides/slide1.webp';
import slide2 from '../Images/slides/slide2.webp';
import slide3 from '../Images/slides/slide3.webp';
import slide4 from '../Images/slides/slide4.webp';
import slide5 from '../Images/slides/slide5.webp';
import slide6 from '../Images/slides/slide6.webp';
import slide7 from '../Images/slides/slide7.webp';
import slide8 from '../Images/slides/slide8.webp';
import slide9 from '../Images/slides/slide9.webp';

export default class SliderSection extends Component {
  render() {
    const settings = {
      infinite: true,
      autoplay: true,
      speed: 500,
      autoplaySpeed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false, // Disable arrows
    };

    return (
      <div className="SliderSection">
        <Slider {...settings}>
          <div>
            <img src={slide1} alt="slide1" width="100%" height="100%" className="max-w-400" />
          </div>
          <div>
            <img src={slide2} alt="slide2" width="100%" height="100%" className="max-w-400" />
          </div>
          <div>
            <img src={slide3} alt="slide3" width="100%" height="100%" className="max-w-400" />
          </div>
          <div>
            <img src={slide4} alt="slide4" width="100%" height="100%" className="max-w-400" />
          </div>
          <div>
            <img src={slide5} alt="slide5" width="100%" height="100%" className="max-w-400" />
          </div>
          <div>
            <img src={slide6} alt="slide6" width="100%" height="100%" className="max-w-400" />
          </div>
          <div>
            <img src={slide7} alt="slide7" width="100%" height="100%" className="max-w-400" />
          </div>
          <div>
            <img src={slide8} alt="slide8" width="100%" height="100%" className="max-w-400" />
          </div>
          <div>
            <img src={slide9} alt="slide9" width="100%" height="100%" className="max-w-400" />
          </div>
        </Slider>
      </div>
    );
  }
}

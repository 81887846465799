const FullTimeTable =  {

Lucknow : [
    {"id":"0","date":"1-4","sfks":"4:32 AM","kfta":"5:54 AM","szza":"12:10 PM","kzsa":"4:38 PM","gawi":"6:26 PM","kmsi":"7:48 PM"},
    {"id":"0","date":"2-4","sfks":"4:31 AM","kfta":"5:53 AM","szza":"12:10 PM","kzsa":"4:38 PM","gawi":"6:26 PM","kmsi":"7:48 PM"},
    {"id":"0","date":"3-4","sfks":"4:30 AM","kfta":"5:52 AM","szza":"12:09 PM","kzsa":"4:38 PM","gawi":"6:27 PM","kmsi":"7:49 PM"},
    {"id":"0","date":"4-4","sfks":"4:28 AM","kfta":"5:52 AM","szza":"12:09 PM","kzsa":"4:38 PM","gawi":"6:27 PM","kmsi":"7:49 PM"},
    {"id":"0","date":"5-4","sfks":"4:27 AM","kfta":"5:51 AM","szza":"12:09 PM","kzsa":"4:38 PM","gawi":"6:28 PM","kmsi":"7:50 PM"},
    {"id":"0","date":"6-4","sfks":"4:26 AM","kfta":"5:50 AM","szza":"12:08 PM","kzsa":"4:38 PM","gawi":"6:28 PM","kmsi":"7:51 PM"},
    {"id":"0","date":"7-4","sfks":"4:25 AM","kfta":"5:49 AM","szza":"12:08 PM","kzsa":"4:39 PM","gawi":"6:29 PM","kmsi":"7:52 PM"},
    {"id":"0","date":"8-4","sfks":"4:24 AM","kfta":"5:48 AM","szza":"12:07 PM","kzsa":"4:39 PM","gawi":"6:29 PM","kmsi":"7:52 PM"},
    {"id":"1","date":"9-4","sfks":"4:23 AM","kfta":"5:47 AM","szza":"12:07 PM","kzsa":"4:39 PM","gawi":"6:30 PM","kmsi":"7:53 PM"},
    {"id":"2","date":"10-4","sfks":"4:22 AM","kfta":"5:46 AM","szza":"12:07 PM","kzsa":"4:39 PM","gawi":"6:30 PM","kmsi":"7:54 PM"},
    {"id":"3","date":"11-4","sfks":"4:21 AM","kfta":"5:45 AM","szza":"12:07 PM","kzsa":"4:39 PM","gawi":"6:31 PM","kmsi":"7:54 PM"},
    {"id":"4","date":"12-4","sfks":"4:20 AM","kfta":"5:44 AM","szza":"12:07 PM","kzsa":"4:39 PM","gawi":"6:31 PM","kmsi":"7:55 PM"},
    {"id":"5","date":"13-4","sfks":"4:18 AM","kfta":"5:43 AM","szza":"12:06 PM","kzsa":"4:40 PM","gawi":"6:32 PM","kmsi":"7:56 PM"},
    {"id":"6","date":"14-4","sfks":"4:17 AM","kfta":"5:42 AM","szza":"12:06 PM","kzsa":"4:40 PM","gawi":"6:32 PM","kmsi":"7:57 PM"},
    {"id":"7","date":"15-4","sfks":"4:15 AM","kfta":"5:41 AM","szza":"12:06 PM","kzsa":"4:40 PM","gawi":"6:33 PM","kmsi":"7:57 PM"},
    {"id":"8","date":"16-4","sfks":"4:14 AM","kfta":"5:40 AM","szza":"12:06 PM","kzsa":"4:40 PM","gawi":"6:34 PM","kmsi":"7:58 PM"},
    {"id":"9","date":"17-4","sfks":"4:13 AM","kfta":"5:39 AM","szza":"12:05 PM","kzsa":"4:40 PM","gawi":"6:34 PM","kmsi":"7:58 PM"},
    {"id":"10","date":"18-4","sfks":"4:12 AM","kfta":"5:38 AM","szza":"12:05 PM","kzsa":"4:41 PM","gawi":"6:35 PM","kmsi":"7:59 PM"},
    {"id":"11","date":"19-4","sfks":"4:11 AM","kfta":"5:37 AM","szza":"12:05 PM","kzsa":"4:41 PM","gawi":"6:35 PM","kmsi":"7:59 PM"},
    {"id":"12","date":"20-4","sfks":"4:10 AM","kfta":"5:36 AM","szza":"12:05 PM","kzsa":"4:41 PM","gawi":"6:36 PM","kmsi":"8:00 PM"},
    {"id":"13","date":"21-4","sfks":"4:09 AM","kfta":"5:35 AM","szza":"12:05 PM","kzsa":"4:41 PM","gawi":"6:36 PM","kmsi":"8:00 PM"},
    {"id":"14","date":"22-4","sfks":"4:08 AM","kfta":"5:34 AM","szza":"12:05 PM","kzsa":"4:41 PM","gawi":"6:37 PM","kmsi":"8:01 PM"},
    {"id":"15","date":"23-4","sfks":"4:07 AM","kfta":"5:33 AM","szza":"12:04 PM","kzsa":"4:42 PM","gawi":"6:37 PM","kmsi":"8:01 PM"},
    {"id":"16","date":"24-4","sfks":"4:06 AM","kfta":"5:32 AM","szza":"12:04 PM","kzsa":"4:42 PM","gawi":"6:38 PM","kmsi":"8:02 PM"},
    {"id":"17","date":"25-4","sfks":"4:05 AM","kfta":"5:31 AM","szza":"12:04 PM","kzsa":"4:42 PM","gawi":"6:38 PM","kmsi":"8:03 PM"},
    {"id":"18","date":"26-4","sfks":"4:04 AM","kfta":"5:30 AM","szza":"12:04 PM","kzsa":"4:42 PM","gawi":"6:39 PM","kmsi":"8:04 PM"},
    {"id":"19","date":"27-4","sfks":"4:02 AM","kfta":"5:29 AM","szza":"12:04 PM","kzsa":"4:42 PM","gawi":"6:39 PM","kmsi":"8:05 PM"},
    {"id":"20","date":"28-4","sfks":"4:01 AM","kfta":"5:28 AM","szza":"12:03 PM","kzsa":"4:42 PM","gawi":"6:40 PM","kmsi":"8:06 PM"},
    {"id":"21","date":"29-4","sfks":"4:00 AM","kfta":"5:27 AM","szza":"12:03 PM","kzsa":"4:43 PM","gawi":"6:40 PM","kmsi":"8:07 PM"},
    {"id":"22","date":"30-4","sfks":"3:59 AM","kfta":"5:26 AM","szza":"12:03 PM","kzsa":"4:43 PM","gawi":"6:41 PM","kmsi":"8:07 PM"},
    {"id":"23","date":"1-5","sfks":"3:58 AM","kfta":"5:25 AM","szza":"12:03 PM","kzsa":"4:43 PM","gawi":"6:41 PM","kmsi":"8:08 PM"},
    {"id":"24","date":"2-5","sfks":"3:57 AM","kfta":"5:25 AM","szza":"12:03 PM","kzsa":"4:44 PM","gawi":"6:42 PM","kmsi":"8:08 PM"},
    {"id":"25","date":"3-5","sfks":"3:56 AM","kfta":"5:24 AM","szza":"12:03 PM","kzsa":"4:44 PM","gawi":"6:42 PM","kmsi":"8:09 PM"},
    {"id":"26","date":"4-5","sfks":"3:55 AM","kfta":"5:23 AM","szza":"12:03 PM","kzsa":"4:44 PM","gawi":"6:43 PM","kmsi":"8:09 PM"},
    {"id":"27","date":"5-5","sfks":"3:54 AM","kfta":"5:23 AM","szza":"12:03 PM","kzsa":"4:44 PM","gawi":"6:44 PM","kmsi":"8:10 PM"},
    {"id":"28","date":"6-5","sfks":"3:53 AM","kfta":"5:22 AM","szza":"12:03 PM","kzsa":"4:44 PM","gawi":"6:44 PM","kmsi":"8:11 PM"},
    {"id":"29","date":"7-5","sfks":"3:52 AM","kfta":"5:21 AM","szza":"12:03 PM","kzsa":"4:45 PM","gawi":"6:45 PM","kmsi":"8:12 PM"},
    {"id":"30","date":"8-5","sfks":"3:51 AM","kfta":"5:21 AM","szza":"12:03 PM","kzsa":"4:45 PM","gawi":"6:46 PM","kmsi":"8:13 PM"},
    {"id":"31","date":"9-5","sfks":"3:50 AM","kfta":"5:20 AM","szza":"12:03 PM","kzsa":"4:45 PM","gawi":"6:46 PM","kmsi":"8:14 PM"},
    {"id":"32","date":"10-5","sfks":"3:49 AM","kfta":"5:19 AM","szza":"12:03 PM","kzsa":"4:45 PM","gawi":"6:47 PM","kmsi":"8:15 PM"},
    {"id":"33","date":"11-5","sfks":"3:48 AM","kfta":"5:19 AM","szza":"12:03 PM","kzsa":"4:45 PM","gawi":"6:47 PM","kmsi":"8:15 PM"},
    {"id":"34","date":"12-5","sfks":"3:47 AM","kfta":"5:18 AM","szza":"12:03 PM","kzsa":"4:46 PM","gawi":"6:48 PM","kmsi":"8:16 PM"},
    {"id":"35","date":"13-5","sfks":"3:46 AM","kfta":"5:17 AM","szza":"12:03 PM","kzsa":"4:46 PM","gawi":"6:48 PM","kmsi":"8:17 PM"},
    {"id":"36","date":"14-5","sfks":"3:45 AM","kfta":"5:17 AM","szza":"12:03 PM","kzsa":"4:46 PM","gawi":"6:49 PM","kmsi":"8:17 PM"},
    {"id":"37","date":"15-5","sfks":"3:44 AM","kfta":"5:16 AM","szza":"12:03 PM","kzsa":"4:46 PM","gawi":"6:49 PM","kmsi":"8:18 PM"},
    {"id":"38","date":"16-5","sfks":"3:44 AM","kfta":"5:16 AM","szza":"12:03 PM","kzsa":"4:46 PM","gawi":"6:50 PM","kmsi":"8:19 PM"},
    {"id":"39","date":"17-5","sfks":"3:43 AM","kfta":"5:15 AM","szza":"12:03 PM","kzsa":"4:46 PM","gawi":"6:50 PM","kmsi":"8:20 PM"},
    {"id":"40","date":"18-5","sfks":"3:43 AM","kfta":"5:15 AM","szza":"12:03 PM","kzsa":"4:47 PM","gawi":"6:51 PM","kmsi":"8:21 PM"},
    {"id":"41","date":"19-5","sfks":"3:42 AM","kfta":"5:14 AM","szza":"12:03 PM","kzsa":"4:47 PM","gawi":"6:51 PM","kmsi":"8:22 PM"},
    {"id":"42","date":"20-5","sfks":"3:41 AM","kfta":"5:14 AM","szza":"12:03 PM","kzsa":"4:47 PM","gawi":"6:52 PM","kmsi":"8:23 PM"},
    {"id":"43","date":"21-5","sfks":"3:41 AM","kfta":"5:13 AM","szza":"12:03 PM","kzsa":"4:47 PM","gawi":"6:52 PM","kmsi":"8:23 PM"},
    {"id":"44","date":"22-5","sfks":"3:40 AM","kfta":"5:13 AM","szza":"12:03 PM","kzsa":"4:48 PM","gawi":"6:53 PM","kmsi":"8:24 PM"},
    {"id":"45","date":"23-5","sfks":"3:39 AM","kfta":"5:12 AM","szza":"12:03 PM","kzsa":"4:48 PM","gawi":"6:53 PM","kmsi":"8:25 PM"},
    {"id":"46","date":"24-5","sfks":"3:39 AM","kfta":"5:12 AM","szza":"12:03 PM","kzsa":"4:48 PM","gawi":"6:54 PM","kmsi":"8:26 PM"},
    {"id":"47","date":"25-5","sfks":"3:38 AM","kfta":"5:12 AM","szza":"12:04 PM","kzsa":"4:49 PM","gawi":"6:54 PM","kmsi":"8:27 PM"},
    {"id":"48","date":"26-5","sfks":"3:38 AM","kfta":"5:11 AM","szza":"12:04 PM","kzsa":"4:49 PM","gawi":"6:55 PM","kmsi":"8:28 PM"},
    {"id":"49","date":"27-5","sfks":"3:37 AM","kfta":"5:11 AM","szza":"12:04 PM","kzsa":"4:49 PM","gawi":"6:55 PM","kmsi":"8:29 PM"},
    {"id":"50","date":"28-5","sfks":"3:37 AM","kfta":"5:11 AM","szza":"12:04 PM","kzsa":"4:49 PM","gawi":"6:56 PM","kmsi":"8:29 PM"},
    {"id":"51","date":"29-5","sfks":"3:36 AM","kfta":"5:11 AM","szza":"12:04 PM","kzsa":"4:50 PM","gawi":"6:56 PM","kmsi":"8:30 PM"},
    {"id":"52","date":"30-5","sfks":"3:36 AM","kfta":"5:10 AM","szza":"12:04 PM","kzsa":"4:50 PM","gawi":"6:57 PM","kmsi":"8:31 PM"},
    {"id":"53","date":"31-5","sfks":"3:35 AM","kfta":"5:10 AM","szza":"12:04 PM","kzsa":"4:50 PM","gawi":"6:57 PM","kmsi":"8:32 PM"},
    {"id":"54","date":"1-6","sfks":"3:35 AM","kfta":"5:10 AM","szza":"12:04 PM","kzsa":"4:50 PM","gawi":"6:58 PM","kmsi":"8:32 PM"},
    {"id":"55","date":"2-6","sfks":"3:35 AM","kfta":"5:10 AM","szza":"12:04 PM","kzsa":"4:50 PM","gawi":"6:59 PM","kmsi":"8:33 PM"},
    {"id":"56","date":"3-6","sfks":"3:35 AM","kfta":"5:09 AM","szza":"12:04 PM","kzsa":"4:50 PM","gawi":"6:59 PM","kmsi":"8:35 PM"},
    {"id":"57","date":"4-6","sfks":"3:34 AM","kfta":"5:09 AM","szza":"12:05 PM","kzsa":"4:50 PM","gawi":"7:00 PM","kmsi":"8:35 PM"},
    {"id":"58","date":"5-6","sfks":"3:34 AM","kfta":"5:09 AM","szza":"12:05 PM","kzsa":"4:50 PM","gawi":"7:00 PM","kmsi":"8:36 PM"},
    {"id":"59","date":"6-6","sfks":"3:34 AM","kfta":"5:09 AM","szza":"12:05 PM","kzsa":"4:50 PM","gawi":"7:01 PM","kmsi":"8:36 PM"},
    {"id":"60","date":"7-6","sfks":"3:33 AM","kfta":"5:09 AM","szza":"12:05 PM","kzsa":"4:50 PM","gawi":"7:01 PM","kmsi":"8:37 PM"},
    {"id":"61","date":"8-6","sfks":"3:33 AM","kfta":"5:08 AM","szza":"12:05 PM","kzsa":"4:51 PM","gawi":"7:01 PM","kmsi":"8:37 PM"},
    {"id":"62","date":"9-6","sfks":"3:33 AM","kfta":"5:08 AM","szza":"12:05 PM","kzsa":"4:51 PM","gawi":"7:02 PM","kmsi":"8:38 PM"},
    {"id":"63","date":"10-6","sfks":"3:32 AM","kfta":"5:08 AM","szza":"12:05 PM","kzsa":"4:51 PM","gawi":"7:02 PM","kmsi":"8:38 PM"},
    {"id":"64","date":"11-6","sfks":"3:32 AM","kfta":"5:08 AM","szza":"12:05 PM","kzsa":"4:52 PM","gawi":"7:02 PM","kmsi":"8:38 PM"},
    {"id":"65","date":"12-6","sfks":"3:32 AM","kfta":"5:08 AM","szza":"12:05 PM","kzsa":"4:52 PM","gawi":"7:03 PM","kmsi":"8:39 PM"},
    {"id":"66","date":"13-6","sfks":"3:32 AM","kfta":"5:08 AM","szza":"12:06 PM","kzsa":"4:52 PM","gawi":"7:03 PM","kmsi":"8:39 PM"},
    {"id":"67","date":"14-6","sfks":"3:32 AM","kfta":"5:08 AM","szza":"12:06 PM","kzsa":"4:52 PM","gawi":"7:03 PM","kmsi":"8:40 PM"},
    {"id":"68","date":"15-6","sfks":"3:32 AM","kfta":"5:08 AM","szza":"12:06 PM","kzsa":"4:53 PM","gawi":"7:03 PM","kmsi":"8:40 PM"},
    {"id":"69","date":"16-6","sfks":"3:32 AM","kfta":"5:08 AM","szza":"12:06 PM","kzsa":"4:53 PM","gawi":"7:04 PM","kmsi":"8:40 PM"},
    {"id":"70","date":"17-6","sfks":"3:33 AM","kfta":"5:08 AM","szza":"12:06 PM","kzsa":"4:53 PM","gawi":"7:04 PM","kmsi":"8:40 PM"},
    {"id":"71","date":"18-6","sfks":"3:33 AM","kfta":"5:09 AM","szza":"12:07 PM","kzsa":"4:53 PM","gawi":"7:04 PM","kmsi":"8:41 PM"},
    {"id":"72","date":"19-6","sfks":"3:33 AM","kfta":"5:09 AM","szza":"12:07 PM","kzsa":"4:54 PM","gawi":"7:05 PM","kmsi":"8:41 PM"},
    {"id":"73","date":"20-6","sfks":"3:33 AM","kfta":"5:09 AM","szza":"12:07 PM","kzsa":"4:54 PM","gawi":"7:05 PM","kmsi":"8:41 PM"},
    {"id":"74","date":"21-6","sfks":"3:33 AM","kfta":"5:09 AM","szza":"12:07 PM","kzsa":"4:54 PM","gawi":"7:05 PM","kmsi":"8:41 PM"},
    {"id":"75","date":"22-6","sfks":"3:33 AM","kfta":"5:09 AM","szza":"12:07 PM","kzsa":"4:54 PM","gawi":"7:06 PM","kmsi":"8:42 PM"},
    {"id":"76","date":"23-6","sfks":"3:34 AM","kfta":"5:10 AM","szza":"12:08 PM","kzsa":"4:54 PM","gawi":"7:06 PM","kmsi":"8:42 PM"},
    {"id":"77","date":"24-6","sfks":"3:34 AM","kfta":"5:10 AM","szza":"12:08 PM","kzsa":"4:54 PM","gawi":"7:06 PM","kmsi":"8:42 PM"},
    {"id":"78","date":"25-6","sfks":"3:34 AM","kfta":"5:10 AM","szza":"12:08 PM","kzsa":"4:55 PM","gawi":"7:06 PM","kmsi":"8:42 PM"},
    {"id":"79","date":"26-6","sfks":"3:35 AM","kfta":"5:11 AM","szza":"12:08 PM","kzsa":"4:55 PM","gawi":"7:06 PM","kmsi":"8:43 PM"},
    {"id":"80","date":"27-6","sfks":"3:35 AM","kfta":"5:11 AM","szza":"12:09 PM","kzsa":"4:55 PM","gawi":"7:06 PM","kmsi":"8:43 PM"},
    {"id":"81","date":"28-6","sfks":"3:35 AM","kfta":"5:11 AM","szza":"12:09 PM","kzsa":"4:55 PM","gawi":"7:06 PM","kmsi":"8:43 PM"},
    {"id":"82","date":"29-6","sfks":"3:35 AM","kfta":"5:12 AM","szza":"12:09 PM","kzsa":"4:56 PM","gawi":"7:07 PM","kmsi":"8:44 PM"},
    {"id":"83","date":"30-6","sfks":"3:36 AM","kfta":"5:12 AM","szza":"12:10 PM","kzsa":"4:56 PM","gawi":"7:07 PM","kmsi":"8:44 PM"},
    {"id":"84","date":"1-7","sfks":"3:36 AM","kfta":"5:13 AM","szza":"12:10 PM","kzsa":"4:56 PM","gawi":"7:07 PM","kmsi":"8:44 PM"},
    {"id":"85","date":"2-7","sfks":"3:36 AM","kfta":"5:13 AM","szza":"12:10 PM","kzsa":"4:56 PM","gawi":"7:07 PM","kmsi":"8:44 PM"},
    {"id":"86","date":"3-7","sfks":"3:37 AM","kfta":"5:13 AM","szza":"12:10 PM","kzsa":"4:56 PM","gawi":"7:07 PM","kmsi":"8:43 PM"},
    {"id":"87","date":"4-7","sfks":"3:37 AM","kfta":"5:14 AM","szza":"12:10 PM","kzsa":"4:56 PM","gawi":"7:07 PM","kmsi":"8:43 PM"},
    {"id":"88","date":"5-7","sfks":"3:38 AM","kfta":"5:14 AM","szza":"12:10 PM","kzsa":"4:56 PM","gawi":"7:07 PM","kmsi":"8:43 PM"},
    {"id":"89","date":"6-7","sfks":"3:38 AM","kfta":"5:14 AM","szza":"12:11 PM","kzsa":"4:57 PM","gawi":"7:06 PM","kmsi":"8:43 PM"},
    {"id":"90","date":"7-7","sfks":"3:39 AM","kfta":"5:14 AM","szza":"12:11 PM","kzsa":"4:57 PM","gawi":"7:06 PM","kmsi":"8:42 PM"},
    {"id":"91","date":"8-7","sfks":"3:39 AM","kfta":"5:15 AM","szza":"12:11 PM","kzsa":"4:57 PM","gawi":"7:06 PM","kmsi":"8:42 PM"},
    {"id":"92","date":"9-7","sfks":"3:40 AM","kfta":"5:15 AM","szza":"12:11 PM","kzsa":"4:57 PM","gawi":"7:06 PM","kmsi":"8:42 PM"},
    {"id":"93","date":"10-7","sfks":"3:40 AM","kfta":"5:15 AM","szza":"12:11 PM","kzsa":"4:57 PM","gawi":"7:06 PM","kmsi":"8:42 PM"},
    {"id":"94","date":"11-7","sfks":"3:41 AM","kfta":"5:15 AM","szza":"12:11 PM","kzsa":"4:57 PM","gawi":"7:06 PM","kmsi":"8:41 PM"},
    {"id":"95","date":"12-7","sfks":"3:41 AM","kfta":"5:16 AM","szza":"12:11 PM","kzsa":"4:57 PM","gawi":"7:06 PM","kmsi":"8:41 PM"},
    {"id":"96","date":"13-7","sfks":"3:41 AM","kfta":"5:16 AM","szza":"12:11 PM","kzsa":"4:57 PM","gawi":"7:06 PM","kmsi":"8:40 PM"},
    {"id":"97","date":"14-7","sfks":"3:42 AM","kfta":"5:17 AM","szza":"12:11 PM","kzsa":"4:57 PM","gawi":"7:06 PM","kmsi":"8:40 PM"},
    {"id":"98","date":"15-7","sfks":"3:43 AM","kfta":"5:17 AM","szza":"12:11 PM","kzsa":"4:57 PM","gawi":"7:05 PM","kmsi":"8:39 PM"},
    {"id":"99","date":"16-7","sfks":"3:43 AM","kfta":"5:18 AM","szza":"12:12 PM","kzsa":"4:57 PM","gawi":"7:05 PM","kmsi":"8:39 PM"},
    {"id":"100","date":"17-7","sfks":"3:44 AM","kfta":"5:18 AM","szza":"12:12 PM","kzsa":"4:57 PM","gawi":"7:05 PM","kmsi":"8:38 PM"},
    {"id":"101","date":"18-7","sfks":"3:45 AM","kfta":"5:19 AM","szza":"12:12 PM","kzsa":"4:57 PM","gawi":"7:05 PM","kmsi":"8:38 PM"},
    {"id":"102","date":"19-7","sfks":"3:46 AM","kfta":"5:19 AM","szza":"12:12 PM","kzsa":"4:57 PM","gawi":"7:04 PM","kmsi":"8:37 PM"},
    {"id":"103","date":"20-7","sfks":"3:47 AM","kfta":"5:20 AM","szza":"12:12 PM","kzsa":"4:57 PM","gawi":"7:04 PM","kmsi":"8:38 PM"},
    {"id":"104","date":"21-7","sfks":"3:47 AM","kfta":"5:20 AM","szza":"12:12 PM","kzsa":"4:56 PM","gawi":"7:04 PM","kmsi":"8:36 PM"},
    {"id":"105","date":"22-7","sfks":"3:48 AM","kfta":"5:21 AM","szza":"12:12 PM","kzsa":"4:56 PM","gawi":"7:04 PM","kmsi":"8:36 PM"},
    {"id":"106","date":"23-7","sfks":"3:48 AM","kfta":"5:21 AM","szza":"12:12 PM","kzsa":"4:56 PM","gawi":"7:03 PM","kmsi":"8:35 PM"},
    {"id":"107","date":"24-7","sfks":"3:49 AM","kfta":"5:22 AM","szza":"12:12 PM","kzsa":"4:56 PM","gawi":"7:03 PM","kmsi":"8:35 PM"},
    {"id":"108","date":"25-7","sfks":"3:49 AM","kfta":"5:22 AM","szza":"12:12 PM","kzsa":"4:56 PM","gawi":"7:02 PM","kmsi":"8:34 PM"},
    {"id":"109","date":"26-7","sfks":"3:50 AM","kfta":"5:23 AM","szza":"12:12 PM","kzsa":"4:56 PM","gawi":"7:02 PM","kmsi":"8:33 PM"},
    {"id":"110","date":"27-7","sfks":"3:51 AM","kfta":"5:23 AM","szza":"12:12 PM","kzsa":"4:56 PM","gawi":"7:01 PM","kmsi":"8:33 PM"},
    {"id":"111","date":"28-7","sfks":"3:52 AM","kfta":"5:24 AM","szza":"12:12 PM","kzsa":"4:55 PM","gawi":"7:01 PM","kmsi":"8:32 PM"},
    {"id":"112","date":"29-7","sfks":"3:53 AM","kfta":"5:24 AM","szza":"12:12 PM","kzsa":"4:55 PM","gawi":"7:00 PM","kmsi":"8:31 PM"},
    {"id":"113","date":"30-7","sfks":"3:54 AM","kfta":"5:25 AM","szza":"12:12 PM","kzsa":"4:55 PM","gawi":"7:00 PM","kmsi":"8:30 PM"},
    {"id":"114","date":"31-7","sfks":"3:55 AM","kfta":"5:25 AM","szza":"12:12 PM","kzsa":"4:55 PM","gawi":"6:59 PM","kmsi":"8:29 PM"},
    {"id":"115","date":"1-8","sfks":"3:56 AM","kfta":"5:26 AM","szza":"12:12 PM","kzsa":"4:55 PM","gawi":"6:58 PM","kmsi":"8:28 PM"},
    {"id":"116","date":"2-8","sfks":"3:57 AM","kfta":"5:26 AM","szza":"12:12 PM","kzsa":"4:55 PM","gawi":"6:58 PM","kmsi":"8:27 PM"},
    {"id":"117","date":"3-8","sfks":"3:57 AM","kfta":"5:27 AM","szza":"12:11 PM","kzsa":"4:54 PM","gawi":"6:57 PM","kmsi":"8:26 PM"},
    {"id":"118","date":"4-8","sfks":"3:58 AM","kfta":"5:27 AM","szza":"12:11 PM","kzsa":"4:54 PM","gawi":"6:56 PM","kmsi":"8:25 PM"},
    {"id":"119","date":"5-8","sfks":"3:59 AM","kfta":"5:28 AM","szza":"12:11 PM","kzsa":"4:54 PM","gawi":"6:55 PM","kmsi":"8:24 PM"},
    {"id":"120","date":"6-8","sfks":"3:59 AM","kfta":"5:28 AM","szza":"12:11 PM","kzsa":"4:53 PM","gawi":"6:54 PM","kmsi":"8:23 PM"},
    {"id":"121","date":"7-8","sfks":"4:00 AM","kfta":"5:29 AM","szza":"12:11 PM","kzsa":"4:53 PM","gawi":"6:53 PM","kmsi":"8:22 PM"},
    {"id":"122","date":"8-8","sfks":"4:01 AM","kfta":"5:29 AM","szza":"12:11 PM","kzsa":"4:52 PM","gawi":"6:52 PM","kmsi":"8:21 PM"},
    {"id":"123","date":"9-8","sfks":"4:02 AM","kfta":"5:30 AM","szza":"12:11 PM","kzsa":"4:52 PM","gawi":"6:52 PM","kmsi":"8:20 PM"},
    {"id":"125","date":"10-8","sfks":"4:03 AM","kfta":"5:31 AM","szza":"12:11 PM","kzsa":"4:51 PM","gawi":"6:51 PM","kmsi":"8:19 PM"},
    {"id":"126","date":"11-8","sfks":"4:04 AM","kfta":"5:31 AM","szza":"12:11 PM","kzsa":"4:51 PM","gawi":"6:51 PM","kmsi":"8:18 PM"},
    {"id":"127","date":"12-8","sfks":"4:05 AM","kfta":"5:32 AM","szza":"12:11 PM","kzsa":"4:50 PM","gawi":"6:50 PM","kmsi":"8:17 PM"},
    {"id":"128","date":"13-8","sfks":"4:05 AM","kfta":"5:32 AM","szza":"12:10 PM","kzsa":"4:50 PM","gawi":"6:49 PM","kmsi":"8:16 PM"},
    {"id":"129","date":"14-8","sfks":"4:06 AM","kfta":"5:33 AM","szza":"12:10 PM","kzsa":"4:49 PM","gawi":"6:48 PM","kmsi":"8:15 PM"},
    {"id":"130","date":"15-8","sfks":"4:07 AM","kfta":"5:33 AM","szza":"12:10 PM","kzsa":"4:49 PM","gawi":"6:47 PM","kmsi":"8:14 PM"},
    {"id":"131","date":"16-8","sfks":"4:08 AM","kfta":"5:34 AM","szza":"12:10 PM","kzsa":"4:49 PM","gawi":"6:46 PM","kmsi":"8:13 PM"},
    {"id":"132","date":"17-8","sfks":"4:09 AM","kfta":"5:34 AM","szza":"12:10 PM","kzsa":"4:48 PM","gawi":"6:45 PM","kmsi":"8:12 PM"},
    {"id":"133","date":"18-8","sfks":"4:10 AM","kfta":"5:35 AM","szza":"12:10 PM","kzsa":"4:48 PM","gawi":"6:44 PM","kmsi":"8:11 PM"},
    {"id":"134","date":"19-8","sfks":"4:11 AM","kfta":"5:35 AM","szza":"12:10 PM","kzsa":"4:47 PM","gawi":"6:43 PM","kmsi":"8:10 PM"},
    {"id":"135","date":"20-8","sfks":"4:11 AM","kfta":"5:36 AM","szza":"12:10 PM","kzsa":"4:47 PM","gawi":"6:42 PM","kmsi":"8:09 PM"},
    {"id":"136","date":"21-8","sfks":"4:12 AM","kfta":"5:36 AM","szza":"12:10 PM","kzsa":"4:47 PM","gawi":"6:41 PM","kmsi":"8:08 PM"},
    {"id":"137","date":"22-8","sfks":"4:12 AM","kfta":"5:37 AM","szza":"12:10 PM","kzsa":"4:46 PM","gawi":"6:40 PM","kmsi":"8:07 PM"},
    {"id":"138","date":"23-8","sfks":"4:13 AM","kfta":"5:37 AM","szza":"12:10 PM","kzsa":"4:46 PM","gawi":"6:39 PM","kmsi":"8:05 PM"},
    {"id":"139","date":"24-8","sfks":"4:13 AM","kfta":"5:38 AM","szza":"12:09 PM","kzsa":"4:45 PM","gawi":"6:38 PM","kmsi":"8:04 PM"},
    {"id":"140","date":"25-8","sfks":"4:14 AM","kfta":"5:38 AM","szza":"12:09 PM","kzsa":"4:45 PM","gawi":"6:37 PM","kmsi":"8:03 PM"},
    {"id":"141","date":"26-8","sfks":"4:14 AM","kfta":"5:39 AM","szza":"12:09 PM","kzsa":"4:44 PM","gawi":"6:36 PM","kmsi":"8:02 PM"},
    {"id":"142","date":"27-8","sfks":"4:15 AM","kfta":"5:39 AM","szza":"12:07 PM","kzsa":"4:43 PM","gawi":"6:35 PM","kmsi":"8:00 PM"},
    {"id":"143","date":"28-8","sfks":"4:15 AM","kfta":"5:40 AM","szza":"12:07 PM","kzsa":"4:42 PM","gawi":"6:34 PM","kmsi":"7:59 PM"},
    {"id":"144","date":"29-8","sfks":"4:16 AM","kfta":"5:40 AM","szza":"12:07 PM","kzsa":"4:41 PM","gawi":"6:33 PM","kmsi":"7:57 PM"},
    {"id":"145","date":"30-8","sfks":"4:16 AM","kfta":"5:41 AM","szza":"12:06 PM","kzsa":"4:40 PM","gawi":"6:32 PM","kmsi":"7:56 PM"},
    {"id":"146","date":"31-8","sfks":"4:17 AM","kfta":"5:41 AM","szza":"12:06 PM","kzsa":"4:40 PM","gawi":"6:31 PM","kmsi":"7:55 PM"},
    {"id":"147","date":"1-9","sfks":"4:18 AM","kfta":"5:42 AM","szza":"12:06 PM","kzsa":"4:39 PM","gawi":"6:30 PM","kmsi":"7:54 PM"},
    {"id":"148","date":"2-9","sfks":"4:18 AM","kfta":"5:42 AM","szza":"12:05 PM","kzsa":"4:38 PM","gawi":"6:29 PM","kmsi":"7:52 PM"},
    {"id":"149","date":"3-9","sfks":"4:19 AM","kfta":"5:43 AM","szza":"12:05 PM","kzsa":"4:37 PM","gawi":"6:28 PM","kmsi":"7:51 PM"},
    {"id":"150","date":"4-9","sfks":"4:20 AM","kfta":"5:43 AM","szza":"12:05 PM","kzsa":"4:36 PM","gawi":"6:27 PM","kmsi":"7:50 PM"},
    {"id":"151","date":"5-9","sfks":"4:21 AM","kfta":"5:44 AM","szza":"12:04 PM","kzsa":"4:35 PM","gawi":"6:26 PM","kmsi":"7:48 PM"},
    {"id":"152","date":"6-9","sfks":"4:22 AM","kfta":"5:44 AM","szza":"12:04 PM","kzsa":"4:35 PM","gawi":"6:25 PM","kmsi":"7:47 PM"},
    {"id":"153","date":"7-9","sfks":"4:23 AM","kfta":"5:45 AM","szza":"12:04 PM","kzsa":"4:34 PM","gawi":"6:24 PM","kmsi":"7:46 PM"},
    {"id":"154","date":"8-9","sfks":"4:24 AM","kfta":"5:45 AM","szza":"12:03 PM","kzsa":"4:34 PM","gawi":"6:23 PM","kmsi":"7:45 PM"},
    {"id":"155","date":"9-9","sfks":"4:25 AM","kfta":"5:46 AM","szza":"12:03 PM","kzsa":"4:33 PM","gawi":"6:22 PM","kmsi":"7:44 PM"},
    {"id":"156","date":"10-9","sfks":"4:25 AM","kfta":"5:47 AM","szza":"12:03 PM","kzsa":"4:32 PM","gawi":"6:21 PM","kmsi":"7:43 PM"},
    {"id":"157","date":"11-9","sfks":"4:26 AM","kfta":"5:47 AM","szza":"12:03 PM","kzsa":"4:32 PM","gawi":"6:20 PM","kmsi":"7:41 PM"},
    {"id":"158","date":"12-9","sfks":"4:26 AM","kfta":"5:48 AM","szza":"12:02 PM","kzsa":"4:31 PM","gawi":"6:18 PM","kmsi":"7:40 PM"},
    {"id":"159","date":"13-9","sfks":"4:27 AM","kfta":"5:48 AM","szza":"12:02 PM","kzsa":"4:30 PM","gawi":"6:17 PM","kmsi":"7:39 PM"},
    {"id":"160","date":"14-9","sfks":"4:27 AM","kfta":"5:48 AM","szza":"12:02 PM","kzsa":"4:29 PM","gawi":"6:16 PM","kmsi":"7:37 PM"},
    {"id":"161","date":"15-9","sfks":"4:27 AM","kfta":"5:49 AM","szza":"12:02 PM","kzsa":"4:28 PM","gawi":"6:15 PM","kmsi":"7:36 PM"},
    {"id":"162","date":"16-9","sfks":"4:28 AM","kfta":"5:49 AM","szza":"12:01 PM","kzsa":"4:27 PM","gawi":"6:14 PM","kmsi":"7:35 PM"},
    {"id":"163","date":"17-9","sfks":"4:28 AM","kfta":"5:49 AM","szza":"12:01 PM","kzsa":"4:26 PM","gawi":"6:13 PM","kmsi":"7:34 PM"},
    {"id":"164","date":"18-9","sfks":"4:28 AM","kfta":"5:50 AM","szza":"12:01 PM","kzsa":"4:26 PM","gawi":"6:12 PM","kmsi":"7:33 PM"},
    {"id":"165","date":"19-9","sfks":"4:29 AM","kfta":"5:50 AM","szza":"12:00 PM","kzsa":"4:25 PM","gawi":"6:11 PM","kmsi":"7:32 PM"},
    {"id":"166","date":"20-9","sfks":"4:29 AM","kfta":"5:50 AM","szza":"12:00 PM","kzsa":"4:24 PM","gawi":"6:10 PM","kmsi":"7:31 PM"},
    {"id":"167","date":"21-9","sfks":"4:30 AM","kfta":"5:51 AM","szza":"12:00 PM","kzsa":"4:23 PM","gawi":"6:08 PM","kmsi":"7:30 PM"},
    {"id":"168","date":"22-9","sfks":"4:30 AM","kfta":"5:51 AM","szza":"12:00 PM","kzsa":"4:23 PM","gawi":"6:07 PM","kmsi":"7:28 PM"},
    {"id":"169","date":"23-9","sfks":"4:30 AM","kfta":"5:51 AM","szza":"12:00 PM","kzsa":"4:22 PM","gawi":"6:06 PM","kmsi":"7:27 PM"},
    {"id":"170","date":"24-9","sfks":"4:31 AM","kfta":"5:52 AM","szza":"12:00 PM","kzsa":"4:21 PM","gawi":"6:05 PM","kmsi":"7:26 PM"},
    {"id":"171","date":"25-9","sfks":"4:31 AM","kfta":"5:52 AM","szza":"11:59 AM","kzsa":"4:20 PM","gawi":"6:04 PM","kmsi":"7:24 PM"},
    {"id":"172","date":"26-9","sfks":"4:31 AM","kfta":"5:52 AM","szza":"11:59 AM","kzsa":"4:19 PM","gawi":"6:03 PM","kmsi":"7:23 PM"},
    {"id":"173","date":"27-9","sfks":"4:32 AM","kfta":"5:53 AM","szza":"11:58 AM","kzsa":"4:18 PM","gawi":"6:02 PM","kmsi":"7:22 PM"},
    {"id":"174","date":"28-9","sfks":"4:32 AM","kfta":"5:53 AM","szza":"11:58 AM","kzsa":"4:17 PM","gawi":"6:01 PM","kmsi":"7:21 PM"},
    {"id":"175","date":"29-9","sfks":"4:33 AM","kfta":"5:54 AM","szza":"11:57 AM","kzsa":"4:16 PM","gawi":"6:00 PM","kmsi":"7:20 PM"},
    {"id":"176","date":"30-9","sfks":"4:33 AM","kfta":"5:54 AM","szza":"11:57 AM","kzsa":"4:15 PM","gawi":"5:58 PM","kmsi":"7:19 PM"},
    {"id":"177","date":"1-11","sfks":"4:50 AM","kfta":"6:12 AM","szza":"11:50 AM","kzsa":"3:48 PM","gawi":"5:28 PM","kmsi":"6:50 PM"},
    {"id":"178","date":"2-11","sfks":"4:50 AM","kfta":"6:13 AM","szza":"11:50 AM","kzsa":"3:47 PM","gawi":"5:28 PM","kmsi":"6:50 PM"},
    {"id":"179","date":"3-11","sfks":"4:51 AM","kfta":"6:13 AM","szza":"11:50 AM","kzsa":"3:46 PM","gawi":"5:27 PM","kmsi":"6:49 PM"},
    {"id":"180","date":"4-11","sfks":"4:51 AM","kfta":"6:14 AM","szza":"11:50 AM","kzsa":"3:46 PM","gawi":"5:27 PM","kmsi":"6:49 PM"},
    {"id":"181","date":"5-11","sfks":"4:52 AM","kfta":"6:15 AM","szza":"11:50 AM","kzsa":"3:45 PM","gawi":"5:26 PM","kmsi":"6:48 PM"},
    {"id":"182","date":"6-11","sfks":"4:52 AM","kfta":"6:16 AM","szza":"11:50 AM","kzsa":"3:45 PM","gawi":"5:25 PM","kmsi":"6:48 PM"},
    {"id":"183","date":"7-11","sfks":"4:53 AM","kfta":"6:16 AM","szza":"11:50 AM","kzsa":"3:44 PM","gawi":"5:24 PM","kmsi":"6:47 PM"},
    {"id":"184","date":"8-11","sfks":"4:53 AM","kfta":"6:17 AM","szza":"11:50 AM","kzsa":"3:43 PM","gawi":"5:23 PM","kmsi":"6:46 PM"},
    {"id":"185","date":"9-11","sfks":"4:54 AM","kfta":"6:17 AM","szza":"11:50 AM","kzsa":"3:42 PM","gawi":"5:23 PM","kmsi":"6:46 PM"},
    {"id":"186","date":"10-11","sfks":"4:55 AM","kfta":"6:18 AM","szza":"11:50 AM","kzsa":"3:42 PM","gawi":"5:22 PM","kmsi":"6:45 PM"},
    {"id":"187","date":"11-11","sfks":"4:55 AM","kfta":"6:18 AM","szza":"11:50 AM","kzsa":"3:42 PM","gawi":"5:22 PM","kmsi":"6:45 PM"},
    {"id":"188","date":"12-11","sfks":"4:56 AM","kfta":"6:19 AM","szza":"11:51 AM","kzsa":"3:41 PM","gawi":"5:21 PM","kmsi":"6:45 PM"},
    {"id":"189","date":"13-11","sfks":"4:56 AM","kfta":"6:20 AM","szza":"11:51 AM","kzsa":"3:41 PM","gawi":"5:21 PM","kmsi":"6:44 PM"},
    {"id":"190","date":"14-11","sfks":"4:57 AM","kfta":"6:20 AM","szza":"11:51 AM","kzsa":"3:41 PM","gawi":"5:20 PM","kmsi":"6:44 PM"},
    {"id":"191","date":"15-11","sfks":"4:57 AM","kfta":"6:21 AM","szza":"11:51 AM","kzsa":"3:40 PM","gawi":"5:20 PM","kmsi":"6:44 PM"},
    {"id":"192","date":"16-11","sfks":"4:58 AM","kfta":"6:22 AM","szza":"11:51 AM","kzsa":"3:40 PM","gawi":"5:19 PM","kmsi":"6:43 PM"},
    {"id":"193","date":"17-11","sfks":"4:59 AM","kfta":"6:23 AM","szza":"11:52 AM","kzsa":"3:40 PM","gawi":"5:19 PM","kmsi":"6:43 PM"},
    {"id":"194","date":"18-11","sfks":"5:00 AM","kfta":"6:24 AM","szza":"11:52 AM","kzsa":"3:39 PM","gawi":"5:19 PM","kmsi":"6:43 PM"},
    {"id":"195","date":"19-11","sfks":"5:01 AM","kfta":"6:25 AM","szza":"11:52 AM","kzsa":"3:39 PM","gawi":"5:18 PM","kmsi":"6:42 PM"},
    {"id":"196","date":"20-11","sfks":"5:02 AM","kfta":"6:26 AM","szza":"11:52 AM","kzsa":"3:39 PM","gawi":"5:18 PM","kmsi":"6:42 PM"},
    {"id":"197","date":"21-11","sfks":"5:02 AM","kfta":"6:26 AM","szza":"11:52 AM","kzsa":"3:39 PM","gawi":"5:18 PM","kmsi":"6:42 PM"},
    {"id":"198","date":"22-11","sfks":"5:03 AM","kfta":"6:27 AM","szza":"11:53 AM","kzsa":"3:39 PM","gawi":"5:18 PM","kmsi":"6:42 PM"},
    {"id":"199","date":"23-11","sfks":"5:03 AM","kfta":"6:28 AM","szza":"11:53 AM","kzsa":"3:39 PM","gawi":"5:18 PM","kmsi":"6:42 PM"},
    {"id":"200","date":"24-11","sfks":"5:04 AM","kfta":"6:29 AM","szza":"11:53 AM","kzsa":"3:39 PM","gawi":"5:17 PM","kmsi":"6:42 PM"},
    {"id":"201","date":"25-11","sfks":"5:05 AM","kfta":"6:29 AM","szza":"11:54 AM","kzsa":"3:39 PM","gawi":"5:17 PM","kmsi":"6:41 PM"},
    {"id":"202","date":"26-11","sfks":"5:06 AM","kfta":"6:30 AM","szza":"11:54 AM","kzsa":"3:38 PM","gawi":"5:17 PM","kmsi":"6:41 PM"},
    {"id":"203","date":"27-11","sfks":"5:06 AM","kfta":"6:30 AM","szza":"11:54 AM","kzsa":"3:38 PM","gawi":"5:17 PM","kmsi":"6:41 PM"},
    {"id":"204","date":"28-11","sfks":"5:07 AM","kfta":"6:31 AM","szza":"11:54 AM","kzsa":"3:38 PM","gawi":"5:16 PM","kmsi":"6:41 PM"},
    {"id":"205","date":"29-11","sfks":"5:07 AM","kfta":"6:32 AM","szza":"11:55 AM","kzsa":"3:38 PM","gawi":"5:16 PM","kmsi":"6:38 PM"},
    {"id":"206","date":"30-11","sfks":"5:08 AM","kfta":"6:33 AM","szza":"11:55 AM","kzsa":"3:37 PM","gawi":"5:16 PM","kmsi":"6:41 PM"},
    {"id":"207","date":"1-10","sfks":"4:34 AM","kfta":"5:55 AM","szza":"11:56 AM","kzsa":"4:14 PM","gawi":"5:57 PM","kmsi":"7:18 PM"},
    {"id":"208","date":"2-10","sfks":"4:34 AM","kfta":"5:55 AM","szza":"11:56 AM","kzsa":"4:13 PM","gawi":"5:55 PM","kmsi":"7:16 PM"},
    {"id":"209","date":"3-10","sfks":"4:35 AM","kfta":"5:56 AM","szza":"11:55 AM","kzsa":"4:12 PM","gawi":"5:54 PM","kmsi":"7:15 PM"},
    {"id":"210","date":"4-10","sfks":"4:35 AM","kfta":"5:56 AM","szza":"11:55 AM","kzsa":"4:11 PM","gawi":"5:53 PM","kmsi":"7:14 PM"},
    {"id":"211","date":"5-10","sfks":"4:36 AM","kfta":"5:57 AM","szza":"11:54 AM","kzsa":"4:10 PM","gawi":"5:52 PM","kmsi":"7:13 PM"},
    {"id":"212","date":"6-10","sfks":"4:36 AM","kfta":"5:57 AM","szza":"11:54 AM","kzsa":"4:09 PM","gawi":"5:51 PM","kmsi":"7:12 PM"},
    {"id":"213","date":"7-10","sfks":"4:37 AM","kfta":"5:58 AM","szza":"11:53 AM","kzsa":"4:08 PM","gawi":"5:50 PM","kmsi":"7:11 PM"},
    {"id":"214","date":"8-10","sfks":"4:37 AM","kfta":"5:58 AM","szza":"11:53 AM","kzsa":"4:07 PM","gawi":"5:49 PM","kmsi":"7:10 PM"},
    {"id":"215","date":"9-10","sfks":"4:38 AM","kfta":"5:59 AM","szza":"11:53 AM","kzsa":"4:06 PM","gawi":"5:48 PM","kmsi":"7:09 PM"},
    {"id":"216","date":"10-10","sfks":"4:38 AM","kfta":"5:59 AM","szza":"11:53 AM","kzsa":"4:05 PM","gawi":"5:47 PM","kmsi":"7:08 PM"},
    {"id":"217","date":"11-10","sfks":"4:39 AM","kfta":"6:00 AM","szza":"11:53 AM","kzsa":"4:05 PM","gawi":"5:46 PM","kmsi":"7:07 PM"},
    {"id":"218","date":"12-10","sfks":"4:39 AM","kfta":"6:00 AM","szza":"11:52 AM","kzsa":"4:04 PM","gawi":"5:45 PM","kmsi":"7:06 PM"},
    {"id":"219","date":"13-10","sfks":"4:40 AM","kfta":"6:01 AM","szza":"11:52 AM","kzsa":"4:03 PM","gawi":"5:44 PM","kmsi":"7:05 PM"},
    {"id":"220","date":"14-10","sfks":"4:40 AM","kfta":"6:01 AM","szza":"11:52 AM","kzsa":"4:02 PM","gawi":"5:43 PM","kmsi":"7:04 PM"},
    {"id":"221","date":"15-10","sfks":"4:41 AM","kfta":"6:02 AM","szza":"11:52 AM","kzsa":"4:01 PM","gawi":"5:42 PM","kmsi":"7:03 PM"},
    {"id":"222","date":"16-10","sfks":"4:41 AM","kfta":"6:02 AM","szza":"11:51 AM","kzsa":"4:00 PM","gawi":"5:41 PM","kmsi":"7:02 PM"},
    {"id":"223","date":"17-10","sfks":"4:42 AM","kfta":"6:02 AM","szza":"11:51 AM","kzsa":"3:59 PM","gawi":"5:40 PM","kmsi":"7:01 PM"},
    {"id":"224","date":"18-10","sfks":"4:42 AM","kfta":"6:03 AM","szza":"11:51 AM","kzsa":"3:59 PM","gawi":"5:39 PM","kmsi":"7:00 PM"},
    {"id":"225","date":"19-10","sfks":"4:43 AM","kfta":"6:04 AM","szza":"11:51 AM","kzsa":"3:58 PM","gawi":"5:38 PM","kmsi":"6:59 PM"},
    {"id":"226","date":"20-10","sfks":"4:44 AM","kfta":"6:05 AM","szza":"11:51 AM","kzsa":"3:57 PM","gawi":"5:37 PM","kmsi":"6:58 PM"},
    {"id":"227","date":"21-10","sfks":"4:44 AM","kfta":"6:05 AM","szza":"11:51 AM","kzsa":"3:57 PM","gawi":"5:37 PM","kmsi":"6:57 PM"},
    {"id":"228","date":"22-10","sfks":"4:45 AM","kfta":"6:06 AM","szza":"11:51 AM","kzsa":"3:56 PM","gawi":"5:36 PM","kmsi":"6:56 PM"},
    {"id":"229","date":"23-10","sfks":"4:45 AM","kfta":"6:06 AM","szza":"11:51 AM","kzsa":"3:55 PM","gawi":"5:35 PM","kmsi":"6:56 PM"},
    {"id":"230","date":"24-10","sfks":"4:46 AM","kfta":"6:07 AM","szza":"11:51 AM","kzsa":"3:54 PM","gawi":"5:34 PM","kmsi":"6:55 PM"},
    {"id":"231","date":"25-10","sfks":"4:46 AM","kfta":"6:07 AM","szza":"11:50 AM","kzsa":"3:53 PM","gawi":"5:34 PM","kmsi":"6:55 PM"},
    {"id":"232","date":"26-10","sfks":"4:47 AM","kfta":"6:08 AM","szza":"11:50 AM","kzsa":"3:53 PM","gawi":"5:33 PM","kmsi":"6:54 PM"},
    {"id":"233","date":"27-10","sfks":"4:47 AM","kfta":"6:09 AM","szza":"11:50 AM","kzsa":"3:52 PM","gawi":"5:33 PM","kmsi":"6:54 PM"},
    {"id":"234","date":"28-10","sfks":"4:48 AM","kfta":"6:09 AM","szza":"11:50 AM","kzsa":"3:51 PM","gawi":"5:32 PM","kmsi":"6:53 PM"},
    {"id":"235","date":"29-10","sfks":"4:48 AM","kfta":"6:10 AM","szza":"11:50 AM","kzsa":"3:50 PM","gawi":"5:31 PM","kmsi":"6:52 PM"},
    {"id":"236","date":"30-10","sfks":"4:49 AM","kfta":"6:10 AM","szza":"11:50 AM","kzsa":"3:49 PM","gawi":"5:30 PM","kmsi":"6:52 PM"},
    {"id":"237","date":"31-10","sfks":"4:49 AM","kfta":"6:11 AM","szza":"11:50 AM","kzsa":"3:48 PM","gawi":"5:29 PM","kmsi":"6:51 PM"},
    {"id":"238","date":"1-12","sfks":"5:09 AM","kfta":"6:34 AM","szza":"11:55 AM","kzsa":"3:37 PM","gawi":"5:16 PM","kmsi":"6:41 PM"},
    {"id":"239","date":"2-12","sfks":"5:09 AM","kfta":"6:34 AM","szza":"11:56 AM","kzsa":"3:37 PM","gawi":"5:16 PM","kmsi":"6:41 PM"},
    {"id":"240","date":"3-12","sfks":"5:10 AM","kfta":"6:35 AM","szza":"11:56 AM","kzsa":"3:37 PM","gawi":"5:16 PM","kmsi":"6:41 PM"},
    {"id":"241","date":"4-12","sfks":"5:11 AM","kfta":"6:36 AM","szza":"11:57 AM","kzsa":"3:37 PM","gawi":"5:16 PM","kmsi":"6:41 PM"},
    {"id":"242","date":"5-12","sfks":"5:12 AM","kfta":"6:37 AM","szza":"11:57 AM","kzsa":"3:37 PM","gawi":"5:16 PM","kmsi":"6:41 PM"},
    {"id":"243","date":"6-12","sfks":"5:12 AM","kfta":"6:38 AM","szza":"11:58 AM","kzsa":"3:37 PM","gawi":"5:16 PM","kmsi":"6:42 PM"},
    {"id":"244","date":"7-12","sfks":"5:13 AM","kfta":"6:39 AM","szza":"11:58 AM","kzsa":"3:37 PM","gawi":"5:16 PM","kmsi":"6:42 PM"},
    {"id":"245","date":"8-12","sfks":"5:14 AM","kfta":"6:40 AM","szza":"11:58 AM","kzsa":"3:37 PM","gawi":"5:17 PM","kmsi":"6:42 PM"},
    {"id":"246","date":"9-12","sfks":"5:14 AM","kfta":"6:41 AM","szza":"11:59 AM","kzsa":"3:37 PM","gawi":"5:17 PM","kmsi":"6:42 PM"},
    {"id":"247","date":"10-12","sfks":"5:15 AM","kfta":"6:41 AM","szza":"11:59 AM","kzsa":"3:37 PM","gawi":"5:17 PM","kmsi":"6:42 PM"},
    {"id":"248","date":"11-12","sfks":"5:15 AM","kfta":"6:42 AM","szza":"11:59 AM","kzsa":"3:38 PM","gawi":"5:17 PM","kmsi":"6:43 PM"},
    {"id":"249","date":"12-12","sfks":"5:16 AM","kfta":"6:42 AM","szza":"12:00 PM","kzsa":"3:38 PM","gawi":"5:18 PM","kmsi":"6:43 PM"},
    {"id":"250","date":"13-12","sfks":"5:17 AM","kfta":"6:43 AM","szza":"12:00 PM","kzsa":"3:38 PM","gawi":"5:18 PM","kmsi":"6:44 PM"},
    {"id":"251","date":"14-12","sfks":"5:18 AM","kfta":"6:43 AM","szza":"12:01 PM","kzsa":"3:39 PM","gawi":"5:18 PM","kmsi":"6:44 PM"},
    {"id":"252","date":"15-12","sfks":"5:18 AM","kfta":"6:44 AM","szza":"12:01 PM","kzsa":"3:39 PM","gawi":"5:19 PM","kmsi":"6:45 PM"},
    {"id":"253","date":"16-12","sfks":"5:19 AM","kfta":"6:44 AM","szza":"12:02 PM","kzsa":"3:40 PM","gawi":"5:19 PM","kmsi":"6:45 PM"},
    {"id":"254","date":"17-12","sfks":"5:20 AM","kfta":"6:45 AM","szza":"12:02 PM","kzsa":"3:40 PM","gawi":"5:19 PM","kmsi":"6:46 PM"},
    {"id":"255","date":"18-12","sfks":"5:20 AM","kfta":"6:45 AM","szza":"12:02 PM","kzsa":"3:40 PM","gawi":"5:20 PM","kmsi":"6:46 PM"},
    {"id":"256","date":"19-12","sfks":"5:21 AM","kfta":"6:46 AM","szza":"12:03 PM","kzsa":"3:41 PM","gawi":"5:20 PM","kmsi":"6:47 PM"},
    {"id":"257","date":"20-12","sfks":"5:21 AM","kfta":"6:46 AM","szza":"12:03 PM","kzsa":"3:41 PM","gawi":"5:20 PM","kmsi":"6:47 PM"},
    {"id":"258","date":"21-12","sfks":"5:22 AM","kfta":"6:47 AM","szza":"12:03 PM","kzsa":"3:41 PM","gawi":"5:21 PM","kmsi":"6:48 PM"},
    {"id":"259","date":"22-12","sfks":"5:22 AM","kfta":"6:47 AM","szza":"12:04 PM","kzsa":"3:41 PM","gawi":"5:21 PM","kmsi":"6:48 PM"},
    {"id":"260","date":"23-12","sfks":"5:22 AM","kfta":"6:48 AM","szza":"12:04 PM","kzsa":"3:42 PM","gawi":"5:22 PM","kmsi":"6:49 PM"},
    {"id":"261","date":"24-12","sfks":"5:23 AM","kfta":"6:48 AM","szza":"12:05 PM","kzsa":"3:42 PM","gawi":"5:22 PM","kmsi":"6:49 PM"},
    {"id":"262","date":"25-12","sfks":"5:23 AM","kfta":"6:49 AM","szza":"12:05 PM","kzsa":"3:43 PM","gawi":"5:23 PM","kmsi":"6:50 PM"},
    {"id":"263","date":"26-12","sfks":"5:23 AM","kfta":"6:49 AM","szza":"12:06 PM","kzsa":"3:43 PM","gawi":"5:23 PM","kmsi":"6:50 PM"},
    {"id":"264","date":"27-12","sfks":"5:23 AM","kfta":"6:50 AM","szza":"12:06 PM","kzsa":"3:44 PM","gawi":"5:24 PM","kmsi":"6:51 PM"},
    {"id":"265","date":"28-12","sfks":"5:24 AM","kfta":"6:50 AM","szza":"12:07 PM","kzsa":"3:44 PM","gawi":"5:24 PM","kmsi":"6:51 PM"},
    {"id":"266","date":"29-12","sfks":"5:24 AM","kfta":"6:51 AM","szza":"12:07 PM","kzsa":"3:45 PM","gawi":"5:25 PM","kmsi":"6:52 PM"},
    {"id":"267","date":"30-12","sfks":"5:24 AM","kfta":"6:51 AM","szza":"12:08 PM","kzsa":"3:45 PM","gawi":"5:25 PM","kmsi":"6:52 PM"},
    {"id":"268","date":"31-12","sfks":"5:25 AM","kfta":"6:52 AM","szza":"12:08 PM","kzsa":"3:46 PM","gawi":"5:26 PM","kmsi":"6:53 PM"},
    {"id":"269","date":"1-1","sfks":"5:25 AM","kfta":"6:53 AM","szza":"12:09 PM","kzsa":"3:47 PM","gawi":"5:27 PM","kmsi":"6:53 PM"},
    {"id":"270","date":"2-1","sfks":"5:25 AM","kfta":"6:53 AM","szza":"12:09 PM","kzsa":"3:47 PM","gawi":"5:27 PM","kmsi":"6:54 PM"},
    {"id":"271","date":"3-1","sfks":"5:25 AM","kfta":"6:53 AM","szza":"12:10 PM","kzsa":"3:47 PM","gawi":"5:27 PM","kmsi":"6:53 PM"},
    {"id":"272","date":"4-1","sfks":"5:26 AM","kfta":"6:53 AM","szza":"12:10 PM","kzsa":"3:48 PM","gawi":"5:29 PM","kmsi":"6:56 PM"},
    {"id":"273","date":"5-1","sfks":"5:26 AM","kfta":"6:53 AM","szza":"12:11 PM","kzsa":"3:49 PM","gawi":"5:30 PM","kmsi":"6:57 PM"},
    {"id":"274","date":"6-1","sfks":"5:26 AM","kfta":"6:53 AM","szza":"12:11 PM","kzsa":"3:50 PM","gawi":"5:30 PM","kmsi":"6:58 PM"},
    {"id":"275","date":"7-1","sfks":"5:27 AM","kfta":"6:53 AM","szza":"12:12 PM","kzsa":"3:51 PM","gawi":"5:31 PM","kmsi":"6:58 PM"},
    {"id":"276","date":"8-1","sfks":"5:27 AM","kfta":"6:53 AM","szza":"12:12 PM","kzsa":"3:51 PM","gawi":"5:32 PM","kmsi":"6:59 PM"},
    {"id":"277","date":"9-1","sfks":"5:27 AM","kfta":"6:53 AM","szza":"12:13 PM","kzsa":"3:52 PM","gawi":"5:32 PM","kmsi":"6:59 PM"},
    {"id":"278","date":"10-1","sfks":"5:28 AM","kfta":"6:53 AM","szza":"12:13 PM","kzsa":"3:53 PM","gawi":"5:33 PM","kmsi":"7:00 PM"},
    {"id":"279","date":"11-1","sfks":"5:28 AM","kfta":"6:53 AM","szza":"12:13 PM","kzsa":"3:54 PM","gawi":"5:34 PM","kmsi":"7:00 PM"},
    {"id":"280","date":"12-1","sfks":"5:28 AM","kfta":"6:53 AM","szza":"12:14 PM","kzsa":"3:55 PM","gawi":"5:35 PM","kmsi":"7:01 PM"},
    {"id":"281","date":"13-1","sfks":"5:28 AM","kfta":"6:53 AM","szza":"12:14 PM","kzsa":"3:56 PM","gawi":"5:36 PM","kmsi":"7:01 PM"},
    {"id":"282","date":"14-1","sfks":"5:28 AM","kfta":"6:53 AM","szza":"12:15 PM","kzsa":"3:57 PM","gawi":"5:37 PM","kmsi":"7:02 PM"},
    {"id":"283","date":"15-1","sfks":"5:28 AM","kfta":"6:53 AM","szza":"12:15 PM","kzsa":"3:58 PM","gawi":"5:38 PM","kmsi":"7:02 PM"},
    {"id":"284","date":"16-1","sfks":"5:28 AM","kfta":"6:53 AM","szza":"12:16 PM","kzsa":"3:59 PM","gawi":"5:38 PM","kmsi":"7:03 PM"},
    {"id":"285","date":"17-1","sfks":"5:28 AM","kfta":"6:53 AM","szza":"12:16 PM","kzsa":"4:00 PM","gawi":"5:39 PM","kmsi":"7:03 PM"},
    {"id":"286","date":"18-1","sfks":"5:28 AM","kfta":"6:53 AM","szza":"12:17 PM","kzsa":"4:00 PM","gawi":"5:40 PM","kmsi":"7:04 PM"},
    {"id":"287","date":"19-1","sfks":"5:27 AM","kfta":"6:53 AM","szza":"12:17 PM","kzsa":"4:01 PM","gawi":"5:41 PM","kmsi":"7:04 PM"},
    {"id":"288","date":"20-1","sfks":"5:27 AM","kfta":"6:53 AM","szza":"12:17 PM","kzsa":"4:02 PM","gawi":"5:41 PM","kmsi":"7:05 PM"},
    {"id":"289","date":"21-1","sfks":"5:27 AM","kfta":"6:52 AM","szza":"12:18 PM","kzsa":"4:02 PM","gawi":"5:42 PM","kmsi":"7:05 PM"},
    {"id":"290","date":"22-1","sfks":"5:27 AM","kfta":"6:52 AM","szza":"12:18 PM","kzsa":"4:03 PM","gawi":"5:43 PM","kmsi":"7:06 PM"},
    {"id":"291","date":"23-1","sfks":"5:27 AM","kfta":"6:51 AM","szza":"12:18 PM","kzsa":"4:03 PM","gawi":"5:44 PM","kmsi":"7:07 PM"},
    {"id":"292","date":"24-1","sfks":"5:27 AM","kfta":"6:51 AM","szza":"12:19 PM","kzsa":"4:04 PM","gawi":"5:44 PM","kmsi":"7:08 PM"},
    {"id":"293","date":"25-1","sfks":"5:27 AM","kfta":"6:50 AM","szza":"12:19 PM","kzsa":"4:05 PM","gawi":"5:45 PM","kmsi":"7:08 PM"},
    {"id":"294","date":"26-1","sfks":"5:27 AM","kfta":"6:50 AM","szza":"12:19 PM","kzsa":"4:05 PM","gawi":"5:45 PM","kmsi":"7:09 PM"},
    {"id":"295","date":"27-1","sfks":"5:27 AM","kfta":"6:50 AM","szza":"12:20 PM","kzsa":"4:06 PM","gawi":"5:46 PM","kmsi":"7:10 PM"},
    {"id":"296","date":"28-1","sfks":"5:27 AM","kfta":"6:49 AM","szza":"12:20 PM","kzsa":"4:07 PM","gawi":"5:47 PM","kmsi":"7:11 PM"},
    {"id":"297","date":"29-1","sfks":"5:26 AM","kfta":"6:49 AM","szza":"12:20 PM","kzsa":"4:08 PM","gawi":"5:48 PM","kmsi":"7:12 PM"},
    {"id":"298","date":"30-1","sfks":"5:26 AM","kfta":"6:49 AM","szza":"12:21 PM","kzsa":"4:09 PM","gawi":"5:49 PM","kmsi":"7:13 PM"},
    {"id":"299","date":"31-1","sfks":"5:26 AM","kfta":"6:48 AM","szza":"12:21 PM","kzsa":"4:10 PM","gawi":"5:49 PM","kmsi":"7:14 PM"},
    {"id":"300","date":"1-2","sfks":"5:26 AM","kfta":"6:48 AM","szza":"12:21 PM","kzsa":"4:11 PM","gawi":"5:50 PM","kmsi":"7:14 PM"},
    {"id":"301","date":"2-2","sfks":"5:25 AM","kfta":"6:47 AM","szza":"12:21 PM","kzsa":"4:11 PM","gawi":"5:51 PM","kmsi":"7:15 PM"},
    {"id":"302","date":"3-2","sfks":"5:25 AM","kfta":"6:46 AM","szza":"12:21 PM","kzsa":"4:12 PM","gawi":"5:51 PM","kmsi":"7:15 PM"},
    {"id":"303","date":"4-2","sfks":"5:24 AM","kfta":"6:46 AM","szza":"12:21 PM","kzsa":"4:13 PM","gawi":"5:52 PM","kmsi":"7:16 PM"},
    {"id":"304","date":"5-2","sfks":"5:24 AM","kfta":"6:45 AM","szza":"12:21 PM","kzsa":"4:14 PM","gawi":"5:53 PM","kmsi":"7:16 PM"},
    {"id":"305","date":"6-2","sfks":"5:23 AM","kfta":"6:45 AM","szza":"12:21 PM","kzsa":"4:15 PM","gawi":"5:54 PM","kmsi":"7:17 PM"},
    {"id":"306","date":"7-2","sfks":"5:23 AM","kfta":"6:45 AM","szza":"12:21 PM","kzsa":"4:16 PM","gawi":"5:55 PM","kmsi":"7:17 PM"},
    {"id":"307","date":"8-2","sfks":"5:22 AM","kfta":"6:44 AM","szza":"12:21 PM","kzsa":"4:16 PM","gawi":"5:56 PM","kmsi":"7:18 PM"},
    {"id":"308","date":"9-2","sfks":"5:22 AM","kfta":"6:44 AM","szza":"12:21 PM","kzsa":"4:17 PM","gawi":"5:56 PM","kmsi":"7:18 PM"},
    {"id":"309","date":"10-2","sfks":"5:21 AM","kfta":"6:43 AM","szza":"12:21 PM","kzsa":"4:17 PM","gawi":"5:57 PM","kmsi":"7:19 PM"},
    {"id":"310","date":"11-2","sfks":"5:21 AM","kfta":"6:42 AM","szza":"12:21 PM","kzsa":"4:17 PM","gawi":"5:58 PM","kmsi":"7:19 PM"},
    {"id":"311","date":"12-2","sfks":"5:20 AM","kfta":"6:41 AM","szza":"12:21 PM","kzsa":"4:18 PM","gawi":"5:58 PM","kmsi":"7:20 PM"},
    {"id":"312","date":"13-2","sfks":"5:20 AM","kfta":"6:41 AM","szza":"12:21 PM","kzsa":"4:18 PM","gawi":"5:59 PM","kmsi":"7:21 PM"},
    {"id":"313","date":"14-2","sfks":"5:19 AM","kfta":"6:40 AM","szza":"12:21 PM","kzsa":"4:19 PM","gawi":"5:59 PM","kmsi":"7:21 PM"},
    {"id":"314","date":"15-2","sfks":"5:19 AM","kfta":"6:40 AM","szza":"12:21 PM","kzsa":"4:19 PM","gawi":"6:00 PM","kmsi":"7:22 PM"},
    {"id":"315","date":"16-2","sfks":"5:18 AM","kfta":"6:39 AM","szza":"12:21 PM","kzsa":"4:20 PM","gawi":"6:01 PM","kmsi":"7:22 PM"},
    {"id":"316","date":"17-2","sfks":"5:17 AM","kfta":"6:38 AM","szza":"12:21 PM","kzsa":"4:21 PM","gawi":"6:02 PM","kmsi":"7:23 PM"},
    {"id":"317","date":"18-2","sfks":"5:17 AM","kfta":"6:37 AM","szza":"12:21 PM","kzsa":"4:22 PM","gawi":"6:03 PM","kmsi":"7:23 PM"},
    {"id":"318","date":"19-2","sfks":"5:16 AM","kfta":"6:36 AM","szza":"12:21 PM","kzsa":"4:22 PM","gawi":"6:03 PM","kmsi":"7:24 PM"},
    {"id":"319","date":"20-2","sfks":"5:15 AM","kfta":"6:36 AM","szza":"12:21 PM","kzsa":"4:23 PM","gawi":"6:04 PM","kmsi":"7:25 PM"},
    {"id":"320","date":"21-2","sfks":"5:14 AM","kfta":"6:35 AM","szza":"12:21 PM","kzsa":"4:23 PM","gawi":"6:04 PM","kmsi":"7:26 PM"},
    {"id":"321","date":"22-2","sfks":"5:13 AM","kfta":"6:34 AM","szza":"12:20 PM","kzsa":"4:24 PM","gawi":"6:05 PM","kmsi":"7:26 PM"},
    {"id":"322","date":"23-2","sfks":"5:12 AM","kfta":"6:33 AM","szza":"12:20 PM","kzsa":"4:25 PM","gawi":"6:05 PM","kmsi":"7:27 PM"},
    {"id":"323","date":"24-2","sfks":"5:11 AM","kfta":"6:32 AM","szza":"12:20 PM","kzsa":"4:26 PM","gawi":"6:06 PM","kmsi":"7:27 PM"},
    {"id":"324","date":"25-2","sfks":"5:10 AM","kfta":"6:31 AM","szza":"12:20 PM","kzsa":"4:27 PM","gawi":"6:06 PM","kmsi":"7:28 PM"},
    {"id":"325","date":"26-2","sfks":"5:09 AM","kfta":"6:30 AM","szza":"12:20 PM","kzsa":"4:27 PM","gawi":"6:07 PM","kmsi":"7:28 PM"},
    {"id":"326","date":"27-2","sfks":"5:08 AM","kfta":"6:29 AM","szza":"12:20 PM","kzsa":"4:28 PM","gawi":"6:08 PM","kmsi":"7:29 PM"},
    {"id":"327","date":"28-2","sfks":"5:07 AM","kfta":"6:28 AM","szza":"12:19 PM","kzsa":"4:29 PM","gawi":"6:08 PM","kmsi":"7:29 PM"},
    {"id":"328","date":"29-2","sfks":"5:06 AM","kfta":"6:27 AM","szza":"12:19 PM","kzsa":"4:30 PM","gawi":"6:09 PM","kmsi":"7:30 PM"},
    {"id":"329","date":"1-3","sfks":"5:07 AM","kfta":"6:27 AM","szza":"12:19 PM","kzsa":"4:29 PM","gawi":"6:09 PM","kmsi":"7:30 PM"},
    {"id":"330","date":"2-3","sfks":"5:06 AM","kfta":"6:27 AM","szza":"12:19 PM","kzsa":"4:30 PM","gawi":"6:09 PM","kmsi":"7:30 PM"},
    {"id":"331","date":"3-3","sfks":"5:05 AM","kfta":"6:25 AM","szza":"12:18 PM","kzsa":"4:30 PM","gawi":"6:10 PM","kmsi":"7:31 PM"},
    {"id":"332","date":"4-3","sfks":"5:04 AM","kfta":"6:24 AM","szza":"12:18 PM","kzsa":"4:30 PM","gawi":"6:10 PM","kmsi":"7:31 PM"},
    {"id":"333","date":"5-3","sfks":"5:03 AM","kfta":"6:23 AM","szza":"12:18 PM","kzsa":"4:30 PM","gawi":"6:11 PM","kmsi":"7:32 PM"},
    {"id":"334","date":"6-3","sfks":"5:02 AM","kfta":"6:22 AM","szza":"12:18 PM","kzsa":"4:30 PM","gawi":"6:12 PM","kmsi":"7:33 PM"},
    {"id":"335","date":"7-3","sfks":"5:01 AM","kfta":"6:21 AM","szza":"12:17 PM","kzsa":"4:30 PM","gawi":"6:12 PM","kmsi":"7:34 PM"},
    {"id":"336","date":"8-3","sfks":"5:00 AM","kfta":"6:20 AM","szza":"12:17 PM","kzsa":"4:31 PM","gawi":"6:13 PM","kmsi":"7:35 PM"},
    {"id":"337","date":"9-3","sfks":"4:59 AM","kfta":"6:19 AM","szza":"12:17 PM","kzsa":"4:31 PM","gawi":"6:13 PM","kmsi":"7:35 PM"},
    {"id":"338","date":"10-3","sfks":"4:58 AM","kfta":"6:18 AM","szza":"12:17 PM","kzsa":"4:31 PM","gawi":"6:14 PM","kmsi":"7:36 PM"},
    {"id":"339","date":"11-3","sfks":"4:57 AM","kfta":"6:17 AM","szza":"12:17 PM","kzsa":"4:32 PM","gawi":"6:14 PM","kmsi":"7:36 PM"},
    {"id":"340","date":"12-3","sfks":"4:55 AM","kfta":"6:16 AM","szza":"12:16 PM","kzsa":"4:32 PM","gawi":"6:15 PM","kmsi":"7:37 PM"},
    {"id":"341","date":"13-3","sfks":"4:54 AM","kfta":"6:15 AM","szza":"12:16 PM","kzsa":"4:32 PM","gawi":"6:15 PM","kmsi":"7:37 PM"},
    {"id":"342","date":"14-3","sfks":"4:53 AM","kfta":"6:14 AM","szza":"12:16 PM","kzsa":"4:33 PM","gawi":"6:16 PM","kmsi":"7:38 PM"},
    {"id":"343","date":"15-3","sfks":"4:52 AM","kfta":"6:13 AM","szza":"12:15 PM","kzsa":"4:33 PM","gawi":"6:16 PM","kmsi":"7:38 PM"},
    {"id":"344","date":"16-3","sfks":"4:51 AM","kfta":"6:12 AM","szza":"12:15 PM","kzsa":"4:33 PM","gawi":"6:17 PM","kmsi":"7:39 PM"},
    {"id":"345","date":"17-3","sfks":"4:50 AM","kfta":"6:11 AM","szza":"12:15 PM","kzsa":"4:34 PM","gawi":"6:17 PM","kmsi":"7:39 PM"},
    {"id":"346","date":"18-3","sfks":"4:49 AM","kfta":"6:10 AM","szza":"12:14 PM","kzsa":"4:34 PM","gawi":"6:18 PM","kmsi":"7:40 PM"},
    {"id":"347","date":"19-3","sfks":"4:48 AM","kfta":"6:09 AM","szza":"12:14 PM","kzsa":"4:35 PM","gawi":"6:19 PM","kmsi":"7:40 PM"},
    {"id":"348","date":"20-3","sfks":"4:47 AM","kfta":"6:08 AM","szza":"12:14 PM","kzsa":"4:35 PM","gawi":"6:20 PM","kmsi":"7:41 PM"},
    {"id":"349","date":"21-3","sfks":"4:45 AM","kfta":"6:07 AM","szza":"12:14 PM","kzsa":"4:36 PM","gawi":"6:20 PM","kmsi":"7:41 PM"},
    {"id":"350","date":"22-3","sfks":"4:44 AM","kfta":"6:06 AM","szza":"12:13 PM","kzsa":"4:36 PM","gawi":"6:21 PM","kmsi":"7:42 PM"},
    {"id":"351","date":"23-3","sfks":"4:42 AM","kfta":"6:05 AM","szza":"12:13 PM","kzsa":"4:36 PM","gawi":"6:21 PM","kmsi":"7:42 PM"},
    {"id":"352","date":"24-3","sfks":"4:41 AM","kfta":"6:04 AM","szza":"12:13 PM","kzsa":"4:37 PM","gawi":"6:22 PM","kmsi":"7:43 PM"},
    {"id":"353","date":"25-3","sfks":"4:40 AM","kfta":"6:03 AM","szza":"12:12 PM","kzsa":"4:37 PM","gawi":"6:22 PM","kmsi":"7:43 PM"},
    {"id":"354","date":"26-3","sfks":"4:39 AM","kfta":"6:01 AM","szza":"12:12 PM","kzsa":"4:37 PM","gawi":"6:23 PM","kmsi":"7:44 PM"},
    {"id":"355","date":"27-3","sfks":"4:37 AM","kfta":"5:59 AM","szza":"12:12 PM","kzsa":"4:37 PM","gawi":"6:23 PM","kmsi":"7:44 PM"},
    {"id":"356","date":"28-3","sfks":"4:36 AM","kfta":"5:58 AM","szza":"12:11 PM","kzsa":"4:37 PM","gawi":"6:24 PM","kmsi":"7:45 PM"},
    {"id":"357","date":"29-3","sfks":"4:35 AM","kfta":"5:57 AM","szza":"12:11 PM","kzsa":"4:38 PM","gawi":"6:24 PM","kmsi":"7:46 PM"},
    {"id":"358","date":"30-3","sfks":"4:34 AM","kfta":"5:56 AM","szza":"12:11 PM","kzsa":"4:38 PM","gawi":"6:25 PM","kmsi":"7:47 PM"},
    {"id":"359","date":"31-3","sfks":"4:33 AM","kfta":"5:55 AM","szza":"12:10 PM","kzsa":"4:38 PM","gawi":"6:25 PM","kmsi":"7:47 PM"}
    ],
Delhi : [
    {"id":"1","date":"1-1","sfks":"5:49 AM","kfta":"7:13 AM","szza":"12:24 PM","kzsa":"3:58 PM","gawi":"5:35 PM","kmsi":"6:59 PM"},
    {"id":"2","date":"2-1","sfks":"5:50 AM","kfta":"7:14 AM","szza":"12:25 PM","kzsa":"3:59 PM","gawi":"5:36 PM","kmsi":"6:59 PM"},
    {"id":"3","date":"3-1","sfks":"5:50 AM","kfta":"7:14 AM","szza":"12:25 PM","kzsa":"3:59 PM","gawi":"5:36 PM","kmsi":"7:00 PM"},
    {"id":"4","date":"4-1","sfks":"5:51 AM","kfta":"7:14 AM","szza":"12:26 PM","kzsa":"4:00 PM","gawi":"5:37 PM","kmsi":"7:01 PM"},
    {"id":"5","date":"5-1","sfks":"5:51 AM","kfta":"7:15 AM","szza":"12:26 PM","kzsa":"4:01 PM","gawi":"5:38 PM","kmsi":"7:01 PM"},
    {"id":"6","date":"6-1","sfks":"5:51 AM","kfta":"7:15 AM","szza":"12:27 PM","kzsa":"4:01 PM","gawi":"5:39 PM","kmsi":"7:02 PM"},
    {"id":"7","date":"7-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:28 PM","kzsa":"4:02 PM","gawi":"5:40 PM","kmsi":"7:03 PM"},
    {"id":"8","date":"8-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:28 PM","kzsa":"4:03 PM","gawi":"5:40 PM","kmsi":"7:04 PM"},
    {"id":"9","date":"9-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:28 PM","kzsa":"4:04 PM","gawi":"5:41 PM","kmsi":"7:05 PM"},
    {"id":"10","date":"10-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:28 PM","kzsa":"4:05 PM","gawi":"5:41 PM","kmsi":"7:05 PM"},
    {"id":"11","date":"11-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:29 PM","kzsa":"4:05 PM","gawi":"5:42 PM","kmsi":"7:06 PM"},
    {"id":"12","date":"12-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:29 PM","kzsa":"4:06 PM","gawi":"5:43 PM","kmsi":"7:07 PM"},
    {"id":"13","date":"13-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:30 PM","kzsa":"4:07 PM","gawi":"5:44 PM","kmsi":"7:07 PM"},
    {"id":"14","date":"14-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:30 PM","kzsa":"4:08 PM","gawi":"5:45 PM","kmsi":"7:08 PM"},
    {"id":"15","date":"15-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:30 PM","kzsa":"4:09 PM","gawi":"5:45 PM","kmsi":"7:09 PM"},
    {"id":"16","date":"16-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:31 PM","kzsa":"4:10 PM","gawi":"5:46 PM","kmsi":"7:10 PM"},
    {"id":"17","date":"17-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:31 PM","kzsa":"4:11 PM","gawi":"5:47 PM","kmsi":"7:11 PM"},
    {"id":"18","date":"18-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:31 PM","kzsa":"4:12 PM","gawi":"5:48 PM","kmsi":"7:11 PM"},
    {"id":"19","date":"19-1","sfks":"5:52 AM","kfta":"7:15 AM","szza":"12:32 PM","kzsa":"4:12 PM","gawi":"5:49 PM","kmsi":"7:12 PM"},
    {"id":"20","date":"20-1","sfks":"5:52 AM","kfta":"7:14 AM","szza":"12:32 PM","kzsa":"4:13 PM","gawi":"5:50 PM","kmsi":"7:13 PM"},
    {"id":"21","date":"21-1","sfks":"5:52 AM","kfta":"7:14 AM","szza":"12:32 PM","kzsa":"4:13 PM","gawi":"5:51 PM","kmsi":"7:13 PM"},
    {"id":"22","date":"22-1","sfks":"5:52 AM","kfta":"7:14 AM","szza":"12:33 PM","kzsa":"4:14 PM","gawi":"5:52 PM","kmsi":"7:14 PM"},
    {"id":"23","date":"23-1","sfks":"5:52 AM","kfta":"7:14 AM","szza":"12:33 PM","kzsa":"4:15 PM","gawi":"5:52 PM","kmsi":"7:15 PM"},
    {"id":"24","date":"24-1","sfks":"5:52 AM","kfta":"7:14 AM","szza":"12:33 PM","kzsa":"4:16 PM","gawi":"5:53 PM","kmsi":"7:16 PM"},
    {"id":"25","date":"25-1","sfks":"5:51 AM","kfta":"7:13 AM","szza":"12:33 PM","kzsa":"4:17 PM","gawi":"5:54 PM","kmsi":"7:16 PM"},
    {"id":"26","date":"26-1","sfks":"5:51 AM","kfta":"7:13 AM","szza":"12:34 PM","kzsa":"4:18 PM","gawi":"5:55 PM","kmsi":"7:17 PM"},
    {"id":"27","date":"27-1","sfks":"5:51 AM","kfta":"7:12 AM","szza":"12:34 PM","kzsa":"4:18 PM","gawi":"5:55 PM","kmsi":"7:17 PM"},
    {"id":"28","date":"28-1","sfks":"5:51 AM","kfta":"7:12 AM","szza":"12:34 PM","kzsa":"4:19 PM","gawi":"5:56 PM","kmsi":"7:18 PM"},
    {"id":"29","date":"29-1","sfks":"5:51 AM","kfta":"7:11 AM","szza":"12:34 PM","kzsa":"4:20 PM","gawi":"5:57 PM","kmsi":"7:18 PM"},
    {"id":"30","date":"30-1","sfks":"5:50 AM","kfta":"7:11 AM","szza":"12:34 PM","kzsa":"4:21 PM","gawi":"5:58 PM","kmsi":"7:19 PM"},
    {"id":"31","date":"31-1","sfks":"5:50 AM","kfta":"7:11 AM","szza":"12:34 PM","kzsa":"4:22 PM","gawi":"5:59 PM","kmsi":"7:20 PM"},

    {"id":"32","date":"1-2","sfks":"5:50 AM","kfta":"7:11 AM","szza":"12:35 PM","kzsa":"4:23 PM","gawi":"6:00 PM","kmsi":"7:21 PM"},
    {"id":"33","date":"2-2","sfks":"5:50 AM","kfta":"7:11 AM","szza":"12:35 PM","kzsa":"4:23 PM","gawi":"6:00 PM","kmsi":"7:21 PM"},
    {"id":"34","date":"3-2","sfks":"5:49 AM","kfta":"7:10 AM","szza":"12:35 PM","kzsa":"4:24 PM","gawi":"6:01 PM","kmsi":"7:22 PM"},
    {"id":"35","date":"4-2","sfks":"5:48 AM","kfta":"7:09 AM","szza":"12:35 PM","kzsa":"4:25 PM","gawi":"6:02 PM","kmsi":"7:22 PM"},
    {"id":"36","date":"5-2","sfks":"5:47 AM","kfta":"7:08 AM","szza":"12:35 PM","kzsa":"4:26 PM","gawi":"6:03 PM","kmsi":"7:23 PM"},
    {"id":"37","date":"6-2","sfks":"5:47 AM","kfta":"7:07 AM","szza":"12:35 PM","kzsa":"4:27 PM","gawi":"6:04 PM","kmsi":"7:24 PM"},
    {"id":"38","date":"7-2","sfks":"5:46 AM","kfta":"7:06 AM","szza":"12:35 PM","kzsa":"4:27 PM","gawi":"6:04 PM","kmsi":"7:25 PM"},
    {"id":"39","date":"8-2","sfks":"5:46 AM","kfta":"7:06 AM","szza":"12:35 PM","kzsa":"4:28 PM","gawi":"6:05 PM","kmsi":"7:25 PM"},
    {"id":"40","date":"9-2","sfks":"5:45 AM","kfta":"7:05 AM","szza":"12:35 PM","kzsa":"4:29 PM","gawi":"6:06 PM","kmsi":"7:26 PM"},
    {"id":"41","date":"10-2","sfks":"5:45 AM","kfta":"7:05 AM","szza":"12:35 PM","kzsa":"4:30 PM","gawi":"6:07 PM","kmsi":"7:27 PM"},
    {"id":"42","date":"11-2","sfks":"5:44 AM","kfta":"7:04 AM","szza":"12:35 PM","kzsa":"4:30 PM","gawi":"6:08 PM","kmsi":"7:28 PM"},
    {"id":"43","date":"12-2","sfks":"5:43 AM","kfta":"7:03 AM","szza":"12:36 PM","kzsa":"4:31 PM","gawi":"6:08 PM","kmsi":"7:28 PM"},
    {"id":"44","date":"13-2","sfks":"5:43 AM","kfta":"7:03 AM","szza":"12:36 PM","kzsa":"4:32 PM","gawi":"6:09 PM","kmsi":"7:29 PM"},
    {"id":"45","date":"14-2","sfks":"5:42 AM","kfta":"7:02 AM","szza":"12:36 PM","kzsa":"4:33 PM","gawi":"6:10 PM","kmsi":"7:29 PM"},
    {"id":"46","date":"15-2","sfks":"5:41 AM","kfta":"7:01 AM","szza":"12:36 PM","kzsa":"4:33 PM","gawi":"6:10 PM","kmsi":"7:30 PM"},
    {"id":"47","date":"16-2","sfks":"5:41 AM","kfta":"7:00 AM","szza":"12:36 PM","kzsa":"4:34 PM","gawi":"6:11 PM","kmsi":"7:31 PM"},
    {"id":"48","date":"17-2","sfks":"5:40 AM","kfta":"6:59 AM","szza":"12:35 PM","kzsa":"4:35 PM","gawi":"6:12 PM","kmsi":"7:31 PM"},
    {"id":"49","date":"18-2","sfks":"5:39 AM","kfta":"6:58 AM","szza":"12:35 PM","kzsa":"4:35 PM","gawi":"6:13 PM","kmsi":"7:32 PM"},
    {"id":"50","date":"19-2","sfks":"5:39 AM","kfta":"6:57 AM","szza":"12:35 PM","kzsa":"4:36 PM","gawi":"6:14 PM","kmsi":"7:32 PM"},
    {"id":"51","date":"20-2","sfks":"5:38 AM","kfta":"6:57 AM","szza":"12:35 PM","kzsa":"4:36 PM","gawi":"6:14 PM","kmsi":"7:33 PM"},
    {"id":"52","date":"21-2","sfks":"5:37 AM","kfta":"6:56 AM","szza":"12:35 PM","kzsa":"4:37 PM","gawi":"6:15 PM","kmsi":"7:34 PM"},
    {"id":"53","date":"22-2","sfks":"5:36 AM","kfta":"6:55 AM","szza":"12:35 PM","kzsa":"4:38 PM","gawi":"6:15 PM","kmsi":"7:34 PM"},
    {"id":"54","date":"23-2","sfks":"5:35 AM","kfta":"6:54 AM","szza":"12:35 PM","kzsa":"4:38 PM","gawi":"6:16 PM","kmsi":"7:35 PM"},
    {"id":"55","date":"24-2","sfks":"5:34 AM","kfta":"6:53 AM","szza":"12:34 PM","kzsa":"4:39 PM","gawi":"6:17 PM","kmsi":"7:35 PM"},
    {"id":"56","date":"25-2","sfks":"5:33 AM","kfta":"6:53 AM","szza":"12:34 PM","kzsa":"4:39 PM","gawi":"6:17 PM","kmsi":"7:36 PM"},
    {"id":"57","date":"26-2","sfks":"5:32 AM","kfta":"6:51 AM","szza":"12:34 PM","kzsa":"4:40 PM","gawi":"6:18 PM","kmsi":"7:37 PM"},
    {"id":"58","date":"27-2","sfks":"5:31 AM","kfta":"6:50 AM","szza":"12:34 PM","kzsa":"4:40 PM","gawi":"6:19 PM","kmsi":"7:37 PM"},
    {"id":"59","date":"28-2","sfks":"5:30 AM","kfta":"6:49 AM","szza":"12:34 PM","kzsa":"4:41 PM","gawi":"6:19 PM","kmsi":"7:38 PM"},
    {"id":"60","date":"29-2","sfks":"5:29 AM","kfta":"6:49 AM","szza":"12:35 PM","kzsa":"4:41 PM","gawi":"6:20 PM","kmsi":"7:38 PM"},


    {"id":"61","date":"1-3","sfks":"5:29 AM","kfta":"6:48 AM","szza":"12:34 PM","kzsa":"4:42 PM","gawi":"6:20 PM","kmsi":"7:39 PM"},
    {"id":"62","date":"2-3","sfks":"5:28 AM","kfta":"6:47 AM","szza":"12:33 PM","kzsa":"4:42 PM","gawi":"6:21 PM","kmsi":"7:40 PM"},
    {"id":"63","date":"3-3","sfks":"5:27 AM","kfta":"6:46 AM","szza":"12:33 PM","kzsa":"4:43 PM","gawi":"6:21 PM","kmsi":"7:41 PM"},
    {"id":"64","date":"4-3","sfks":"5:26 AM","kfta":"6:45 AM","szza":"12:33 PM","kzsa":"4:43 PM","gawi":"6:22 PM","kmsi":"7:41 PM"},
    {"id":"65","date":"5-3","sfks":"5:25 AM","kfta":"6:44 AM","szza":"12:33 PM","kzsa":"4:43 PM","gawi":"6:23 PM","kmsi":"7:41 PM"},
    {"id":"66","date":"6-3","sfks":"5:24 AM","kfta":"6:43 AM","szza":"12:33 PM","kzsa":"4:44 PM","gawi":"6:23 PM","kmsi":"7:42 PM"},
    {"id":"67","date":"7-3","sfks":"5:23 AM","kfta":"6:42 AM","szza":"12:32 PM","kzsa":"4:44 PM","gawi":"6:24 PM","kmsi":"7:42 PM"},
    {"id":"68","date":"8-3","sfks":"5:22 AM","kfta":"6:41 AM","szza":"12:32 PM","kzsa":"4:45 PM","gawi":"6:25 PM","kmsi":"7:43 PM"},
    {"id":"69","date":"9-3","sfks":"5:21 AM","kfta":"6:40 AM","szza":"12:32 PM","kzsa":"4:45 PM","gawi":"6:25 PM","kmsi":"7:43 PM"},
    {"id":"70","date":"10-3","sfks":"5:20 AM","kfta":"6:39 AM","szza":"12:32 PM","kzsa":"4:46 PM","gawi":"6:26 PM","kmsi":"7:44 PM"},
    {"id":"71","date":"11-3","sfks":"5:19 AM","kfta":"6:38 AM","szza":"12:31 PM","kzsa":"4:46 PM","gawi":"6:26 PM","kmsi":"7:45 PM"},
    {"id":"72","date":"12-3","sfks":"5:18 AM","kfta":"6:37 AM","szza":"12:31 PM","kzsa":"4:47 PM","gawi":"6:27 PM","kmsi":"7:46 PM"},
    {"id":"73","date":"13-3","sfks":"5:17 AM","kfta":"6:36 AM","szza":"12:31 PM","kzsa":"4:47 PM","gawi":"6:27 PM","kmsi":"7:46 PM"},
    {"id":"74","date":"14-3","sfks":"5:16 AM","kfta":"6:34 AM","szza":"12:31 PM","kzsa":"4:47 PM","gawi":"6:28 PM","kmsi":"7:46 PM"},
    {"id":"75","date":"15-3","sfks":"5:15 AM","kfta":"6:33 AM","szza":"12:30 PM","kzsa":"4:48 PM","gawi":"6:28 PM","kmsi":"7:47 PM"},
    {"id":"76","date":"16-3","sfks":"5:14 AM","kfta":"6:32 AM","szza":"12:30 PM","kzsa":"4:48 PM","gawi":"6:29 PM","kmsi":"7:47 PM"},
    {"id":"77","date":"17-3","sfks":"5:12 AM","kfta":"6:31 AM","szza":"12:30 PM","kzsa":"4:48 PM","gawi":"6:30 PM","kmsi":"7:48 PM"},
    {"id":"78","date":"18-3","sfks":"5:11 AM","kfta":"6:30 AM","szza":"12:29 PM","kzsa":"4:49 PM","gawi":"6:31 PM","kmsi":"7:49 PM"},
    {"id":"79","date":"19-3","sfks":"5:10 AM","kfta":"6:28 AM","szza":"12:29 PM","kzsa":"4:49 PM","gawi":"6:31 PM","kmsi":"7:49 PM"},
    {"id":"80","date":"20-3","sfks":"5:08 AM","kfta":"6:27 AM","szza":"12:29 PM","kzsa":"4:50 PM","gawi":"6:32 PM","kmsi":"7:50 PM"},
    {"id":"81","date":"21-3","sfks":"5:07 AM","kfta":"6:26 AM","szza":"12:29 PM","kzsa":"4:50 PM","gawi":"6:32 PM","kmsi":"7:51 PM"},
    {"id":"82","date":"22-3","sfks":"5:06 AM","kfta":"6:25 AM","szza":"12:28 PM","kzsa":"4:50 PM","gawi":"6:33 PM","kmsi":"7:52 PM"},
    {"id":"83","date":"23-3","sfks":"5:05 AM","kfta":"6:24 AM","szza":"12:28 PM","kzsa":"4:51 PM","gawi":"6:33 PM","kmsi":"7:52 PM"},
    {"id":"84","date":"24-3","sfks":"5:04 AM","kfta":"6:23 AM","szza":"12:28 PM","kzsa":"4:51 PM","gawi":"6:34 PM","kmsi":"7:53 PM"},
    {"id":"85","date":"25-3","sfks":"5:02 AM","kfta":"6:22 AM","szza":"12:27 PM","kzsa":"4:51 PM","gawi":"6:34 PM","kmsi":"7:54 PM"},
    {"id":"86","date":"26-3","sfks":"5:01 AM","kfta":"6:20 AM","szza":"12:27 PM","kzsa":"4:52 PM","gawi":"6:35 PM","kmsi":"7:54 PM"},
    {"id":"87","date":"27-3","sfks":"5:00 AM","kfta":"6:19 AM","szza":"12:27 PM","kzsa":"4:52 PM","gawi":"6:36 PM","kmsi":"7:55 PM"},
    {"id":"88","date":"28-3","sfks":"4:59 AM","kfta":"6:18 AM","szza":"12:26 PM","kzsa":"4:52 PM","gawi":"6:36 PM","kmsi":"7:56 PM"},
    {"id":"89","date":"29-3","sfks":"4:57 AM","kfta":"6:16 AM","szza":"12:26 PM","kzsa":"4:52 PM","gawi":"6:37 PM","kmsi":"7:56 PM"},
    {"id":"90","date":"30-3","sfks":"4:56 AM","kfta":"6:15 AM","szza":"12:26 PM","kzsa":"4:53 PM","gawi":"6:37 PM","kmsi":"7:56 PM"},
    {"id":"91","date":"31-3","sfks":"4:55 AM","kfta":"6:14 AM","szza":"12:25 PM","kzsa":"4:53 PM","gawi":"6:38 PM","kmsi":"7:56 PM"},
    {"id":"92","date":"1-4","sfks":"4:53 AM","kfta":"6:13 AM","szza":"12:25 PM","kzsa":"4:53 PM","gawi":"6:39 PM","kmsi":"7:58 PM"},
    {"id":"93","date":"2-4","sfks":"4:52 AM","kfta":"6:12 AM","szza":"12:25 PM","kzsa":"4:54 PM","gawi":"6:39 PM","kmsi":"7:59 PM"},
    {"id":"94","date":"3-4","sfks":"4:51 AM","kfta":"6:11 AM","szza":"12:25 PM","kzsa":"4:54 PM","gawi":"6:40 PM","kmsi":"8:00 PM"},
    {"id":"95","date":"4-4","sfks":"4:50 AM","kfta":"6:10 AM","szza":"12:24 PM","kzsa":"4:54 PM","gawi":"6:40 PM","kmsi":"8:00 PM"},
    {"id":"96","date":"5-4","sfks":"4:48 AM","kfta":"6:09 AM","szza":"12:24 PM","kzsa":"4:54 PM","gawi":"6:41 PM","kmsi":"8:01 PM"},
    {"id":"97","date":"6-4","sfks":"4:47 AM","kfta":"6:08 AM","szza":"12:24 PM","kzsa":"4:55 PM","gawi":"6:42 PM","kmsi":"8:02 PM"},
    {"id":"98","date":"7-4","sfks":"4:46 AM","kfta":"6:07 AM","szza":"12:23 PM","kzsa":"4:55 PM","gawi":"6:42 PM","kmsi":"8:02 PM"},
    {"id":"99","date":"8-4","sfks":"4:45 AM","kfta":"6:06 AM","szza":"12:23 PM","kzsa":"4:55 PM","gawi":"6:42 PM","kmsi":"8:03 PM"},
    {"id":"100","date":"9-4","sfks":"4:43 AM","kfta":"6:05 AM","szza":"12:23 PM","kzsa":"4:55 PM","gawi":"6:43 PM","kmsi":"8:04 PM"},
    {"id":"101","date":"10-4","sfks":"4:42 AM","kfta":"6:04 AM","szza":"12:22 PM","kzsa":"4:55 PM","gawi":"6:43 PM","kmsi":"8:04 PM"},
    {"id":"102","date":"11-4","sfks":"4:41 AM","kfta":"6:03 AM","szza":"12:22 PM","kzsa":"4:56 PM","gawi":"6:44 PM","kmsi":"8:05 PM"},
    {"id":"103","date":"12-4","sfks":"4:39 AM","kfta":"6:02 AM","szza":"12:22 PM","kzsa":"4:56 PM","gawi":"6:45 PM","kmsi":"8:06 PM"},
    {"id":"104","date":"13-4","sfks":"4:38 AM","kfta":"6:01 AM","szza":"12:22 PM","kzsa":"4:56 PM","gawi":"6:45 PM","kmsi":"8:07 PM"},
    {"id":"105","date":"14-4","sfks":"4:37 AM","kfta":"5:59 AM","szza":"12:22 PM","kzsa":"4:56 PM","gawi":"6:46 PM","kmsi":"8:07 PM"},
    {"id":"106","date":"15-4","sfks":"4:36 AM","kfta":"5:58 AM","szza":"12:21 PM","kzsa":"4:57 PM","gawi":"6:46 PM","kmsi":"8:08 PM"},
    {"id":"107","date":"16-4","sfks":"4:34 AM","kfta":"5:57 AM","szza":"12:21 PM","kzsa":"4:57 PM","gawi":"6:47 PM","kmsi":"8:09 PM"},
    {"id":"108","date":"17-4","sfks":"4:33 AM","kfta":"5:55 AM","szza":"12:21 PM","kzsa":"4:57 PM","gawi":"6:47 PM","kmsi":"8:10 PM"},
    {"id":"109","date":"18-4","sfks":"4:32 AM","kfta":"5:54 AM","szza":"12:21 PM","kzsa":"4:57 PM","gawi":"6:48 PM","kmsi":"8:10 PM"},
    {"id":"110","date":"19-4","sfks":"4:31 AM","kfta":"5:53 AM","szza":"12:20 PM","kzsa":"4:58 PM","gawi":"6:49 PM","kmsi":"8:11 PM"},
    {"id":"111","date":"20-4","sfks":"4:30 AM","kfta":"5:52 AM","szza":"12:20 PM","kzsa":"4:58 PM","gawi":"6:49 PM","kmsi":"8:12 PM"},
    {"id":"112","date":"21-4","sfks":"4:28 AM","kfta":"5:51 AM","szza":"12:20 PM","kzsa":"4:58 PM","gawi":"6:50 PM","kmsi":"8:12 PM"},
    {"id":"113","date":"22-4","sfks":"4:27 AM","kfta":"5:50 AM","szza":"12:19 PM","kzsa":"4:58 PM","gawi":"6:50 PM","kmsi":"8:13 PM"},
    {"id":"114","date":"23-4","sfks":"4:26 AM","kfta":"5:49 AM","szza":"12:19 PM","kzsa":"4:58 PM","gawi":"6:51 PM","kmsi":"8:14 PM"},
    {"id":"115","date":"24-4","sfks":"4:25 AM","kfta":"5:48 AM","szza":"12:19 PM","kzsa":"4:59 PM","gawi":"6:51 PM","kmsi":"8:15 PM"},
    {"id":"116","date":"25-4","sfks":"4:24 AM","kfta":"5:47 AM","szza":"12:19 PM","kzsa":"4:59 PM","gawi":"6:52 PM","kmsi":"8:16 PM"},
    {"id":"117","date":"26-4","sfks":"4:22 AM","kfta":"5:46 AM","szza":"12:19 PM","kzsa":"4:59 PM","gawi":"6:53 PM","kmsi":"8:17 PM"},
    {"id":"118","date":"27-4","sfks":"4:21 AM","kfta":"5:46 AM","szza":"12:19 PM","kzsa":"4:59 PM","gawi":"6:53 PM","kmsi":"8:17 PM"},
    {"id":"119","date":"28-4","sfks":"4:20 AM","kfta":"5:45 AM","szza":"12:19 PM","kzsa":"4:59 PM","gawi":"6:54 PM","kmsi":"8:18 PM"},
    {"id":"120","date":"29-4","sfks":"4:19 AM","kfta":"5:44 AM","szza":"12:18 PM","kzsa":"5:00 PM","gawi":"6:55 PM","kmsi":"8:19 PM"},
    {"id":"121","date":"30-4","sfks":"4:18 AM","kfta":"5:43 AM","szza":"12:18 PM","kzsa":"5:00 PM","gawi":"6:55 PM","kmsi":"8:20 PM"},
    
    {"id":"122","date":"1-5","sfks":"4:17 AM","kfta":"5:42 AM","szza":"12:18 PM","kzsa":"5:00 PM","gawi":"6:56 PM","kmsi":"8:21 PM"},
    {"id":"123","date":"2-5","sfks":"4:15 AM","kfta":"5:41 AM","szza":"12:18 PM","kzsa":"5:00 PM","gawi":"6:56 PM","kmsi":"8:21 PM"},
    {"id":"124","date":"3-5","sfks":"4:14 AM","kfta":"5:40 AM","szza":"12:18 PM","kzsa":"5:00 PM","gawi":"6:57 PM","kmsi":"8:21 PM"},
    {"id":"125","date":"4-5","sfks":"4:13 AM","kfta":"5:39 AM","szza":"12:18 PM","kzsa":"5:00 PM","gawi":"6:57 PM","kmsi":"8:23 PM"},
    {"id":"126","date":"5-5","sfks":"4:12 AM","kfta":"5:39 AM","szza":"12:18 PM","kzsa":"5:01 PM","gawi":"6:58 PM","kmsi":"8:24 PM"},
    {"id":"127","date":"6-5","sfks":"4:11 AM","kfta":"5:38 AM","szza":"12:18 PM","kzsa":"5:01 PM","gawi":"6:59 PM","kmsi":"8:25 PM"},
    {"id":"128","date":"7-5","sfks":"4:10 AM","kfta":"5:37 AM","szza":"12:18 PM","kzsa":"5:01 PM","gawi":"6:59 PM","kmsi":"8:26 PM"},
    {"id":"129","date":"8-5","sfks":"4:09 AM","kfta":"5:36 AM","szza":"12:17 PM","kzsa":"5:01 PM","gawi":"7:00 PM","kmsi":"8:27 PM"},
    {"id":"130","date":"9-5","sfks":"4:08 AM","kfta":"5:35 AM","szza":"12:17 PM","kzsa":"5:02 PM","gawi":"7:00 PM","kmsi":"8:27 PM"},
    {"id":"131","date":"10-5","sfks":"4:07 AM","kfta":"5:34 AM","szza":"12:17 PM","kzsa":"5:02 PM","gawi":"7:01 PM","kmsi":"8:28 PM"},
    {"id":"132","date":"11-5","sfks":"4:06 AM","kfta":"5:34 AM","szza":"12:17 PM","kzsa":"5:02 PM","gawi":"7:02 PM","kmsi":"8:29 PM"},
    {"id":"133","date":"12-5","sfks":"4:05 AM","kfta":"5:33 AM","szza":"12:17 PM","kzsa":"5:02 PM","gawi":"7:02 PM","kmsi":"8:30 PM"},
    {"id":"134","date":"13-5","sfks":"4:04 AM","kfta":"5:32 AM","szza":"12:17 PM","kzsa":"5:02 PM","gawi":"7:03 PM","kmsi":"8:31 PM"},
    {"id":"135","date":"14-5","sfks":"4:03 AM","kfta":"5:31 AM","szza":"12:17 PM","kzsa":"5:03 PM","gawi":"7:04 PM","kmsi":"8:32 PM"},
    {"id":"136","date":"15-5","sfks":"4:02 AM","kfta":"5:31 AM","szza":"12:17 PM","kzsa":"5:03 PM","gawi":"7:04 PM","kmsi":"8:33 PM"},
    {"id":"137","date":"16-5","sfks":"4:02 AM","kfta":"5:30 AM","szza":"12:17 PM","kzsa":"5:03 PM","gawi":"7:05 PM","kmsi":"8:34 PM"},
    {"id":"138","date":"17-5","sfks":"4:01 AM","kfta":"5:30 AM","szza":"12:17 PM","kzsa":"5:04 PM","gawi":"7:05 PM","kmsi":"8:34 PM"},
    {"id":"139","date":"18-5","sfks":"4:00 AM","kfta":"5:29 AM","szza":"12:17 PM","kzsa":"5:04 PM","gawi":"7:06 PM","kmsi":"8:35 PM"},
    {"id":"140","date":"19-5","sfks":"3:59 AM","kfta":"5:29 AM","szza":"12:17 PM","kzsa":"5:04 PM","gawi":"7:06 PM","kmsi":"8:36 PM"},
    {"id":"141","date":"20-5","sfks":"3:59 AM","kfta":"5:28 AM","szza":"12:17 PM","kzsa":"5:04 PM","gawi":"7:07 PM","kmsi":"8:37 PM"},
    {"id":"142","date":"21-5","sfks":"3:58 AM","kfta":"5:28 AM","szza":"12:17 PM","kzsa":"5:04 PM","gawi":"7:07 PM","kmsi":"8:38 PM"},
    {"id":"143","date":"22-5","sfks":"3:57 AM","kfta":"5:27 AM","szza":"12:17 PM","kzsa":"5:05 PM","gawi":"7:08 PM","kmsi":"8:38 PM"},
    {"id":"144","date":"23-5","sfks":"3:57 AM","kfta":"5:27 AM","szza":"12:17 PM","kzsa":"5:05 PM","gawi":"7:09 PM","kmsi":"8:39 PM"},
    {"id":"145","date":"24-5","sfks":"3:56 AM","kfta":"5:26 AM","szza":"12:18 PM","kzsa":"5:05 PM","gawi":"7:09 PM","kmsi":"8:40 PM"},
    {"id":"146","date":"25-5","sfks":"3:55 AM","kfta":"5:26 AM","szza":"12:18 PM","kzsa":"5:06 PM","gawi":"7:10 PM","kmsi":"8:40 PM"},
    {"id":"147","date":"26-5","sfks":"3:55 AM","kfta":"5:25 AM","szza":"12:18 PM","kzsa":"5:06 PM","gawi":"7:10 PM","kmsi":"8:41 PM"},
    {"id":"148","date":"27-5","sfks":"3:54 AM","kfta":"5:25 AM","szza":"12:18 PM","kzsa":"5:06 PM","gawi":"7:11 PM","kmsi":"8:42 PM"},
    {"id":"149","date":"28-5","sfks":"3:53 AM","kfta":"5:25 AM","szza":"12:18 PM","kzsa":"5:06 PM","gawi":"7:11 PM","kmsi":"8:43 PM"},
    {"id":"150","date":"29-5","sfks":"3:53 AM","kfta":"5:24 AM","szza":"12:18 PM","kzsa":"5:07 PM","gawi":"7:12 PM","kmsi":"8:44 PM"},
    {"id":"151","date":"30-5","sfks":"3:52 AM","kfta":"5:24 AM","szza":"12:18 PM","kzsa":"5:07 PM","gawi":"7:12 PM","kmsi":"8:44 PM"},
    {"id":"152","date":"31-5","sfks":"3:52 AM","kfta":"5:24 AM","szza":"12:18 PM","kzsa":"5:07 PM","gawi":"7:13 PM","kmsi":"8:45 PM"},
    {"id":"153","date":"1-6","sfks":"3:51 AM","kfta":"5:24 AM","szza":"12:19 PM","kzsa":"5:08 PM","gawi":"7:14 PM","kmsi":"8:46 PM"},
    {"id":"154","date":"2-6","sfks":"3:51 AM","kfta":"5:23 AM","szza":"12:19 PM","kzsa":"5:08 PM","gawi":"7:14 PM","kmsi":"8:47 PM"},
    {"id":"155","date":"3-6","sfks":"3:51 AM","kfta":"5:23 AM","szza":"12:19 PM","kzsa":"5:08 PM","gawi":"7:15 PM","kmsi":"8:47 PM"},
    {"id":"156","date":"4-6","sfks":"3:50 AM","kfta":"5:23 AM","szza":"12:19 PM","kzsa":"5:08 PM","gawi":"7:15 PM","kmsi":"8:48 PM"},
    {"id":"157","date":"5-6","sfks":"3:50 AM","kfta":"5:23 AM","szza":"12:19 PM","kzsa":"5:09 PM","gawi":"7:15 PM","kmsi":"8:49 PM"},
    {"id":"158","date":"6-6","sfks":"3:50 AM","kfta":"5:23 AM","szza":"12:19 PM","kzsa":"5:09 PM","gawi":"7:16 PM","kmsi":"8:49 PM"},
    {"id":"159","date":"7-6","sfks":"3:50 AM","kfta":"5:23 AM","szza":"12:20 PM","kzsa":"5:09 PM","gawi":"7:17 PM","kmsi":"8:50 PM"},
    {"id":"160","date":"8-6","sfks":"3:49 AM","kfta":"5:23 AM","szza":"12:20 PM","kzsa":"5:10 PM","gawi":"7:17 PM","kmsi":"8:50 PM"},
    {"id":"161","date":"9-6","sfks":"3:49 AM","kfta":"5:23 AM","szza":"12:20 PM","kzsa":"5:10 PM","gawi":"7:17 PM","kmsi":"8:51 PM"},
    {"id":"162","date":"10-6","sfks":"3:49 AM","kfta":"5:22 AM","szza":"12:20 PM","kzsa":"5:10 PM","gawi":"7:18 PM","kmsi":"8:51 PM"},
    {"id":"163","date":"11-6","sfks":"3:49 AM","kfta":"5:22 AM","szza":"12:20 PM","kzsa":"5:10 PM","gawi":"7:18 PM","kmsi":"8:52 PM"},
    {"id":"164","date":"12-6","sfks":"3:48 AM","kfta":"5:22 AM","szza":"12:20 PM","kzsa":"5:11 PM","gawi":"7:19 PM","kmsi":"8:52 PM"},
    {"id":"165","date":"13-6","sfks":"3:48 AM","kfta":"5:22 AM","szza":"12:21 PM","kzsa":"5:11 PM","gawi":"7:19 PM","kmsi":"8:53 PM"},
    {"id":"166","date":"14-6","sfks":"3:48 AM","kfta":"5:22 AM","szza":"12:21 PM","kzsa":"5:11 PM","gawi":"7:19 PM","kmsi":"8:53 PM"},
    {"id":"167","date":"15-6","sfks":"3:48 AM","kfta":"5:22 AM","szza":"12:21 PM","kzsa":"5:12 PM","gawi":"7:19 PM","kmsi":"8:53 PM"},
    {"id":"168","date":"16-6","sfks":"3:49 AM","kfta":"5:23 AM","szza":"12:21 PM","kzsa":"5:12 PM","gawi":"7:20 PM","kmsi":"8:54 PM"},
    {"id":"169","date":"17-6","sfks":"3:49 AM","kfta":"5:23 AM","szza":"12:22 PM","kzsa":"5:12 PM","gawi":"7:20 PM","kmsi":"8:54 PM"},
    {"id":"170","date":"18-6","sfks":"3:49 AM","kfta":"5:23 AM","szza":"12:22 PM","kzsa":"5:13 PM","gawi":"7:20 PM","kmsi":"8:55 PM"},
    {"id":"171","date":"19-6","sfks":"3:49 AM","kfta":"5:23 AM","szza":"12:22 PM","kzsa":"5:13 PM","gawi":"7:21 PM","kmsi":"8:55 PM"},
    {"id":"172","date":"20-6","sfks":"3:49 AM","kfta":"5:23 AM","szza":"12:22 PM","kzsa":"5:13 PM","gawi":"7:21 PM","kmsi":"8:55 PM"},
    {"id":"173","date":"21-6","sfks":"3:49 AM","kfta":"5:23 AM","szza":"12:22 PM","kzsa":"5:14 PM","gawi":"7:21 PM","kmsi":"8:56 PM"},
    {"id":"174","date":"22-6","sfks":"3:49 AM","kfta":"5:23 AM","szza":"12:23 PM","kzsa":"5:14 PM","gawi":"7:22 PM","kmsi":"8:56 PM"},
    {"id":"175","date":"23-6","sfks":"3:49 AM","kfta":"5:23 AM","szza":"12:23 PM","kzsa":"5:14 PM","gawi":"7:22 PM","kmsi":"8:56 PM"},
    {"id":"176","date":"24-6","sfks":"3:49 AM","kfta":"5:24 AM","szza":"12:23 PM","kzsa":"5:14 PM","gawi":"7:22 PM","kmsi":"8:56 PM"},
    {"id":"177","date":"25-6","sfks":"3:50 AM","kfta":"5:24 AM","szza":"12:23 PM","kzsa":"5:14 PM","gawi":"7:22 PM","kmsi":"8:56 PM"},
    {"id":"178","date":"26-6","sfks":"3:50 AM","kfta":"5:24 AM","szza":"12:23 PM","kzsa":"5:14 PM","gawi":"7:22 PM","kmsi":"8:56 PM"},
    {"id":"179","date":"27-6","sfks":"3:50 AM","kfta":"5:25 AM","szza":"12:24 PM","kzsa":"5:15 PM","gawi":"7:22 PM","kmsi":"8:57 PM"},
    {"id":"180","date":"28-6","sfks":"3:51 AM","kfta":"5:25 AM","szza":"12:24 PM","kzsa":"5:15 PM","gawi":"7:22 PM","kmsi":"8:57 PM"},



    {"id":"181","date":"29-6","sfks":"3:51 AM","kfta":"5:25 AM","szza":"12:24 PM","kzsa":"5:15 PM","gawi":"7:23 PM","kmsi":"8:57 PM"},
    {"id":"182","date":"30-6","sfks":"3:52 AM","kfta":"5:26 AM","szza":"12:24 PM","kzsa":"5:15 PM","gawi":"7:23 PM","kmsi":"8:57 PM"},
    {"id":"183","date":"1-7","sfks":"3:52 AM","kfta":"5:26 AM","szza":"12:24 PM","kzsa":"5:15 PM","gawi":"7:23 PM","kmsi":"8:57 PM"},
    {"id":"184","date":"2-7","sfks":"3:52 AM","kfta":"5:26 AM","szza":"12:25 PM","kzsa":"5:15 PM","gawi":"7:23 PM","kmsi":"8:57 PM"},
    {"id":"185","date":"3-7","sfks":"3:53 AM","kfta":"5:27 AM","szza":"12:25 PM","kzsa":"5:15 PM","gawi":"7:23 PM","kmsi":"8:57 PM"},
    {"id":"186","date":"4-7","sfks":"3:53 AM","kfta":"5:27 AM","szza":"12:25 PM","kzsa":"5:15 PM","gawi":"7:23 PM","kmsi":"8:57 PM"},
    {"id":"187","date":"5-7","sfks":"3:54 AM","kfta":"5:27 AM","szza":"12:25 PM","kzsa":"5:15 PM","gawi":"7:23 PM","kmsi":"8:56 PM"},
    {"id":"188","date":"6-7","sfks":"3:54 AM","kfta":"5:28 AM","szza":"12:25 PM","kzsa":"5:15 PM","gawi":"7:23 PM","kmsi":"8:56 PM"},
    {"id":"189","date":"7-7","sfks":"3:55 AM","kfta":"5:28 AM","szza":"12:26 PM","kzsa":"5:16 PM","gawi":"7:23 PM","kmsi":"8:56 PM"},
    {"id":"190","date":"8-7","sfks":"3:55 AM","kfta":"5:29 AM","szza":"12:26 PM","kzsa":"5:16 PM","gawi":"7:23 PM","kmsi":"8:56 PM"},
    {"id":"191","date":"9-7","sfks":"3:56 AM","kfta":"5:29 AM","szza":"12:26 PM","kzsa":"5:16 PM","gawi":"7:22 PM","kmsi":"8:55 PM"},
    {"id":"192","date":"10-7","sfks":"3:57 AM","kfta":"5:30 AM","szza":"12:26 PM","kzsa":"5:16 PM","gawi":"7:22 PM","kmsi":"8:55 PM"},
    {"id":"193","date":"11-7","sfks":"3:57 AM","kfta":"5:30 AM","szza":"12:26 PM","kzsa":"5:16 PM","gawi":"7:22 PM","kmsi":"8:55 PM"},
    {"id":"194","date":"12-7","sfks":"3:58 AM","kfta":"5:30 AM","szza":"12:26 PM","kzsa":"5:16 PM","gawi":"7:22 PM","kmsi":"8:54 PM"},
    {"id":"195","date":"13-7","sfks":"3:58 AM","kfta":"5:31 AM","szza":"12:26 PM","kzsa":"5:16 PM","gawi":"7:22 PM","kmsi":"8:54 PM"},
    {"id":"196","date":"14-7","sfks":"3:59 AM","kfta":"5:31 AM","szza":"12:27 PM","kzsa":"5:16 PM","gawi":"7:21 PM","kmsi":"8:53 PM"},
    {"id":"197","date":"15-7","sfks":"4:00 AM","kfta":"5:32 AM","szza":"12:27 PM","kzsa":"5:16 PM","gawi":"7:21 PM","kmsi":"8:53 PM"},
    {"id":"198","date":"16-7","sfks":"4:00 AM","kfta":"5:32 AM","szza":"12:27 PM","kzsa":"5:16 PM","gawi":"7:21 PM","kmsi":"8:53 PM"},
    {"id":"199","date":"17-7","sfks":"4:01 AM","kfta":"5:33 AM","szza":"12:27 PM","kzsa":"5:15 PM","gawi":"7:20 PM","kmsi":"8:52 PM"},
    {"id":"200","date":"18-7","sfks":"4:02 AM","kfta":"5:33 AM","szza":"12:27 PM","kzsa":"5:15 PM","gawi":"7:20 PM","kmsi":"8:52 PM"},
    {"id":"201","date":"19-7","sfks":"4:03 AM","kfta":"5:34 AM","szza":"12:27 PM","kzsa":"5:15 PM","gawi":"7:20 PM","kmsi":"8:51 PM"},
    {"id":"202","date":"20-7","sfks":"4:03 AM","kfta":"5:34 AM","szza":"12:27 PM","kzsa":"5:15 PM","gawi":"7:19 PM","kmsi":"8:51 PM"},
    {"id":"203","date":"21-7","sfks":"4:04 AM","kfta":"5:35 AM","szza":"12:27 PM","kzsa":"5:15 PM","gawi":"7:19 PM","kmsi":"8:50 PM"},
    {"id":"204","date":"22-7","sfks":"4:05 AM","kfta":"5:36 AM","szza":"12:27 PM","kzsa":"5:15 PM","gawi":"7:18 PM","kmsi":"8:50 PM"},
    {"id":"205","date":"23-7","sfks":"4:06 AM","kfta":"5:36 AM","szza":"12:27 PM","kzsa":"5:15 PM","gawi":"7:18 PM","kmsi":"8:49 PM"},
    {"id":"206","date":"24-7","sfks":"4:06 AM","kfta":"5:37 AM","szza":"12:27 PM","kzsa":"5:15 PM","gawi":"7:17 PM","kmsi":"8:48 PM"},
    {"id":"207","date":"25-7","sfks":"4:07 AM","kfta":"5:37 AM","szza":"12:27 PM","kzsa":"5:14 PM","gawi":"7:17 PM","kmsi":"8:47 PM"},
    {"id":"208","date":"26-7","sfks":"4:08 AM","kfta":"5:38 AM","szza":"12:27 PM","kzsa":"5:14 PM","gawi":"7:16 PM","kmsi":"8:46 PM"},
    {"id":"209","date":"27-7","sfks":"4:09 AM","kfta":"5:38 AM","szza":"12:27 PM","kzsa":"5:14 PM","gawi":"7:16 PM","kmsi":"8:45 PM"},
    {"id":"210","date":"28-7","sfks":"4:10 AM","kfta":"5:39 AM","szza":"12:27 PM","kzsa":"5:14 PM","gawi":"7:15 PM","kmsi":"8:44 PM"},
    {"id":"211","date":"29-7","sfks":"4:10 AM","kfta":"5:39 AM","szza":"12:27 PM","kzsa":"5:13 PM","gawi":"7:15 PM","kmsi":"8:44 PM"},
    {"id":"212","date":"30-7","sfks":"4:11 AM","kfta":"5:40 AM","szza":"12:27 PM","kzsa":"5:13 PM","gawi":"7:14 PM","kmsi":"8:42 PM"},
    {"id":"213","date":"31-7","sfks":"4:12 AM","kfta":"5:40 AM","szza":"12:27 PM","kzsa":"5:13 PM","gawi":"7:14 PM","kmsi":"8:42 PM"},

    {"id":"214","date":"1-8","sfks":"4:12 AM","kfta":"5:41 AM","szza":"12:27 PM","kzsa":"5:12 PM","gawi":"7:13 PM","kmsi":"8:41 PM"},
    {"id":"215","date":"2-8","sfks":"4:13 AM","kfta":"5:41 AM","szza":"12:27 PM","kzsa":"5:12 PM","gawi":"7:12 PM","kmsi":"8:40 PM"},
    {"id":"216","date":"3-8","sfks":"4:14 AM","kfta":"5:42 AM","szza":"12:27 PM","kzsa":"5:12 PM","gawi":"7:12 PM","kmsi":"8:39 PM"},
    {"id":"217","date":"4-8","sfks":"4:15 AM","kfta":"5:43 AM","szza":"12:27 PM","kzsa":"5:12 PM","gawi":"7:11 PM","kmsi":"8:38 PM"},
    {"id":"218","date":"5-8","sfks":"4:16 AM","kfta":"5:43 AM","szza":"12:27 PM","kzsa":"5:11 PM","gawi":"7:10 PM","kmsi":"8:37 PM"},
    {"id":"219","date":"6-8","sfks":"4:17 AM","kfta":"5:44 AM","szza":"12:27 PM","kzsa":"5:11 PM","gawi":"7:09 PM","kmsi":"8:36 PM"},
    {"id":"220","date":"7-8","sfks":"4:18 AM","kfta":"5:45 AM","szza":"12:27 PM","kzsa":"5:11 PM","gawi":"7:08 PM","kmsi":"8:35 PM"},
    {"id":"221","date":"8-8","sfks":"4:19 AM","kfta":"5:45 AM","szza":"12:27 PM","kzsa":"5:10 PM","gawi":"7:08 PM","kmsi":"8:34 PM"},
    {"id":"222","date":"9-8","sfks":"4:19 AM","kfta":"5:46 AM","szza":"12:27 PM","kzsa":"5:10 PM","gawi":"7:07 PM","kmsi":"8:33 PM"},
    {"id":"223","date":"10-8","sfks":"4:20 AM","kfta":"5:46 AM","szza":"12:26 PM","kzsa":"5:09 PM","gawi":"7:06 PM","kmsi":"8:32 PM"},
    {"id":"224","date":"11-8","sfks":"4:21 AM","kfta":"5:47 AM","szza":"12:26 PM","kzsa":"5:09 PM","gawi":"7:05 PM","kmsi":"8:31 PM"},
    {"id":"225","date":"12-8","sfks":"4:22 AM","kfta":"5:47 AM","szza":"12:26 PM","kzsa":"5:08 PM","gawi":"7:04 PM","kmsi":"8:30 PM"},
    {"id":"226","date":"13-8","sfks":"4:23 AM","kfta":"5:48 AM","szza":"12:26 PM","kzsa":"5:08 PM","gawi":"7:03 PM","kmsi":"8:29 PM"},
    {"id":"227","date":"14-8","sfks":"4:23 AM","kfta":"5:48 AM","szza":"12:26 PM","kzsa":"5:07 PM","gawi":"7:02 PM","kmsi":"8:28 PM"},
    {"id":"228","date":"15-8","sfks":"4:24 AM","kfta":"5:49 AM","szza":"12:26 PM","kzsa":"5:07 PM","gawi":"7:01 PM","kmsi":"8:26 PM"},
    {"id":"229","date":"16-8","sfks":"4:25 AM","kfta":"5:50 AM","szza":"12:25 PM","kzsa":"5:06 PM","gawi":"7:00 PM","kmsi":"8:25 PM"},
    {"id":"230","date":"17-8","sfks":"4:26 AM","kfta":"5:51 AM","szza":"12:25 PM","kzsa":"5:06 PM","gawi":"6:59 PM","kmsi":"8:24 PM"},
    {"id":"231","date":"18-8","sfks":"4:26 AM","kfta":"5:51 AM","szza":"12:25 PM","kzsa":"5:05 PM","gawi":"6:59 PM","kmsi":"8:23 PM"},
    {"id":"232","date":"19-8","sfks":"4:27 AM","kfta":"5:52 AM","szza":"12:25 PM","kzsa":"5:04 PM","gawi":"6:58 PM","kmsi":"8:22 PM"},
    {"id":"233","date":"20-8","sfks":"4:28 AM","kfta":"5:52 AM","szza":"12:25 PM","kzsa":"5:04 PM","gawi":"6:57 PM","kmsi":"8:20 PM"},
    {"id":"234","date":"21-8","sfks":"4:29 AM","kfta":"5:52 AM","szza":"12:24 PM","kzsa":"5:03 PM","gawi":"6:56 PM","kmsi":"8:19 PM"},
    {"id":"235","date":"22-8","sfks":"4:30 AM","kfta":"5:53 AM","szza":"12:24 PM","kzsa":"5:02 PM","gawi":"6:55 PM","kmsi":"8:18 PM"},
    {"id":"236","date":"23-8","sfks":"4:30 AM","kfta":"5:53 AM","szza":"12:24 PM","kzsa":"5:02 PM","gawi":"6:54 PM","kmsi":"8:17 PM"},
    {"id":"237","date":"24-8","sfks":"4:31 AM","kfta":"5:54 AM","szza":"12:24 PM","kzsa":"5:01 PM","gawi":"6:53 PM","kmsi":"8:15 PM"},
    {"id":"238","date":"25-8","sfks":"4:32 AM","kfta":"5:55 AM","szza":"12:23 PM","kzsa":"5:01 PM","gawi":"6:52 PM","kmsi":"8:14 PM"},
    {"id":"239","date":"26-8","sfks":"4:33 AM","kfta":"5:55 AM","szza":"12:23 PM","kzsa":"5:01 PM","gawi":"6:50 PM","kmsi":"8:13 PM"},
    {"id":"240","date":"27-8","sfks":"4:33 AM","kfta":"5:56 AM","szza":"12:23 PM","kzsa":"4:59 PM","gawi":"6:49 PM","kmsi":"8:11 PM"},
    {"id":"241","date":"28-8","sfks":"4:34 AM","kfta":"5:56 AM","szza":"12:22 PM","kzsa":"4:58 PM","gawi":"6:48 PM","kmsi":"8:10 PM"},
    {"id":"242","date":"29-8","sfks":"4:35 AM","kfta":"5:57 AM","szza":"12:22 PM","kzsa":"4:57 PM","gawi":"6:47 PM","kmsi":"8:09 PM"},
    {"id":"243","date":"30-8","sfks":"4:35 AM","kfta":"5:57 AM","szza":"12:22 PM","kzsa":"4:57 PM","gawi":"6:46 PM","kmsi":"8:08 PM"},
    {"id":"243","date":"31-8","sfks":"4:36 AM","kfta":"5:58 AM","szza":"12:22 PM","kzsa":"4:56 PM","gawi":"6:45 PM","kmsi":"8:06 PM"},
    {"id":"244","date":"1-9","sfks":"4:37 AM","kfta":"5:58 AM","szza":"12:21 PM","kzsa":"4:55 PM","gawi":"6:44 PM","kmsi":"8:05 PM"},
    {"id":"245","date":"2-9","sfks":"4:37 AM","kfta":"5:58 AM","szza":"12:21 PM","kzsa":"4:54 PM","gawi":"6:43 PM","kmsi":"8:04 PM"},
    {"id":"246","date":"3-9","sfks":"4:38 AM","kfta":"5:59 AM","szza":"12:21 PM","kzsa":"4:54 PM","gawi":"6:42 PM","kmsi":"8:03 PM"},
    {"id":"247","date":"4-9","sfks":"4:39 AM","kfta":"6:00 AM","szza":"12:20 PM","kzsa":"4:53 PM","gawi":"6:40 PM","kmsi":"8:01 PM"},
    {"id":"248","date":"5-9","sfks":"4:39 AM","kfta":"6:00 AM","szza":"12:20 PM","kzsa":"4:52 PM","gawi":"6:39 PM","kmsi":"8:00 PM"},
    {"id":"249","date":"6-9","sfks":"4:40 AM","kfta":"6:01 AM","szza":"12:20 PM","kzsa":"4:51 PM","gawi":"6:38 PM","kmsi":"7:59 PM"},
    {"id":"250","date":"7-9","sfks":"4:41 AM","kfta":"6:01 AM","szza":"12:19 PM","kzsa":"4:50 PM","gawi":"6:37 PM","kmsi":"7:57 PM"},
    {"id":"251","date":"8-9","sfks":"4:41 AM","kfta":"6:02 AM","szza":"12:19 PM","kzsa":"4:49 PM","gawi":"6:36 PM","kmsi":"7:56 PM"},
    {"id":"252","date":"9-9","sfks":"4:42 AM","kfta":"6:02 AM","szza":"12:19 PM","kzsa":"4:48 PM","gawi":"6:35 PM","kmsi":"7:55 PM"},
    {"id":"253","date":"10-9","sfks":"4:43 AM","kfta":"6:03 AM","szza":"12:18 PM","kzsa":"4:48 PM","gawi":"6:33 PM","kmsi":"7:53 PM"},
    {"id":"254","date":"11-9","sfks":"4:43 AM","kfta":"6:03 AM","szza":"12:18 PM","kzsa":"4:47 PM","gawi":"6:32 PM","kmsi":"7:52 PM"},
    {"id":"255","date":"12-9","sfks":"4:44 AM","kfta":"6:04 AM","szza":"12:18 PM","kzsa":"4:46 PM","gawi":"6:31 PM","kmsi":"7:51 PM"},
    {"id":"256","date":"13-9","sfks":"4:44 AM","kfta":"6:04 AM","szza":"12:17 PM","kzsa":"4:45 PM","gawi":"6:30 PM","kmsi":"7:50 PM"},
    {"id":"257","date":"14-9","sfks":"4:45 AM","kfta":"6:05 AM","szza":"12:17 PM","kzsa":"4:44 PM","gawi":"6:29 PM","kmsi":"7:48 PM"},
    {"id":"258","date":"15-9","sfks":"4:46 AM","kfta":"6:05 AM","szza":"12:17 PM","kzsa":"4:43 PM","gawi":"6:27 PM","kmsi":"7:47 PM"},
    {"id":"259","date":"16-9","sfks":"4:46 AM","kfta":"6:06 AM","szza":"12:16 PM","kzsa":"4:42 PM","gawi":"6:26 PM","kmsi":"7:45 PM"},
    {"id":"260","date":"17-9","sfks":"4:47 AM","kfta":"6:06 AM","szza":"12:16 PM","kzsa":"4:41 PM","gawi":"6:25 PM","kmsi":"7:44 PM"},
    {"id":"261","date":"18-9","sfks":"4:48 AM","kfta":"6:07 AM","szza":"12:15 PM","kzsa":"4:40 PM","gawi":"6:24 PM","kmsi":"7:43 PM"},
    {"id":"262","date":"19-9","sfks":"4:49 AM","kfta":"6:07 AM","szza":"12:15 PM","kzsa":"4:39 PM","gawi":"6:22 PM","kmsi":"7:41 PM"},
    {"id":"263","date":"20-9","sfks":"4:49 AM","kfta":"6:08 AM","szza":"12:15 PM","kzsa":"4:38 PM","gawi":"6:21 PM","kmsi":"7:40 PM"},
    {"id":"264","date":"21-9","sfks":"4:49 AM","kfta":"6:08 AM","szza":"12:14 PM","kzsa":"4:37 PM","gawi":"6:20 PM","kmsi":"7:39 PM"},
    {"id":"265","date":"22-9","sfks":"4:50 AM","kfta":"6:09 AM","szza":"12:14 PM","kzsa":"4:36 PM","gawi":"6:19 PM","kmsi":"7:38 PM"},
    {"id":"266","date":"23-9","sfks":"4:50 AM","kfta":"6:09 AM","szza":"12:14 PM","kzsa":"4:35 PM","gawi":"6:18 PM","kmsi":"7:37 PM"},
    {"id":"267","date":"24-9","sfks":"4:51 AM","kfta":"6:09 AM","szza":"12:13 PM","kzsa":"4:35 PM","gawi":"6:17 PM","kmsi":"7:36 PM"},
    {"id":"268","date":"25-9","sfks":"4:51 AM","kfta":"6:10 AM","szza":"12:13 PM","kzsa":"4:34 PM","gawi":"6:16 PM","kmsi":"7:34 PM"},
    {"id":"269","date":"26-9","sfks":"4:52 AM","kfta":"6:10 AM","szza":"12:13 PM","kzsa":"4:33 PM","gawi":"6:15 PM","kmsi":"7:33 PM"},
    {"id":"270","date":"27-9","sfks":"4:52 AM","kfta":"6:11 AM","szza":"12:12 PM","kzsa":"4:32 PM","gawi":"6:13 PM","kmsi":"7:32 PM"},
    {"id":"271","date":"28-9","sfks":"4:53 AM","kfta":"6:11 AM","szza":"12:12 PM","kzsa":"4:31 PM","gawi":"6:12 PM","kmsi":"7:31 PM"},
    {"id":"272","date":"29-9","sfks":"4:53 AM","kfta":"6:12 AM","szza":"12:12 PM","kzsa":"4:30 PM","gawi":"6:11 PM","kmsi":"7:30 PM"},
    {"id":"273","date":"30-9","sfks":"4:54 AM","kfta":"6:12 AM","szza":"12:11 PM","kzsa":"4:29 PM","gawi":"6:10 PM","kmsi":"7:28 PM"},
    {"id":"274","date":"1-10","sfks":"4:54 AM","kfta":"6:13 AM","szza":"12:11 PM","kzsa":"4:28 PM","gawi":"6:09 PM","kmsi":"7:27 PM"},
    {"id":"275","date":"2-10","sfks":"4:55 AM","kfta":"6:13 AM","szza":"12:11 PM","kzsa":"4:27 PM","gawi":"6:07 PM","kmsi":"7:26 PM"},
    {"id":"276","date":"3-10","sfks":"4:55 AM","kfta":"6:14 AM","szza":"12:10 PM","kzsa":"4:26 PM","gawi":"6:06 PM","kmsi":"7:24 PM"},
    {"id":"277","date":"4-10","sfks":"4:56 AM","kfta":"6:15 AM","szza":"12:10 PM","kzsa":"4:25 PM","gawi":"6:05 PM","kmsi":"7:23 PM"},
    {"id":"278","date":"5-10","sfks":"4:57 AM","kfta":"6:15 AM","szza":"12:10 PM","kzsa":"4:24 PM","gawi":"6:04 PM","kmsi":"7:22 PM"},
    {"id":"279","date":"6-10","sfks":"4:57 AM","kfta":"6:15 AM","szza":"12:09 PM","kzsa":"4:23 PM","gawi":"6:03 PM","kmsi":"7:21 PM"},
    {"id":"280","date":"7-10","sfks":"4:58 AM","kfta":"6:16 AM","szza":"12:09 PM","kzsa":"4:22 PM","gawi":"6:02 PM","kmsi":"7:20 PM"},
    {"id":"281","date":"8-10","sfks":"4:58 AM","kfta":"6:17 AM","szza":"12:09 PM","kzsa":"4:21 PM","gawi":"6:00 PM","kmsi":"7:19 PM"},
    {"id":"282","date":"9-10","sfks":"4:59 AM","kfta":"6:17 AM","szza":"12:09 PM","kzsa":"4:20 PM","gawi":"5:59 PM","kmsi":"7:18 PM"},
    {"id":"283","date":"10-10","sfks":"4:59 AM","kfta":"6:18 AM","szza":"12:08 PM","kzsa":"4:19 PM","gawi":"5:58 PM","kmsi":"7:17 PM"},
    {"id":"284","date":"11-10","sfks":"5:00 AM","kfta":"6:18 AM","szza":"12:08 PM","kzsa":"4:18 PM","gawi":"5:57 PM","kmsi":"7:16 PM"},
    {"id":"285","date":"12-10","sfks":"5:00 AM","kfta":"6:19 AM","szza":"12:08 PM","kzsa":"4:17 PM","gawi":"5:56 PM","kmsi":"7:15 PM"},
    {"id":"286","date":"13-10","sfks":"5:01 AM","kfta":"6:20 AM","szza":"12:07 PM","kzsa":"4:16 PM","gawi":"5:55 PM","kmsi":"7:14 PM"},
    {"id":"287","date":"14-10","sfks":"5:01 AM","kfta":"6:21 AM","szza":"12:07 PM","kzsa":"4:15 PM","gawi":"5:54 PM","kmsi":"7:13 PM"},
    {"id":"288","date":"15-10","sfks":"5:02 AM","kfta":"6:21 AM","szza":"12:07 PM","kzsa":"4:14 PM","gawi":"5:53 PM","kmsi":"7:11 PM"},
    {"id":"289","date":"16-10","sfks":"5:03 AM","kfta":"6:21 AM","szza":"12:07 PM","kzsa":"4:13 PM","gawi":"5:52 PM","kmsi":"7:10 PM"},
    {"id":"290","date":"17-10","sfks":"5:03 AM","kfta":"6:22 AM","szza":"12:07 PM","kzsa":"4:12 PM","gawi":"5:51 PM","kmsi":"7:09 PM"},
    {"id":"291","date":"18-10","sfks":"5:04 AM","kfta":"6:23 AM","szza":"12:06 PM","kzsa":"4:11 PM","gawi":"5:50 PM","kmsi":"7:08 PM"},
    {"id":"292","date":"19-10","sfks":"5:04 AM","kfta":"6:23 AM","szza":"12:06 PM","kzsa":"4:11 PM","gawi":"5:49 PM","kmsi":"7:07 PM"},
    {"id":"293","date":"20-10","sfks":"5:05 AM","kfta":"6:23 AM","szza":"12:06 PM","kzsa":"4:10 PM","gawi":"5:48 PM","kmsi":"7:06 PM"},
    {"id":"294","date":"21-10","sfks":"5:06 AM","kfta":"6:24 AM","szza":"12:06 PM","kzsa":"4:09 PM","gawi":"5:47 PM","kmsi":"7:06 PM"},
    {"id":"295","date":"22-10","sfks":"5:06 AM","kfta":"6:25 AM","szza":"12:06 PM","kzsa":"4:08 PM","gawi":"5:46 PM","kmsi":"7:05 PM"},
    {"id":"296","date":"23-10","sfks":"5:07 AM","kfta":"6:26 AM","szza":"12:06 PM","kzsa":"4:07 PM","gawi":"5:45 PM","kmsi":"7:04 PM"},
    {"id":"297","date":"24-10","sfks":"5:07 AM","kfta":"6:26 AM","szza":"12:05 PM","kzsa":"4:06 PM","gawi":"5:44 PM","kmsi":"7:03 PM"},
    {"id":"298","date":"25-10","sfks":"5:08 AM","kfta":"6:27 AM","szza":"12:05 PM","kzsa":"4:05 PM","gawi":"5:43 PM","kmsi":"7:02 PM"},
    {"id":"299","date":"26-10","sfks":"5:09 AM","kfta":"6:28 AM","szza":"12:05 PM","kzsa":"4:05 PM","gawi":"5:42 PM","kmsi":"7:01 PM"},
    {"id":"300","date":"27-10","sfks":"5:09 AM","kfta":"6:29 AM","szza":"12:05 PM","kzsa":"4:04 PM","gawi":"5:41 PM","kmsi":"7:00 PM"},
    {"id":"301","date":"28-10","sfks":"5:10 AM","kfta":"6:29 AM","szza":"12:05 PM","kzsa":"4:03 PM","gawi":"5:40 PM","kmsi":"6:59 PM"},
    {"id":"302","date":"29-10","sfks":"5:10 AM","kfta":"6:30 AM","szza":"12:05 PM","kzsa":"4:02 PM","gawi":"5:39 PM","kmsi":"6:59 PM"},
    {"id":"303","date":"30-10","sfks":"5:11 AM","kfta":"6:30 AM","szza":"12:05 PM","kzsa":"4:01 PM","gawi":"5:38 PM","kmsi":"6:58 PM"},
    {"id":"304","date":"31-10","sfks":"5:11 AM","kfta":"6:31 AM","szza":"12:05 PM","kzsa":"4:01 PM","gawi":"5:38 PM","kmsi":"6:58 PM"},

    {"id":"305","date":"1-11","sfks":"5:12 AM","kfta":"6:32 AM","szza":"12:05 PM","kzsa":"4:00 PM","gawi":"5:37 PM","kmsi":"6:57 PM"},
    {"id":"306","date":"2-11","sfks":"5:13 AM","kfta":"6:33 AM","szza":"12:05 PM","kzsa":"3:59 PM","gawi":"5:36 PM","kmsi":"6:56 PM"},
    {"id":"307","date":"3-11","sfks":"5:13 AM","kfta":"6:33 AM","szza":"12:05 PM","kzsa":"3:58 PM","gawi":"5:35 PM","kmsi":"6:55 PM"},
    {"id":"308","date":"4-11","sfks":"5:14 AM","kfta":"6:34 AM","szza":"12:05 PM","kzsa":"3:58 PM","gawi":"5:34 PM","kmsi":"6:55 PM"},
    {"id":"309","date":"5-11","sfks":"5:15 AM","kfta":"6:35 AM","szza":"12:05 PM","kzsa":"3:57 PM","gawi":"5:34 PM","kmsi":"6:54 PM"},
    {"id":"310","date":"6-11","sfks":"5:15 AM","kfta":"6:36 AM","szza":"12:05 PM","kzsa":"3:56 PM","gawi":"5:33 PM","kmsi":"6:53 PM"},
    {"id":"311","date":"7-11","sfks":"5:16 AM","kfta":"6:36 AM","szza":"12:05 PM","kzsa":"3:56 PM","gawi":"5:32 PM","kmsi":"6:53 PM"},
    {"id":"312","date":"8-11","sfks":"5:17 AM","kfta":"6:37 AM","szza":"12:05 PM","kzsa":"3:55 PM","gawi":"5:32 PM","kmsi":"6:52 PM"},
    {"id":"313","date":"9-11","sfks":"5:17 AM","kfta":"6:38 AM","szza":"12:05 PM","kzsa":"3:54 PM","gawi":"5:31 PM","kmsi":"6:52 PM"},
    {"id":"314","date":"10-11","sfks":"5:18 AM","kfta":"6:39 AM","szza":"12:05 PM","kzsa":"3:54 PM","gawi":"5:31 PM","kmsi":"6:52 PM"},
    {"id":"315","date":"11-11","sfks":"5:19 AM","kfta":"6:39 AM","szza":"12:05 PM","kzsa":"3:53 PM","gawi":"5:30 PM","kmsi":"6:51 PM"},
    {"id":"316","date":"12-11","sfks":"5:19 AM","kfta":"6:40 AM","szza":"12:05 PM","kzsa":"3:53 PM","gawi":"5:29 PM","kmsi":"6:50 PM"},
    {"id":"317","date":"13-11","sfks":"5:20 AM","kfta":"6:41 AM","szza":"12:05 PM","kzsa":"3:52 PM","gawi":"5:29 PM","kmsi":"6:50 PM"},
    {"id":"318","date":"14-11","sfks":"5:20 AM","kfta":"6:42 AM","szza":"12:05 PM","kzsa":"3:52 PM","gawi":"5:28 PM","kmsi":"6:50 PM"},
    {"id":"319","date":"15-11","sfks":"5:21 AM","kfta":"6:42 AM","szza":"12:06 PM","kzsa":"3:51 PM","gawi":"5:28 PM","kmsi":"6:49 PM"},
    {"id":"320","date":"16-11","sfks":"5:22 AM","kfta":"6:43 AM","szza":"12:06 PM","kzsa":"3:51 PM","gawi":"5:27 PM","kmsi":"6:49 PM"},
    {"id":"321","date":"17-11","sfks":"5:22 AM","kfta":"6:44 AM","szza":"12:06 PM","kzsa":"3:51 PM","gawi":"5:27 PM","kmsi":"6:49 PM"},
    {"id":"322","date":"18-11","sfks":"5:23 AM","kfta":"6:45 AM","szza":"12:06 PM","kzsa":"3:50 PM","gawi":"5:26 PM","kmsi":"6:48 PM"},
    {"id":"323","date":"19-11","sfks":"5:24 AM","kfta":"6:46 AM","szza":"12:06 PM","kzsa":"3:50 PM","gawi":"5:26 PM","kmsi":"6:48 PM"},
    {"id":"324","date":"20-11","sfks":"5:25 AM","kfta":"6:46 AM","szza":"12:07 PM","kzsa":"3:49 PM","gawi":"5:26 PM","kmsi":"6:48 PM"},
    {"id":"325","date":"21-11","sfks":"5:25 AM","kfta":"6:47 AM","szza":"12:07 PM","kzsa":"3:49 PM","gawi":"5:26 PM","kmsi":"6:48 PM"},
    {"id":"326","date":"22-11","sfks":"5:26 AM","kfta":"6:48 AM","szza":"12:07 PM","kzsa":"3:49 PM","gawi":"5:25 PM","kmsi":"6:48 PM"},
    {"id":"327","date":"23-11","sfks":"5:27 AM","kfta":"6:49 AM","szza":"12:07 PM","kzsa":"3:48 PM","gawi":"5:25 PM","kmsi":"6:47 PM"},
    {"id":"328","date":"24-11","sfks":"5:27 AM","kfta":"6:50 AM","szza":"12:08 PM","kzsa":"3:48 PM","gawi":"5:25 PM","kmsi":"6:47 PM"},
    {"id":"329","date":"25-11","sfks":"5:28 AM","kfta":"6:50 AM","szza":"12:08 PM","kzsa":"3:48 PM","gawi":"5:24 PM","kmsi":"6:47 PM"},
    {"id":"330","date":"26-11","sfks":"5:29 AM","kfta":"6:51 AM","szza":"12:08 PM","kzsa":"3:47 PM","gawi":"5:24 PM","kmsi":"6:47 PM"},
    {"id":"331","date":"27-11","sfks":"5:29 AM","kfta":"6:52 AM","szza":"12:08 PM","kzsa":"3:47 PM","gawi":"5:24 PM","kmsi":"6:47 PM"},
    {"id":"332","date":"28-11","sfks":"5:30 AM","kfta":"6:53 AM","szza":"12:09 PM","kzsa":"3:47 PM","gawi":"5:24 PM","kmsi":"6:47 PM"},
    {"id":"333","date":"29-11","sfks":"5:30 AM","kfta":"6:54 AM","szza":"12:09 PM","kzsa":"3:47 PM","gawi":"5:24 PM","kmsi":"6:47 PM"},
    {"id":"334","date":"30-11","sfks":"5:31 AM","kfta":"6:54 AM","szza":"12:09 PM","kzsa":"3:47 PM","gawi":"5:24 PM","kmsi":"6:47 PM"},

   ],
Pilibhit : [
    {"id":"1","date":"1-1","sfks":"5:38 AM","kfta":"7:02 AM","szza":"12:13 PM","kzsa":"3:50 PM","gawi":"5:25 PM","kmsi":"6:49 PM"},
    {"id":"2","date":"2-1","sfks":"5:39 AM","kfta":"7:03 AM","szza":"12:14 PM","kzsa":"3:51 PM","gawi":"5:26 PM","kmsi":"6:49 PM"},
    {"id":"3","date":"3-1","sfks":"5:39 AM","kfta":"7:03 AM","szza":"12:14 PM","kzsa":"3:52 PM","gawi":"5:27 PM","kmsi":"6:50 PM"},
    {"id":"4","date":"4-1","sfks":"5:39 AM","kfta":"7:03 AM","szza":"12:15 PM","kzsa":"3:52 PM","gawi":"5:28 PM","kmsi":"6:50 PM"},
    {"id":"5","date":"5-1","sfks":"5:39 AM","kfta":"7:03 AM","szza":"12:15 PM","kzsa":"3:53 PM","gawi":"5:28 PM","kmsi":"6:51 PM"},
    {"id":"6","date":"6-1","sfks":"5:40 AM","kfta":"7:03 AM","szza":"12:16 PM","kzsa":"3:54 PM","gawi":"5:29 PM","kmsi":"6:52 PM"},
    {"id":"7","date":"7-1","sfks":"5:40 AM","kfta":"7:03 AM","szza":"12:16 PM","kzsa":"3:54 PM","gawi":"5:29 PM","kmsi":"6:52 PM"},
    {"id":"8","date":"8-1","sfks":"5:40 AM","kfta":"7:03 AM","szza":"12:16 PM","kzsa":"3:54 PM","gawi":"5:30 PM","kmsi":"6:53 PM"},
    {"id":"9","date":"9-1","sfks":"5:40 AM","kfta":"7:04 AM","szza":"12:16 PM","kzsa":"3:55 PM","gawi":"5:30 PM","kmsi":"6:54 PM"},
    {"id":"10","date":"10-1","sfks":"5:40 AM","kfta":"7:04 AM","szza":"12:17 PM","kzsa":"3:55 PM","gawi":"5:31 PM","kmsi":"6:54 PM"},
    {"id":"11","date":"11-1","sfks":"5:41 AM","kfta":"7:04 AM","szza":"12:17 PM","kzsa":"3:56 PM","gawi":"5:31 PM","kmsi":"6:55 PM"},
    {"id":"12","date":"12-1","sfks":"5:41 AM","kfta":"7:04 AM","szza":"12:18 PM","kzsa":"3:57 PM","gawi":"5:32 PM","kmsi":"6:56 PM"},
    {"id":"13","date":"13-1","sfks":"5:41 AM","kfta":"7:04 AM","szza":"12:18 PM","kzsa":"3:58 PM","gawi":"5:33 PM","kmsi":"6:57 PM"},
    {"id":"14","date":"14-1","sfks":"5:41 AM","kfta":"7:04 AM","szza":"12:18 PM","kzsa":"3:59 PM","gawi":"5:34 PM","kmsi":"6:57 PM"},
    {"id":"15","date":"15-1","sfks":"5:41 AM","kfta":"7:04 AM","szza":"12:19 PM","kzsa":"3:59 PM","gawi":"5:34 PM","kmsi":"6:58 PM"},
    {"id":"16","date":"16-1","sfks":"5:41 AM","kfta":"7:04 AM","szza":"12:19 PM","kzsa":"4:00 PM","gawi":"5:35 PM","kmsi":"6:59 PM"},
    {"id":"17","date":"17-1","sfks":"5:41 AM","kfta":"7:04 AM","szza":"12:20 PM","kzsa":"4:01 PM","gawi":"5:36 PM","kmsi":"6:59 PM"},
    {"id":"18","date":"18-1","sfks":"5:41 AM","kfta":"7:04 AM","szza":"12:20 PM","kzsa":"4:02 PM","gawi":"5:37 PM","kmsi":"7:00 PM"},
    {"id":"19","date":"19-1","sfks":"5:41 AM","kfta":"7:04 AM","szza":"12:20 PM","kzsa":"4:03 PM","gawi":"5:38 PM","kmsi":"7:01 PM"},
    {"id":"20","date":"20-1","sfks":"5:41 AM","kfta":"7:03 AM","szza":"12:21 PM","kzsa":"4:04 PM","gawi":"5:39 PM","kmsi":"7:02 PM"},
    {"id":"21","date":"21-1","sfks":"5:40 AM","kfta":"7:03 AM","szza":"12:21 PM","kzsa":"4:04 PM","gawi":"5:39 PM","kmsi":"7:02 PM"},
    {"id":"22","date":"22-1","sfks":"5:40 AM","kfta":"7:03 AM","szza":"12:21 PM","kzsa":"4:05 PM","gawi":"5:40 PM","kmsi":"7:03 PM"},
    {"id":"23","date":"23-1","sfks":"5:40 AM","kfta":"7:03 AM","szza":"12:21 PM","kzsa":"4:06 PM","gawi":"5:41 PM","kmsi":"7:04 PM"},
    {"id":"24","date":"24-1","sfks":"5:40 AM","kfta":"7:03 AM","szza":"12:22 PM","kzsa":"4:07 PM","gawi":"5:42 PM","kmsi":"7:04 PM"},
    {"id":"25","date":"25-1","sfks":"5:39 AM","kfta":"7:02 AM","szza":"12:22 PM","kzsa":"4:08 PM","gawi":"5:43 PM","kmsi":"7:05 PM"},
    {"id":"26","date":"26-1","sfks":"5:39 AM","kfta":"7:01 AM","szza":"12:23 PM","kzsa":"4:09 PM","gawi":"5:44 PM","kmsi":"7:06 PM"},
    {"id":"27","date":"27-1","sfks":"5:38 AM","kfta":"7:01 AM","szza":"12:23 PM","kzsa":"4:10 PM","gawi":"5:45 PM","kmsi":"7:06 PM"},
    {"id":"28","date":"28-1","sfks":"5:38 AM","kfta":"7:00 AM","szza":"12:23 PM","kzsa":"4:11 PM","gawi":"5:46 PM","kmsi":"7:07 PM"},
    {"id":"29","date":"29-1","sfks":"5:38 AM","kfta":"7:00 AM","szza":"12:23 PM","kzsa":"4:12 PM","gawi":"5:47 PM","kmsi":"7:08 PM"},
    {"id":"30","date":"30-1","sfks":"5:38 AM","kfta":"7:00 AM","szza":"12:23 PM","kzsa":"4:12 PM","gawi":"5:47 PM","kmsi":"7:09 PM"},
    {"id":"31","date":"31-1","sfks":"5:38 AM","kfta":"6:59 AM","szza":"12:23 PM","kzsa":"4:13 PM","gawi":"5:48 PM","kmsi":"7:10 PM"},

    
    {"id":"32","date":"1-2","sfks":"5:38 AM","kfta":"6:59 AM","szza":"12:23 PM","kzsa":"4:13 PM","gawi":"5:49 PM","kmsi":"7:10 PM"},
    {"id":"33","date":"2-2","sfks":"5:38 AM","kfta":"6:59 AM","szza":"12:24 PM","kzsa":"4:14 PM","gawi":"5:50 PM","kmsi":"7:11 PM"},
    {"id":"34","date":"3-2","sfks":"5:37 AM","kfta":"6:58 AM","szza":"12:24 PM","kzsa":"4:15 PM","gawi":"5:51 PM","kmsi":"7:11 PM"},
    {"id":"35","date":"4-2","sfks":"5:37 AM","kfta":"6:57 AM","szza":"12:24 PM","kzsa":"4:16 PM","gawi":"5:52 PM","kmsi":"7:12 PM"},
    {"id":"36","date":"5-2","sfks":"5:36 AM","kfta":"6:56 AM","szza":"12:24 PM","kzsa":"4:17 PM","gawi":"5:52 PM","kmsi":"7:13 PM"},
    {"id":"37","date":"6-2","sfks":"5:36 AM","kfta":"6:56 AM","szza":"12:24 PM","kzsa":"4:18 PM","gawi":"5:53 PM","kmsi":"7:13 PM"},
    {"id":"38","date":"7-2","sfks":"5:35 AM","kfta":"6:55 AM","szza":"12:24 PM","kzsa":"4:19 PM","gawi":"5:54 PM","kmsi":"7:14 PM"},
    {"id":"39","date":"8-2","sfks":"5:35 AM","kfta":"6:55 AM","szza":"12:24 PM","kzsa":"4:19 PM","gawi":"5:55 PM","kmsi":"7:15 PM"},
    {"id":"40","date":"9-2","sfks":"5:34 AM","kfta":"6:54 AM","szza":"12:24 PM","kzsa":"4:20 PM","gawi":"5:55 PM","kmsi":"7:15 PM"},
    {"id":"41","date":"10-2","sfks":"5:34 AM","kfta":"6:53 AM","szza":"12:24 PM","kzsa":"4:21 PM","gawi":"5:56 PM","kmsi":"7:16 PM"},
    {"id":"42","date":"11-2","sfks":"5:33 AM","kfta":"6:52 AM","szza":"12:24 PM","kzsa":"4:21 PM","gawi":"5:57 PM","kmsi":"7:17 PM"},
    {"id":"43","date":"12-2","sfks":"5:32 AM","kfta":"6:52 AM","szza":"12:24 PM","kzsa":"4:22 PM","gawi":"5:58 PM","kmsi":"7:17 PM"},
    {"id":"44","date":"13-2","sfks":"5:32 AM","kfta":"6:51 AM","szza":"12:24 PM","kzsa":"4:23 PM","gawi":"5:59 PM","kmsi":"7:18 PM"},
    {"id":"45","date":"14-2","sfks":"5:31 AM","kfta":"6:50 AM","szza":"12:24 PM","kzsa":"4:24 PM","gawi":"5:59 PM","kmsi":"7:18 PM"},
    {"id":"46","date":"15-2","sfks":"5:31 AM","kfta":"6:49 AM","szza":"12:24 PM","kzsa":"4:24 PM","gawi":"6:00 PM","kmsi":"7:19 PM"},
    {"id":"47","date":"16-2","sfks":"5:31 AM","kfta":"6:48 AM","szza":"12:24 PM","kzsa":"4:25 PM","gawi":"6:01 PM","kmsi":"7:20 PM"},
    {"id":"48","date":"17-2","sfks":"5:29 AM","kfta":"6:47 AM","szza":"12:24 PM","kzsa":"4:25 PM","gawi":"6:02 PM","kmsi":"7:20 PM"},
    {"id":"49","date":"18-2","sfks":"5:26 AM","kfta":"6:45 AM","szza":"12:24 PM","kzsa":"4:26 PM","gawi":"6:03 PM","kmsi":"7:21 PM"},
    {"id":"50","date":"19-2","sfks":"5:24 AM","kfta":"6:44 AM","szza":"12:24 PM","kzsa":"4:27 PM","gawi":"6:03 PM","kmsi":"7:22 PM"},
    {"id":"51","date":"20-2","sfks":"5:23 AM","kfta":"6:43 AM","szza":"12:24 PM","kzsa":"4:27 PM","gawi":"6:04 PM","kmsi":"7:22 PM"},
    {"id":"52","date":"21-2","sfks":"5:22 AM","kfta":"6:42 AM","szza":"12:24 PM","kzsa":"4:28 PM","gawi":"6:04 PM","kmsi":"7:23 PM"},
    {"id":"53","date":"22-2","sfks":"5:21 AM","kfta":"6:42 AM","szza":"12:23 PM","kzsa":"4:29 PM","gawi":"6:05 PM","kmsi":"7:24 PM"},
    {"id":"54","date":"23-2","sfks":"5:20 AM","kfta":"6:41 AM","szza":"12:23 PM","kzsa":"4:30 PM","gawi":"6:06 PM","kmsi":"7:24 PM"},
    {"id":"55","date":"24-2","sfks":"5:19 AM","kfta":"6:40 AM","szza":"12:23 PM","kzsa":"4:30 PM","gawi":"6:06 PM","kmsi":"7:25 PM"},
    {"id":"56","date":"25-2","sfks":"5:19 AM","kfta":"6:39 AM","szza":"12:23 PM","kzsa":"4:31 PM","gawi":"6:07 PM","kmsi":"7:25 PM"},
    {"id":"57","date":"26-2","sfks":"5:18 AM","kfta":"6:38 AM","szza":"12:23 PM","kzsa":"4:31 PM","gawi":"6:08 PM","kmsi":"7:26 PM"},
    {"id":"58","date":"27-2","sfks":"5:18 AM","kfta":"6:37 AM","szza":"12:22 PM","kzsa":"4:32 PM","gawi":"6:08 PM","kmsi":"7:26 PM"},
    {"id":"59","date":"28-2","sfks":"5:18 AM","kfta":"6:36 AM","szza":"12:22 PM","kzsa":"4:32 PM","gawi":"6:09 PM","kmsi":"7:27 PM"},
    {"id":"60","date":"29-2","sfks":"5:17 AM","kfta":"6:35 AM","szza":"12:23 PM","kzsa":"4:32 PM","gawi":"6:09 PM","kmsi":"7:27 PM"},

    {"id":"61","date":"1-3","sfks":"5:17 AM","kfta":"6:35 AM","szza":"12:23 PM","kzsa":"4:33 PM","gawi":"6:09 PM","kmsi":"7:28 PM"},
    {"id":"62","date":"2-3","sfks":"5:16 AM","kfta":"6:34 AM","szza":"12:23 PM","kzsa":"4:34 PM","gawi":"6:10 PM","kmsi":"7:28 PM"},
    {"id":"63","date":"3-3","sfks":"5:15 AM","kfta":"6:33 AM","szza":"12:22 PM","kzsa":"4:35 PM","gawi":"6:11 PM","kmsi":"7:29 PM"},
    {"id":"64","date":"4-3","sfks":"5:14 AM","kfta":"6:32 AM","szza":"12:22 PM","kzsa":"4:35 PM","gawi":"6:11 PM","kmsi":"7:30 PM"},
    {"id":"65","date":"5-3","sfks":"5:13 AM","kfta":"6:31 AM","szza":"12:22 PM","kzsa":"4:35 PM","gawi":"6:13 PM","kmsi":"7:30 PM"},
    {"id":"66","date":"6-3","sfks":"5:13 AM","kfta":"6:30 AM","szza":"12:22 PM","kzsa":"4:35 PM","gawi":"6:13 PM","kmsi":"7:30 PM"},
    {"id":"67","date":"7-3","sfks":"5:12 AM","kfta":"6:29 AM","szza":"12:21 PM","kzsa":"4:35 PM","gawi":"6:14 PM","kmsi":"7:31 PM"},
    {"id":"68","date":"8-3","sfks":"5:11 AM","kfta":"6:28 AM","szza":"12:21 PM","kzsa":"4:36 PM","gawi":"6:14 PM","kmsi":"7:31 PM"},
    {"id":"69","date":"9-3","sfks":"5:10 AM","kfta":"6:27 AM","szza":"12:21 PM","kzsa":"4:36 PM","gawi":"6:15 PM","kmsi":"7:32 PM"},
    {"id":"70","date":"10-3","sfks":"5:09 AM","kfta":"6:26 AM","szza":"12:21 PM","kzsa":"4:36 PM","gawi":"6:15 PM","kmsi":"7:33 PM"},

    {"id":"71","date":"11-3","sfks":"5:08 AM","kfta":"6:25 AM","szza":"12:20 PM","kzsa":"4:37 PM","gawi":"6:16 PM","kmsi":"7:33 PM"},
    {"id":"72","date":"12-3","sfks":"5:07 AM","kfta":"6:24 AM","szza":"12:20 PM","kzsa":"4:37 PM","gawi":"6:16 PM","kmsi":"7:34 PM"},
    {"id":"73","date":"13-3","sfks":"5:06 AM","kfta":"6:23 AM","szza":"12:20 PM","kzsa":"4:37 PM","gawi":"6:17 PM","kmsi":"7:35 PM"},
    {"id":"74","date":"14-3","sfks":"5:05 AM","kfta":"6:22 AM","szza":"12:19 PM","kzsa":"4:38 PM","gawi":"6:17 PM","kmsi":"7:35 PM"},
    {"id":"75","date":"15-3","sfks":"5:04 AM","kfta":"6:21 AM","szza":"12:19 PM","kzsa":"4:38 PM","gawi":"6:18 PM","kmsi":"7:36 PM"},
    {"id":"76","date":"16-3","sfks":"5:03 AM","kfta":"6:20 AM","szza":"12:19 PM","kzsa":"4:38 PM","gawi":"6:19 PM","kmsi":"7:37 PM"},
    {"id":"77","date":"17-3","sfks":"5:02 AM","kfta":"6:18 AM","szza":"12:18 PM","kzsa":"4:39 PM","gawi":"6:19 PM","kmsi":"7:37 PM"},
    {"id":"78","date":"18-3","sfks":"5:01 AM","kfta":"6:17 AM","szza":"12:18 PM","kzsa":"4:39 PM","gawi":"6:20 PM","kmsi":"7:38 PM"},
    {"id":"79","date":"19-3","sfks":"5:00 AM","kfta":"6:16 AM","szza":"12:18 PM","kzsa":"4:40 PM","gawi":"6:20 PM","kmsi":"7:39 PM"},
    {"id":"80","date":"20-3","sfks":"4:57 AM","kfta":"6:15 AM","szza":"12:18 PM","kzsa":"4:40 PM","gawi":"6:21 PM","kmsi":"7:39 PM"},
    {"id":"81","date":"21-3","sfks":"4:56 AM","kfta":"6:14 AM","szza":"12:17 PM","kzsa":"4:41 PM","gawi":"6:21 PM","kmsi":"7:40 PM"},
    {"id":"82","date":"22-3","sfks":"4:55 AM","kfta":"6:13 AM","szza":"12:17 PM","kzsa":"4:41 PM","gawi":"6:22 PM","kmsi":"7:40 PM"},
    {"id":"83","date":"23-3","sfks":"4:54 AM","kfta":"6:12 AM","szza":"12:17 PM","kzsa":"4:41 PM","gawi":"6:22 PM","kmsi":"7:41 PM"},
    {"id":"84","date":"24-3","sfks":"4:52 AM","kfta":"6:11 AM","szza":"12:16 PM","kzsa":"4:42 PM","gawi":"6:23 PM","kmsi":"7:41 PM"},
    {"id":"85","date":"25-3","sfks":"4:51 AM","kfta":"6:09 AM","szza":"12:16 PM","kzsa":"4:42 PM","gawi":"6:23 PM","kmsi":"7:42 PM"},
    {"id":"86","date":"26-3","sfks":"4:49 AM","kfta":"6:08 AM","szza":"12:16 PM","kzsa":"4:42 PM","gawi":"6:24 PM","kmsi":"7:43 PM"},
    {"id":"87","date":"27-3","sfks":"4:48 AM","kfta":"6:07 AM","szza":"12:15 PM","kzsa":"4:42 PM","gawi":"6:24 PM","kmsi":"7:44 PM"},
    {"id":"88","date":"28-3","sfks":"4:47 AM","kfta":"6:06 AM","szza":"12:15 PM","kzsa":"4:43 PM","gawi":"6:25 PM","kmsi":"7:45 PM"},
    {"id":"89","date":"29-3","sfks":"4:46 AM","kfta":"6:05 AM","szza":"12:15 PM","kzsa":"4:43 PM","gawi":"6:25 PM","kmsi":"7:45 PM"},
    {"id":"90","date":"30-3","sfks":"4:45 AM","kfta":"6:04 AM","szza":"12:15 PM","kzsa":"4:43 PM","gawi":"6:26 PM","kmsi":"7:46 PM"},
    {"id":"91","date":"31-3","sfks":"4:44 AM","kfta":"6:02 AM","szza":"12:14 PM","kzsa":"4:43 PM","gawi":"6:26 PM","kmsi":"7:46 PM"},

    {"id":"92","date":"1-4","sfks":"4:42 AM","kfta":"6:01 AM","szza":"12:14 PM","kzsa":"4:44 PM","gawi":"6:27 PM","kmsi":"7:47 PM"},
    {"id":"93","date":"2-4","sfks":"4:40 AM","kfta":"6:00 AM","szza":"12:13 PM","kzsa":"4:44 PM","gawi":"6:28 PM","kmsi":"7:48 PM"},
    {"id":"94","date":"3-4","sfks":"4:39 AM","kfta":"5:59 AM","szza":"12:13 PM","kzsa":"4:45 PM","gawi":"6:29 PM","kmsi":"7:49 PM"},
    {"id":"95","date":"4-4","sfks":"4:38 AM","kfta":"5:58 AM","szza":"12:13 PM","kzsa":"4:45 PM","gawi":"6:30 PM","kmsi":"7:49 PM"},
    {"id":"96","date":"5-4","sfks":"4:36 AM","kfta":"5:57 AM","szza":"12:13 PM","kzsa":"4:45 PM","gawi":"6:31 PM","kmsi":"7:50 PM"},
    {"id":"97","date":"6-4","sfks":"4:35 AM","kfta":"5:56 AM","szza":"12:13 PM","kzsa":"4:45 PM","gawi":"6:31 PM","kmsi":"7:51 PM"},
    {"id":"98","date":"7-4","sfks":"4:34 AM","kfta":"5:55 AM","szza":"12:13 PM","kzsa":"4:45 PM","gawi":"6:32 PM","kmsi":"7:51 PM"},
    {"id":"99","date":"8-4","sfks":"4:33 AM","kfta":"5:54 AM","szza":"12:12 PM","kzsa":"4:46 PM","gawi":"6:32 PM","kmsi":"7:52 PM"},
    {"id":"100","date":"9-4","sfks":"4:31 AM","kfta":"5:53 AM","szza":"12:12 PM","kzsa":"4:46 PM","gawi":"6:33 PM","kmsi":"7:53 PM"},
    {"id":"101","date":"10-4","sfks":"4:29 AM","kfta":"5:52 AM","szza":"12:12 PM","kzsa":"4:46 PM","gawi":"6:33 PM","kmsi":"7:54 PM"},
    {"id":"102","date":"11-4","sfks":"4:28 AM","kfta":"5:51 AM","szza":"12:11 PM","kzsa":"4:46 PM","gawi":"6:34 PM","kmsi":"7:54 PM"},
    {"id":"103","date":"12-4","sfks":"4:26 AM","kfta":"5:50 AM","szza":"12:11 PM","kzsa":"4:47 PM","gawi":"6:35 PM","kmsi":"7:55 PM"},
    {"id":"104","date":"13-4","sfks":"4:25 AM","kfta":"5:49 AM","szza":"12:11 PM","kzsa":"4:47 PM","gawi":"6:36 PM","kmsi":"7:56 PM"},
    {"id":"105","date":"14-4","sfks":"4:24 AM","kfta":"5:47 AM","szza":"12:11 PM","kzsa":"4:47 PM","gawi":"6:36 PM","kmsi":"7:57 PM"},
    {"id":"106","date":"15-4","sfks":"4:23 AM","kfta":"5:46 AM","szza":"12:10 PM","kzsa":"4:47 PM","gawi":"6:37 PM","kmsi":"7:57 PM"},
   ],
Jabalpur : [  
    {"id":"30","date":"30-1","sfks":"5:26 AM","kfta":"6:49 AM","szza":"12:26 PM","kzsa":"4:28 PM","gawi":"6:04 PM","kmsi":"7:26 PM"},
    {"id":"31","date":"31-1","sfks":"5:26 AM","kfta":"6:49 AM","szza":"12:26 PM","kzsa":"4:29 PM","gawi":"6:04 PM","kmsi":"7:27 PM"},
    {"id":"32","date":"1-2","sfks":"5:26 AM","kfta":"6:49 AM","szza":"12:27 PM","kzsa":"4:30 PM","gawi":"6:05 PM","kmsi":"7:28 PM"},
    {"id":"33","date":"2-2","sfks":"5:25 AM","kfta":"6:48 AM","szza":"12:27 PM","kzsa":"4:30 PM","gawi":"6:05 PM","kmsi":"7:28 PM"},
    {"id":"34","date":"3-2","sfks":"5:25 AM","kfta":"6:48 AM","szza":"12:27 PM","kzsa":"4:31 PM","gawi":"6:06 PM","kmsi":"7:29 PM"},
    {"id":"35","date":"4-2","sfks":"5:24 AM","kfta":"6:47 AM","szza":"12:27 PM","kzsa":"4:31 PM","gawi":"6:06 PM","kmsi":"7:29 PM"},
    {"id":"36","date":"5-2","sfks":"5:24 AM","kfta":"6:47 AM","szza":"12:27 PM","kzsa":"4:32 PM","gawi":"6:07 PM","kmsi":"7:30 PM"},
    {"id":"37","date":"6-2","sfks":"5:23 AM","kfta":"6:46 AM","szza":"12:27 PM","kzsa":"4:32 PM","gawi":"6:07 PM","kmsi":"7:30 PM"},
    {"id":"38","date":"7-2","sfks":"5:22 AM","kfta":"6:45 AM","szza":"12:27 PM","kzsa":"4:33 PM","gawi":"6:08 PM","kmsi":"7:31 PM"},
    {"id":"39","date":"8-2","sfks":"5:21 AM","kfta":"6:45 AM","szza":"12:27 PM","kzsa":"4:34 PM","gawi":"6:08 PM","kmsi":"7:32 PM"},
    {"id":"40","date":"9-2","sfks":"5:20 AM","kfta":"6:44 AM","szza":"12:27 PM","kzsa":"4:34 PM","gawi":"6:09 PM","kmsi":"7:33 PM"},
    {"id":"41","date":"10-2","sfks":"5:20 AM","kfta":"6:44 AM","szza":"12:27 PM","kzsa":"4:35 PM","gawi":"6:10 PM","kmsi":"7:34 PM"},
    {"id":"42","date":"11-2","sfks":"5:19 AM","kfta":"6:43 AM","szza":"12:27 PM","kzsa":"4:36 PM","gawi":"6:11 PM","kmsi":"7:35 PM"},
    {"id":"43","date":"12-2","sfks":"5:18 AM","kfta":"6:43 AM","szza":"12:26 PM","kzsa":"4:36 PM","gawi":"6:11 PM","kmsi":"7:35 PM"},
    {"id":"44","date":"13-2","sfks":"5:18 AM","kfta":"6:42 AM","szza":"12:26 PM","kzsa":"4:37 PM","gawi":"6:12 PM","kmsi":"7:36 PM"},
    {"id":"45","date":"14-2","sfks":"5:17 AM","kfta":"6:42 AM","szza":"12:26 PM","kzsa":"4:37 PM","gawi":"6:12 PM","kmsi":"7:36 PM"},
    {"id":"46","date":"15-2","sfks":"5:16 AM","kfta":"6:41 AM","szza":"12:26 PM","kzsa":"4:38 PM","gawi":"6:13 PM","kmsi":"7:37 PM"},
    {"id":"47","date":"16-2","sfks":"5:16 AM","kfta":"6:40 AM","szza":"12:26 PM","kzsa":"4:38 PM","gawi":"6:13 PM","kmsi":"7:37 PM"},
    {"id":"48","date":"17-2","sfks":"5:15 AM","kfta":"6:39 AM","szza":"12:26 PM","kzsa":"4:39 PM","gawi":"6:14 PM","kmsi":"7:38 PM"},
    {"id":"49","date":"18-2","sfks":"5:14 AM","kfta":"6:38 AM","szza":"12:26 PM","kzsa":"4:39 PM","gawi":"6:14 PM","kmsi":"7:38 PM"},
    {"id":"50","date":"19-2","sfks":"5:13 AM","kfta":"6:37 AM","szza":"12:26 PM","kzsa":"4:39 PM","gawi":"6:15 PM","kmsi":"7:39 PM"},
    {"id":"51","date":"20-2","sfks":"5:12 AM","kfta":"6:36 AM","szza":"12:25 PM","kzsa":"4:39 PM","gawi":"6:15 PM","kmsi":"7:39 PM"},
    {"id":"52","date":"21-2","sfks":"5:11 AM","kfta":"6:36 AM","szza":"12:25 PM","kzsa":"4:39 PM","gawi":"6:15 PM","kmsi":"7:40 PM"},
    {"id":"53","date":"22-2","sfks":"5:10 AM","kfta":"6:35 AM","szza":"12:25 PM","kzsa":"4:40 PM","gawi":"6:16 PM","kmsi":"7:41 PM"},
    {"id":"54","date":"23-2","sfks":"5:09 AM","kfta":"6:34 AM","szza":"12:25 PM","kzsa":"4:41 PM","gawi":"6:17 PM","kmsi":"7:42 PM"},
    {"id":"55","date":"24-2","sfks":"5:08 AM","kfta":"6:33 AM","szza":"12:25 PM","kzsa":"4:41 PM","gawi":"6:17 PM","kmsi":"7:42 PM"},
    {"id":"56","date":"25-2","sfks":"5:07 AM","kfta":"6:32 AM","szza":"12:25 PM","kzsa":"4:42 PM","gawi":"6:18 PM","kmsi":"7:43 PM"},
    {"id":"57","date":"26-2","sfks":"5:06 AM","kfta":"6:31 AM","szza":"12:25 PM","kzsa":"4:42 PM","gawi":"6:18 PM","kmsi":"7:43 PM"},
    {"id":"58","date":"27-2","sfks":"5:06 AM","kfta":"6:31 AM","szza":"12:25 PM","kzsa":"4:43 PM","gawi":"6:19 PM","kmsi":"7:44 PM"},
    {"id":"59","date":"28-2","sfks":"5:05 AM","kfta":"6:30 AM","szza":"12:25 PM","kzsa":"4:43 PM","gawi":"6:19 PM","kmsi":"7:44 PM"},
    {"id":"60","date":"29-2","sfks":"5:05 AM","kfta":"6:30 AM","szza":"12:25 PM","kzsa":"4:43 PM","gawi":"6:19 PM","kmsi":"7:44 PM"},

    {"id":"61","date":"1-3","sfks":"5:04 AM","kfta":"6:29 AM","szza":"12:25 PM","kzsa":"4:43 PM","gawi":"6:20 PM","kmsi":"7:45 PM"},
    {"id":"62","date":"2-3","sfks":"5:03 AM","kfta":"6:29 AM","szza":"12:25 PM","kzsa":"4:43 PM","gawi":"6:20 PM","kmsi":"7:46 PM"},
    {"id":"63","date":"3-3","sfks":"5:02 AM","kfta":"6:28 AM","szza":"12:24 PM","kzsa":"4:43 PM","gawi":"6:20 PM","kmsi":"7:46 PM"},
    {"id":"64","date":"4-3","sfks":"5:01 AM","kfta":"6:27 AM","szza":"12:24 PM","kzsa":"4:44 PM","gawi":"6:21 PM","kmsi":"7:47 PM"},
    {"id":"65","date":"5-3","sfks":"5:00 AM","kfta":"6:26 AM","szza":"12:24 PM","kzsa":"4:44 PM","gawi":"6:21 PM","kmsi":"7:47 PM"},
    {"id":"66","date":"6-3","sfks":"4:59 AM","kfta":"6:25 AM","szza":"12:23 PM","kzsa":"4:44 PM","gawi":"6:21 PM","kmsi":"7:47 PM"},
    {"id":"67","date":"7-3","sfks":"4:58 AM","kfta":"6:25 AM","szza":"12:23 PM","kzsa":"4:44 PM","gawi":"6:21 PM","kmsi":"7:48 PM"},
    {"id":"68","date":"8-3","sfks":"4:57 AM","kfta":"6:24 AM","szza":"12:23 PM","kzsa":"4:44 PM","gawi":"6:22 PM","kmsi":"7:49 PM"},
    {"id":"69","date":"9-3","sfks":"4:56 AM","kfta":"6:23 AM","szza":"12:23 PM","kzsa":"4:44 PM","gawi":"6:22 PM","kmsi":"7:49 PM"},
    {"id":"70","date":"10-3","sfks":"4:55 AM","kfta":"6:22 AM","szza":"12:23 PM","kzsa":"4:45 PM","gawi":"6:23 PM","kmsi":"7:50 PM"},
    {"id":"71","date":"11-3","sfks":"4:54 AM","kfta":"6:21 AM","szza":"12:22 PM","kzsa":"4:45 PM","gawi":"6:23 PM","kmsi":"7:50 PM"},
    {"id":"72","date":"12-3","sfks":"4:53 AM","kfta":"6:20 AM","szza":"12:22 PM","kzsa":"4:45 PM","gawi":"6:23 PM","kmsi":"7:50 PM"},
    {"id":"73","date":"13-3","sfks":"4:52 AM","kfta":"6:19 AM","szza":"12:22 PM","kzsa":"4:46 PM","gawi":"6:24 PM","kmsi":"7:51 PM"},
    {"id":"74","date":"14-3","sfks":"4:51 AM","kfta":"6:18 AM","szza":"12:22 PM","kzsa":"4:46 PM","gawi":"6:24 PM","kmsi":"7:51 PM"},
    {"id":"75","date":"15-3","sfks":"4:50 AM","kfta":"6:17 AM","szza":"12:21 PM","kzsa":"4:46 PM","gawi":"6:24 PM","kmsi":"7:51 PM"},
    {"id":"76","date":"16-3","sfks":"4:49 AM","kfta":"6:16 AM","szza":"12:21 PM","kzsa":"4:46 PM","gawi":"6:25 PM","kmsi":"7:52 PM"},
    {"id":"77","date":"17-3","sfks":"4:48 AM","kfta":"6:15 AM","szza":"12:20 PM","kzsa":"4:46 PM","gawi":"6:25 PM","kmsi":"7:52 PM"},
    {"id":"78","date":"18-3","sfks":"4:47 AM","kfta":"6:14 AM","szza":"12:20 PM","kzsa":"4:47 PM","gawi":"6:26 PM","kmsi":"7:53 PM"},
    {"id":"79","date":"19-3","sfks":"4:46 AM","kfta":"6:13 AM","szza":"12:20 PM","kzsa":"4:47 PM","gawi":"6:26 PM","kmsi":"7:53 PM"},
    {"id":"80","date":"20-3","sfks":"4:45 AM","kfta":"6:12 AM","szza":"12:19 PM","kzsa":"4:47 PM","gawi":"6:26 PM","kmsi":"7:53 PM"},
    {"id":"81","date":"21-3","sfks":"4:44 AM","kfta":"6:11 AM","szza":"12:19 PM","kzsa":"4:47 PM","gawi":"6:26 PM","kmsi":"7:54 PM"},
    {"id":"82","date":"22-3","sfks":"4:43 AM","kfta":"6:10 AM","szza":"12:19 PM","kzsa":"4:47 PM","gawi":"6:27 PM","kmsi":"7:54 PM"},
    {"id":"83","date":"23-3","sfks":"4:42 AM","kfta":"6:09 AM","szza":"12:18 PM","kzsa":"4:47 PM","gawi":"6:27 PM","kmsi":"7:54 PM"},
    {"id":"84","date":"24-3","sfks":"4:41 AM","kfta":"6:08 AM","szza":"12:18 PM","kzsa":"4:47 PM","gawi":"6:27 PM","kmsi":"7:55 PM"},
    {"id":"85","date":"25-3","sfks":"4:40 AM","kfta":"6:07 AM","szza":"12:18 PM","kzsa":"4:47 PM","gawi":"6:28 PM","kmsi":"7:55 PM"},
    {"id":"86","date":"26-3","sfks":"4:39 AM","kfta":"6:06 AM","szza":"12:17 PM","kzsa":"4:47 PM","gawi":"6:28 PM","kmsi":"7:55 PM"},
    {"id":"87","date":"27-3","sfks":"4:38 AM","kfta":"6:05 AM","szza":"12:17 PM","kzsa":"4:47 PM","gawi":"6:28 PM","kmsi":"7:56 PM"},
    {"id":"88","date":"28-3","sfks":"4:37 AM","kfta":"6:04 AM","szza":"12:17 PM","kzsa":"4:47 PM","gawi":"6:29 PM","kmsi":"7:56 PM"},
    {"id":"89","date":"29-3","sfks":"4:36 AM","kfta":"6:03 AM","szza":"12:16 PM","kzsa":"4:47 PM","gawi":"6:29 PM","kmsi":"7:56 PM"},
    {"id":"90","date":"30-3","sfks":"4:35 AM","kfta":"6:02 AM","szza":"12:16 PM","kzsa":"4:47 PM","gawi":"6:29 PM","kmsi":"7:56 PM"},
    {"id":"91","date":"31-3","sfks":"4:35 AM","kfta":"6:02 AM","szza":"12:16 PM","kzsa":"4:47 PM","gawi":"6:29 PM","kmsi":"7:56 PM"},

    {"id":"92","date":"1-4","sfks":"4:34 AM","kfta":"6:02 AM","szza":"12:16 PM","kzsa":"4:47 PM","gawi":"6:30 PM","kmsi":"7:57 PM"},
    {"id":"93","date":"2-4","sfks":"4:33 AM","kfta":"6:01 AM","szza":"12:16 PM","kzsa":"4:47 PM","gawi":"6:30 PM","kmsi":"7:58 PM"},
    {"id":"94","date":"3-4","sfks":"4:32 AM","kfta":"6:01 AM","szza":"12:15 PM", "kzsa":"4:47 PM","gawi":"6:30 PM","kmsi":"7:59 PM"},
    {"id":"95","date":"4-4","sfks":"4:31 AM","kfta":"6:00 AM","szza":"12:15 PM", "kzsa":"4:47 PM","gawi":"6:31 PM","kmsi":"8:00 PM"},
    {"id":"96","date":"5-4","sfks":"4:30 AM","kfta":"5:59 AM","szza":"12:15 PM", "kzsa":"4:47 PM","gawi":"6:31 PM","kmsi":"8:00 PM"},
    {"id":"97","date":"6-4","sfks":"4:29 AM","kfta":"5:58 AM","szza":"12:15 PM", "kzsa":"4:48 PM","gawi":"6:32 PM","kmsi":"8:01 PM"},
    {"id":"98","date":"7-4","sfks":"4:28 AM","kfta":"5:57 AM","szza":"12:14 PM", "kzsa":"4:48 PM","gawi":"6:32 PM","kmsi":"8:01 PM"},
    {"id":"99","date":"8-4","sfks":"4:27 AM","kfta":"5:56 AM","szza":"12:14 PM", "kzsa":"4:48 PM","gawi":"6:32 PM","kmsi":"8:01 PM"},
    {"id":"100","date":"9-4","sfks":"4:26 AM","kfta":"5:55 AM","szza":"12:14 PM", "kzsa":"4:48 PM","gawi":"6:33 PM","kmsi":"8:02 PM"},
    {"id":"101","date":"10-4","sfks":"4:25 AM","kfta":"5:54 AM","szza":"12:13 PM", "kzsa":"4:48 PM","gawi":"6:33 PM","kmsi":"8:02 PM"},
    {"id":"102","date":"11-4","sfks":"4:24 AM","kfta":"5:53 AM","szza":"12:13 PM", "kzsa":"4:48 PM","gawi":"6:34 PM","kmsi":"8:03 PM"},
    {"id":"103","date":"12-4","sfks":"4:23 AM","kfta":"5:52 AM","szza":"12:13 PM", "kzsa":"4:48 PM","gawi":"6:34 PM","kmsi":"8:03 PM"},
    {"id":"104","date":"13-4","sfks":"4:22 AM","kfta":"5:51 AM","szza":"12:12 PM", "kzsa":"4:48 PM","gawi":"6:34 PM","kmsi":"8:03 PM"},
    {"id":"105","date":"14-4","sfks":"4:21 AM","kfta":"5:50 AM","szza":"12:12 PM", "kzsa":"4:48 PM","gawi":"6:35 PM","kmsi":"8:04 PM"},
    {"id":"106","date":"15-4","sfks":"4:20 AM","kfta":"5:49 AM","szza":"12:12 PM", "kzsa":"4:48 PM","gawi":"6:35 PM","kmsi":"8:04 PM"},

   ],     
Bareilly : [
    {"id":"59","date":"28-2","sfks":"5:20 AM","kfta":"6:38 AM","szza":"12:26 PM","kzsa":"4:34 PM","gawi":"6:10 PM","kmsi":"7:29 PM"},
    {"id":"60","date":"29-2","sfks":"5:19 AM","kfta":"6:37 AM","szza":"12:26 PM","kzsa":"4:34 PM","gawi":"6:10 PM","kmsi":"7:29 PM"},

    {"id":"61","date":"1-3","sfks":"5:19 AM","kfta":"6:37 AM","szza":"12:25 PM","kzsa":"4:35 PM","gawi":"6:11 PM","kmsi":"7:30 PM"},
    {"id":"62","date":"2-3","sfks":"5:18 AM","kfta":"6:36 AM","szza":"12:25 PM","kzsa":"4:36 PM","gawi":"6:12 PM","kmsi":"7:30 PM"},
    {"id":"63","date":"3-3","sfks":"5:17 AM","kfta":"6:35 AM","szza":"12:24 PM","kzsa":"4:37 PM","gawi":"6:13 PM","kmsi":"7:31 PM"},
    {"id":"64","date":"4-3","sfks":"5:16 AM","kfta":"6:34 AM","szza":"12:24 PM","kzsa":"4:37 PM","gawi":"6:13 PM","kmsi":"7:31 PM"},
    {"id":"65","date":"5-3","sfks":"5:15 AM","kfta":"6:33 AM","szza":"12:24 PM","kzsa":"4:37 PM","gawi":"6:14 PM","kmsi":"7:32 PM"},
    {"id":"66","date":"6-3","sfks":"5:15 AM","kfta":"6:32 AM","szza":"12:24 PM","kzsa":"4:37 PM","gawi":"6:15 PM","kmsi":"7:32 PM"},
    {"id":"67","date":"7-3","sfks":"5:14 AM","kfta":"6:31 AM","szza":"12:23 PM","kzsa":"4:37 PM","gawi":"6:16 PM","kmsi":"7:33 PM"},
    {"id":"68","date":"8-3","sfks":"5:13 AM","kfta":"6:30 AM","szza":"12:23 PM","kzsa":"4:38 PM","gawi":"6:16 PM","kmsi":"7:33 PM"},
    {"id":"69","date":"9-3","sfks":"5:12 AM","kfta":"6:29 AM","szza":"12:23 PM","kzsa":"4:38 PM","gawi":"6:17 PM","kmsi":"7:33 PM"},
    {"id":"70","date":"10-3","sfks":"5:11 AM","kfta":"6:28 AM","szza":"12:22 PM","kzsa":"4:38 PM","gawi":"6:17 PM","kmsi":"7:34 PM"},
    {"id":"71","date":"11-3","sfks":"5:10 AM","kfta":"6:27 AM","szza":"12:22 PM","kzsa":"4:39 PM","gawi":"6:18 PM","kmsi":"7:35 PM"},
    {"id":"72","date":"12-3","sfks":"5:09 AM","kfta":"6:26 AM","szza":"12:22 PM","kzsa":"4:39 PM","gawi":"6:18 PM","kmsi":"7:35 PM"},
    {"id":"73","date":"13-3","sfks":"5:08 AM","kfta":"6:25 AM","szza":"12:21 PM","kzsa":"4:39 PM","gawi":"6:19 PM","kmsi":"7:36 PM"},
    {"id":"74","date":"14-3","sfks":"5:07 AM","kfta":"6:24 AM","szza":"12:21 PM","kzsa":"4:40 PM","gawi":"6:19 PM","kmsi":"7:37 PM"},
    {"id":"75","date":"15-3","sfks":"5:06 AM","kfta":"6:23 AM","szza":"12:21 PM","kzsa":"4:40 PM","gawi":"6:20 PM","kmsi":"7:37 PM"},
    {"id":"76","date":"16-3","sfks":"5:05 AM","kfta":"6:22 AM","szza":"12:21 PM","kzsa":"4:41 PM","gawi":"6:21 PM","kmsi":"7:38 PM"},
    {"id":"77","date":"17-3","sfks":"5:04 AM","kfta":"6:20 AM","szza":"12:20 PM","kzsa":"4:41 PM","gawi":"6:21 PM","kmsi":"7:39 PM"},
    {"id":"78","date":"18-3","sfks":"5:03 AM","kfta":"6:19 AM","szza":"12:20 PM","kzsa":"4:42 PM","gawi":"6:22 PM","kmsi":"7:39 PM"},
    {"id":"79","date":"19-3","sfks":"5:02 AM","kfta":"6:18 AM","szza":"12:20 PM","kzsa":"4:42 PM","gawi":"6:22 PM","kmsi":"7:40 PM"},
    {"id":"80","date":"20-3","sfks":"5:01 AM","kfta":"6:17 AM","szza":"12:20 PM","kzsa":"4:43 PM","gawi":"6:23 PM","kmsi":"7:41 PM"},
    {"id":"81","date":"21-3","sfks":"4:59 AM","kfta":"6:16 AM","szza":"12:19 PM","kzsa":"4:43 PM","gawi":"6:23 PM","kmsi":"7:41 PM"},
    {"id":"82","date":"22-3","sfks":"4:57 AM","kfta":"6:15 AM","szza":"12:19 PM","kzsa":"4:43 PM","gawi":"6:24 PM","kmsi":"7:42 PM"},
    {"id":"83","date":"23-3","sfks":"4:56 AM","kfta":"6:14 AM","szza":"12:19 PM","kzsa":"4:43 PM","gawi":"6:24 PM","kmsi":"7:42 PM"},
    {"id":"84","date":"24-3","sfks":"4:54 AM","kfta":"6:13 AM","szza":"12:18 PM","kzsa":"4:44 PM","gawi":"6:25 PM","kmsi":"7:43 PM"},
    {"id":"85","date":"25-3","sfks":"4:53 AM","kfta":"6:11 AM","szza":"12:18 PM","kzsa":"4:44 PM","gawi":"6:25 PM","kmsi":"7:43 PM"},
    {"id":"86","date":"26-3","sfks":"4:51 AM","kfta":"6:10 AM","szza":"12:18 PM","kzsa":"4:44 PM","gawi":"6:26 PM","kmsi":"7:44 PM"},
    {"id":"87","date":"27-3","sfks":"4:50 AM","kfta":"6:09 AM","szza":"12:17 PM","kzsa":"4:44 PM","gawi":"6:26 PM","kmsi":"7:45 PM"},
    {"id":"88","date":"28-3","sfks":"4:49 AM","kfta":"6:08 AM","szza":"12:17 PM","kzsa":"4:45 PM","gawi":"6:27 PM","kmsi":"7:46 PM"},
    {"id":"89","date":"29-3","sfks":"4:48 AM","kfta":"6:07 AM","szza":"12:17 PM","kzsa":"4:45 PM","gawi":"6:28 PM","kmsi":"7:47 PM"},
    {"id":"90","date":"30-3","sfks":"4:47 AM","kfta":"6:06 AM","szza":"12:17 PM","kzsa":"4:45 PM","gawi":"6:28 PM","kmsi":"7:47 PM"},
    {"id":"91","date":"31-3","sfks":"4:47 AM","kfta":"6:04 AM","szza":"12:16 PM","kzsa":"4:45 PM","gawi":"6:28 PM","kmsi":"7:48 PM"},

    {"id":"92","date":"1-4","sfks":"4:45 AM","kfta":"6:03 AM","szza":"12:16 PM","kzsa":"4:46 PM","gawi":"6:29 PM","kmsi":"7:49 PM"},
    {"id":"93","date":"2-4","sfks":"4:43 AM","kfta":"6:02 AM","szza":"12:15 PM","kzsa":"4:47 PM","gawi":"6:30 PM","kmsi":"7:50 PM"},
    {"id":"94","date":"3-4","sfks":"4:41 AM","kfta":"6:01 AM","szza":"12:15 PM","kzsa":"4:47 PM","gawi":"6:31 PM","kmsi":"7:51 PM"},
    {"id":"95","date":"4-4","sfks":"4:40 AM","kfta":"5:59 AM","szza":"12:15 PM","kzsa":"4:47 PM","gawi":"6:32 PM","kmsi":"7:52 PM"},
    {"id":"96","date":"5-4","sfks":"4:38 AM","kfta":"5:59 AM","szza":"12:15 PM","kzsa":"4:47 PM","gawi":"6:33 PM","kmsi":"7:52 PM"},
    {"id":"97","date":"6-4","sfks":"4:37 AM","kfta":"5:58 AM","szza":"12:15 PM","kzsa":"4:47 PM","gawi":"6:33 PM","kmsi":"7:52 PM"},
    {"id":"98","date":"7-4","sfks":"4:36 AM","kfta":"5:58 AM","szza":"12:15 PM","kzsa":"4:48 PM","gawi":"6:34 PM","kmsi":"7:53 PM"},
    {"id":"99","date":"8-4","sfks":"4:35 AM","kfta":"5:56 AM","szza":"12:15 PM","kzsa":"4:48 PM","gawi":"6:34 PM","kmsi":"7:54 PM"},
    {"id":"100","date":"9-4","sfks":"4:33 AM","kfta":"5:55 AM","szza":"12:14 PM","kzsa":"4:48 PM","gawi":"6:35 PM","kmsi":"7:55 PM"},
    {"id":"101","date":"10-4","sfks":"4:31 AM","kfta":"5:54 AM","szza":"12:14 PM","kzsa":"4:48 PM","gawi":"6:35 PM","kmsi":"7:56 PM"},
    {"id":"102","date":"11-4","sfks":"4:30 AM","kfta":"5:53 AM","szza":"12:14 PM","kzsa":"4:48 PM","gawi":"6:36 PM","kmsi":"7:56 PM"},
    {"id":"103","date":"12-4","sfks":"4:28 AM","kfta":"5:52 AM","szza":"12:13 PM","kzsa":"4:48 PM","gawi":"6:37 PM","kmsi":"7:57 PM"},
    {"id":"104","date":"13-4","sfks":"4:27 AM","kfta":"5:51 AM","szza":"12:13 PM","kzsa":"4:49 PM","gawi":"6:38 PM","kmsi":"7:58 PM"},
    {"id":"105","date":"14-4","sfks":"4:26 AM","kfta":"5:50 AM","szza":"12:13 PM","kzsa":"4:49 PM","gawi":"6:38 PM","kmsi":"7:59 PM"},
    {"id":"106","date":"15-4","sfks":"4:25 AM","kfta":"5:49 AM","szza":"12:13 PM","kzsa":"4:49 PM","gawi":"6:39 PM","kmsi":"7:59 PM"},


],
Indore : [
    {"id":"92","date":"1-4","sfks":"5:02 AM","kfta":"6:17 AM","szza":"12:32 PM","kzsa":"4:59 PM","gawi":"6:45 PM","kmsi":"7:59 PM"},
    {"id":"93","date":"2-4","sfks":"5:01 AM","kfta":"6:16 AM","szza":"12:31 PM","kzsa":"4:59 PM","gawi":"6:45 PM","kmsi":"7:59 PM"},
    {"id":"94","date":"3-4","sfks":"5:00 AM","kfta":"6:15 AM","szza":"12:31 PM","kzsa":"4:59 PM","gawi":"6:46 PM","kmsi":"8:00 PM"},
    {"id":"95","date":"4-4","sfks":"4:59 AM","kfta":"6:14 AM","szza":"12:31 PM","kzsa":"5:00 PM","gawi":"6:46 PM","kmsi":"8:00 PM"},
    {"id":"96","date":"5-4","sfks":"4:58 AM","kfta":"6:13 AM","szza":"12:30 PM","kzsa":"5:00 PM","gawi":"6:46 PM","kmsi":"8:00 PM"},
    {"id":"97","date":"6-4","sfks":"4:57 AM","kfta":"6:12 AM","szza":"12:30 PM","kzsa":"5:00 PM","gawi":"6:47 PM","kmsi":"8:01 PM"},
    {"id":"98","date":"7-4","sfks":"4:56 AM","kfta":"6:11 AM","szza":"12:30 PM","kzsa":"5:00 PM","gawi":"6:47 PM","kmsi":"8:02 PM"},
    {"id":"99","date":"8-4","sfks":"4:55 AM","kfta":"6:10 AM","szza":"12:29 PM","kzsa":"5:00 PM","gawi":"6:48 PM","kmsi":"8:02 PM"},
    {"id":"100","date":"9-4","sfks":"4:54 AM","kfta":"6:09 AM","szza":"12:29 PM","kzsa":"5:00 PM","gawi":"6:48 PM","kmsi":"8:03 PM"},
    {"id":"101","date":"10-4","sfks":"4:53 AM","kfta":"6:08 AM","szza":"12:29 PM","kzsa":"5:00 PM","gawi":"6:48 PM","kmsi":"8:03 PM"},
    {"id":"102","date":"11-4","sfks":"4:52 AM","kfta":"6:07 AM","szza":"12:29 PM","kzsa":"5:00 PM","gawi":"6:49 PM","kmsi":"8:04 PM"},
    {"id":"103","date":"12-4","sfks":"4:51 AM","kfta":"6:07 AM","szza":"12:28 PM","kzsa":"5:00 PM","gawi":"6:49 PM","kmsi":"8:04 PM"},
    {"id":"104","date":"13-4","sfks":"4:50 AM","kfta":"6:06 AM","szza":"12:28 PM","kzsa":"5:00 PM","gawi":"6:50 PM","kmsi":"8:05 PM"},
    {"id":"105","date":"14-4","sfks":"4:49 AM","kfta":"6:05 AM","szza":"12:28 PM","kzsa":"5:00 PM","gawi":"6:50 PM","kmsi":"8:05 PM"},
    {"id":"106","date":"15-4","sfks":"4:48 AM","kfta":"6:04 AM","szza":"12:28 PM","kzsa":"5:00 PM","gawi":"6:50 PM","kmsi":"8:06 PM"},
    {"id":"107","date":"16-4","sfks":"4:47 AM","kfta":"6:03 AM","szza":"12:27 PM","kzsa":"5:00 PM","gawi":"6:51 PM","kmsi":"8:06 PM"},
    {"id":"108","date":"17-4","sfks":"4:46 AM","kfta":"6:02 AM","szza":"12:27 PM","kzsa":"5:00 PM","gawi":"6:51 PM","kmsi":"8:07 PM"},
    {"id":"109","date":"18-4","sfks":"4:45 AM","kfta":"6:01 AM","szza":"12:27 PM","kzsa":"5:00 PM","gawi":"6:51 PM","kmsi":"8:07 PM"},
    {"id":"110","date":"19-4","sfks":"4:44 AM","kfta":"6:01 AM","szza":"12:27 PM","kzsa":"5:00 PM","gawi":"6:52 PM","kmsi":"8:08 PM"},
    {"id":"111","date":"20-4","sfks":"4:43 AM","kfta":"6:00 AM","szza":"12:27 PM","kzsa":"5:00 PM","gawi":"6:52 PM","kmsi":"8:08 PM"},
    {"id":"112","date":"21-4","sfks":"4:42 AM","kfta":"5:59 AM","szza":"12:26 PM","kzsa":"5:00 PM","gawi":"6:53 PM","kmsi":"8:09 PM"},
    {"id":"113","date":"22-4","sfks":"4:41 AM","kfta":"5:58 AM","szza":"12:26 PM","kzsa":"5:00 PM","gawi":"6:53 PM","kmsi":"8:10 PM"},
    {"id":"114","date":"23-4","sfks":"4:40 AM","kfta":"5:57 AM","szza":"12:26 PM","kzsa":"5:00 PM","gawi":"6:53 PM","kmsi":"8:10 PM"},
    {"id":"115","date":"24-4","sfks":"4:39 AM","kfta":"5:57 AM","szza":"12:26 PM","kzsa":"5:00 PM","gawi":"6:54 PM","kmsi":"8:11 PM"},
    {"id":"116","date":"25-4","sfks":"4:38 AM","kfta":"5:56 AM","szza":"12:26 PM","kzsa":"5:00 PM","gawi":"6:54 PM","kmsi":"8:11 PM"},
    {"id":"117","date":"26-4","sfks":"4:37 AM","kfta":"5:55 AM","szza":"12:25 PM","kzsa":"5:00 PM","gawi":"6:55 PM","kmsi":"8:12 PM"},
    {"id":"118","date":"27-4","sfks":"4:36 AM","kfta":"5:54 AM","szza":"12:25 PM","kzsa":"5:00 PM","gawi":"6:55 PM","kmsi":"8:12 PM"},
    {"id":"119","date":"28-4","sfks":"4:35 AM","kfta":"5:54 AM","szza":"12:25 PM","kzsa":"5:00 PM","gawi":"6:56 PM","kmsi":"8:13 PM"},
    {"id":"120","date":"29-4","sfks":"4:34 AM","kfta":"5:53 AM","szza":"12:25 PM","kzsa":"5:01 PM","gawi":"6:56 PM","kmsi":"8:14 PM"},
    {"id":"121","date":"30-4","sfks":"4:33 AM","kfta":"5:52 AM","szza":"12:25 PM","kzsa":"5:01 PM","gawi":"6:57 PM","kmsi":"8:14 PM"},
]
}

export default FullTimeTable;

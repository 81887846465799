import React from "react";
import './MainLayout.css'
import Dashboard from "../Dashboard/Dashboard";
import Sidebar from "../Sidebar/Sidebar";
import { Navigate, Route, Routes } from "react-router-dom";
import SehriIftar from "../TimeTable/SehriIftar";
import JanuaryTimeTable from "../TimeTable/JanuaryTimeTable";
import SelectMonthTT from "../TimeTable/SelectMonthTT";
import FebruaryTimeTable from "../TimeTable/FebruaryTimeTable";
import AprilTimeTable from "../TimeTable/AprilTimeTable";
import MayTimeTable from "../TimeTable/MayTimeTable";
import JuneTimeTable from "../TimeTable/JuneTimeTable";
import JulyTimeTable from "../TimeTable/JulyTimeTable";
import ShabeBarat from "../ShabeBarat/ShabeBarat";
import SelectMonthSI from "../TimeTable/SelectMonthSI";
import Ramzan from "../RamzanulMubarak/Ramzan";
import ShabeQadr from "../ShabeQadr/ShabeQadr";
import EsaleSawab from "../EsaleSawab/EsaleSawab";
import EidulAdha from "../EidulAdha/EidulAdha";
import AalaHazrat from "../AalaHazrat/AalaHazrat";
import Wuzu from "../Wuzu/Wuzu";
import QuranShareef from "../QuranShareef/QuranShareef";
import MarchTimeTable from "../TimeTable/MarchTimeTable";
import AugustTimeTable from "../TimeTable/AugustTimeTable";
import SepTimeTable from "../TimeTable/SepTimeTable";
import OctTimeTable from "../TimeTable/OctTimeTable";
import NovTimeTable from "../TimeTable/NovTimeTable";
import DecTimeTable from "../TimeTable/DecTimeTable";
import JanuarySehriIftaar from "../TimeTable/SehriIftaar/JanuarySehriIftaar";
import FebruarySehriIftaar from "../TimeTable/SehriIftaar/FebruarySehriIftaar";
import MarchSehriIftaar from "../TimeTable/SehriIftaar/MarchSehriIftaar";
import AprilSehriIftaar from "../TimeTable/SehriIftaar/AprilSehriIftaar";
import MaySehriIftaar from "../TimeTable/SehriIftaar/MaySehriIftaar";
import JuneSehriIftaar from "../TimeTable/SehriIftaar/JuneSehriIftaar";
import JulySehriIftaar from "../TimeTable/SehriIftaar/JulySehriIftaar";
import AugustSehriIftaar from "../TimeTable/SehriIftaar/AugustSehriIftaar";
import SeptemberSehriIftaar from "../TimeTable/SehriIftaar/SeptemberSehriIFtaar";
import OctoberSehriIftaar from "../TimeTable/SehriIftaar/OctoberSehriIftaar";
import NovemberSehriIftaar from "../TimeTable/SehriIftaar/NovemberSehriIftaar";
import DecemberSehriIftaar from "../TimeTable/SehriIftaar/DecemberSehriIftaar";
import RamzanCalendar from "../RamzanCalendar/RamzanCalendar";
import Esalesawabview from "../EsaleSawab/Esalesawabview";
import RegistrationView from "../EsaleSawab/RegistrationView";
import MiladeAkbar from "../MiladeAkbar/MiladeAkbar";
import BetiKiAzmat from "../BetiKiAzmat/BetiKiAzmat";
import ScrollToTop from "./ScrollToTop";

const MainLayout = ({isClass, setClassName, districtDetails }) => {
    return (
        <>
          <main className={`MainLayout ${isClass ? 'nav-slided' :''}`} id="main">
            <Sidebar isclassName={isClass} setClassName={setClassName} />
            <ScrollToTop />
            <Routes>
              <Route path='/' element={<Dashboard districtDetails={districtDetails} />} />
              <Route path='/selectmonthtt' element={<SelectMonthTT />} />
              <Route path='/selectmonthsi' element={<SelectMonthSI />} />
              <Route path='/sehriIftar' element={<SehriIftar />} />
              <Route path='/januarytimetable' element={<JanuaryTimeTable districtDetails={districtDetails} />} />
              <Route path='/februarytimetable' element={<FebruaryTimeTable districtDetails={districtDetails} />} />
              <Route path='/marchtimetable' element={<MarchTimeTable districtDetails={districtDetails} />} /> 
              <Route path='/apriltimetable' element={<AprilTimeTable districtDetails={districtDetails} />} />
              <Route path='/maytimetable' element={<MayTimeTable districtDetails={districtDetails} />} />
              <Route path='/junetimetable' element={<JuneTimeTable districtDetails={districtDetails} />} />
              <Route path='/julytimetable' element={<JulyTimeTable districtDetails={districtDetails} />} />
              <Route path='/augusttimetable' element={<AugustTimeTable districtDetails={districtDetails} />} />
              <Route path='/septembertimetable' element={<SepTimeTable districtDetails={districtDetails} />} />
              <Route path='/octobertimetable' element={<OctTimeTable districtDetails={districtDetails} />} />
              <Route path='/novembertimetable' element={<NovTimeTable districtDetails={districtDetails} />} />
              <Route path='/decembertimetable' element={<DecTimeTable districtDetails={districtDetails}/>} />
              <Route path='/januarysehriiftaar' element={<JanuarySehriIftaar districtDetails={districtDetails} />} />
              <Route path='/februarysehriiftaar' element={<FebruarySehriIftaar districtDetails={districtDetails} />} />
              <Route path='/marchsehriiftaar' element={<MarchSehriIftaar districtDetails={districtDetails} />} />
              <Route path='/aprilsehriiftaar' element={<AprilSehriIftaar districtDetails={districtDetails} />} />
              <Route path='/maysehriiftaar' element={<MaySehriIftaar districtDetails={districtDetails} />} />
              <Route path='/junesehriiftaar' element={<JuneSehriIftaar districtDetails={districtDetails} />} />
              <Route path='/julysehriiftaar' element={<JulySehriIftaar districtDetails={districtDetails} />} />
              <Route path='/augustsehriiftaar' element={<AugustSehriIftaar districtDetails={districtDetails} />} />
              <Route path='/septembersehriiftaar' element={<SeptemberSehriIftaar districtDetails={districtDetails} />} />
              <Route path='/octobersehriiftaar' element={<OctoberSehriIftaar districtDetails={districtDetails} />} />
              <Route path='/novembersehriiftaar' element={<NovemberSehriIftaar districtDetails={districtDetails} />} />
              <Route path='/decembersehriiftaar' element={<DecemberSehriIftaar districtDetails={districtDetails} />} />
              <Route path='/shabebarat' element={<ShabeBarat /> } />
              <Route path='/ramzanulmubarak' element={<Ramzan /> } />
              <Route path='/shabeqadr' element={<ShabeQadr />} />
              <Route path='/esalesawab' element={<EsaleSawab />} />
              <Route path='/ramzancalendar' element={<RamzanCalendar />} />
              <Route path='/miladeakbar' element={<MiladeAkbar /> } />
              <Route path='/eid-ul-adha' element={<EidulAdha />} />
              <Route path='/aala-hazrat' element={<AalaHazrat /> } />
              <Route path='/wuzu-ka-bayan' element={<Wuzu />} />
              <Route path='/beti-ki-azmat' element={<BetiKiAzmat />} />
              <Route path='/quran-shareef-padhne-ki-fazilat' element={<QuranShareef />} />
              <Route path='/esalesawabviewdetails' element={<Esalesawabview />} />
              <Route path='/registrationview' element={<RegistrationView /> } />
              <Route path="/*" element={<Navigate to ="/" />}  />
           </Routes>
          </main>
        </>
    )
}

export default MainLayout;
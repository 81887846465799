import React, { useEffect, useState} from "react";
import './Dashboard.css';
import { useNavigate } from 'react-router-dom';
import { BsMoonStars } from "react-icons/bs";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { Link } from "react-router-dom";
import axios from "axios";
import SliderSection from "../SliderSection/SliderSection";
import DailyTime from "./DailyTime";

// import DashboardSkeleton from "../Skeleton/DashboardSkeleton";

const Dashboard = ({districtDetails} ) => {
   const { district, state } = districtDetails;
   // console.log(`District: ${district}, State: ${state}`);

const current = new Date();
const date = `${current.getDate()}-${current.getMonth()+1}-${current.getFullYear()}`;

const onlydate = `${current.getDate()}`;
// console.log(onlydate);
// const onlymonth = `${current.getMonth()+1}`;
// console.log(typeof onlydate);

// urduTimeTable code
const[urduTime,setUrduTime] = useState([]);

const getUrduTime = async () =>{
   try {
   const response = await axios.get('https://talabulilm.in/urdutimetable.php')
   setUrduTime(response.data)
   } catch (error) {
   console.log(error)
   }
   };
   
   useEffect(() => {
      getUrduTime();
   },[])

function getMonths(){
   let day;
   switch (new Date().getMonth()+1) {
     case 1:
       day = "../januarytimetable";
       break;
     case 2:
       day = "../februarytimetable";
       break;
     case 3:
       day = "../marchtimetable";
       break;
     case 4:
       day = "../apriltimetable";
       break;
     case 5:
       day = "../maytimetable";
       break;
     case 6:
       day = "../junetimetable";
       break;
     case 7:
       day = "../julytimetable";
       break;
     case 8:
       day = "../augusttimetable";
       break;
     case 9:
       day = "../septembertimetable";
       break;
     case 10:
       day = "../octobertimetable";
       break;
     case 11:
       day = "../novembertimetable";
       break;
     case 12:
       day = "../decembertimetable";
       break;
       default :
       day = "../blank";
   
   };
   return day;
   };
   // console.log(getMonths())

// console.log('onlydate is',onlydate);
// console.log('moonDate',moonDate);
// urduTime.map((item, index) => (
// ));
// console.log('urdu date is :',urduDate);
// const locale = 'en';
// const [today, setDate] = useState(new Date()); // Save the current date to be able to trigger an update
// const currenttime = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
// const[times,setTimes] = useState([]);
// const getTime = async () =>{
// try {
// const response = await axios.get('https://talabulilm.in/getData.php?slimit=1&elimit=359')
// setTimes(response.data)
// } catch (error) {
// console.log(error)
// }
// };
// const[username, setUsername] = useState('');
// const[name, setName] = useState('');
// const[code, setCode] = useState('');

// useEffect(() => {
// getTime();
// },[])
const [updatedDt, setDt] = useState(new Date().toLocaleString());
useEffect(() => {
let secTimer = setInterval( () => {
setDt(new Date().toLocaleString())
},1000)
return () => clearInterval(secTimer);
}, []);
const timeWithSecond = updatedDt.substring(10);

// const handleSubmit = () =>{
//    if(username.length === 0){
//       alert('Enter Username');
//    }
//    else if(name.length === 0){
//       alert('Enter Name');
//    }
//    else if(code.length === 0){
//       alert('Enter Code');
//    }
//    else if(code !== 'MiladeAkbar' ){
//       alert('Code is wrong');
//    }
//    else {
//       const url = "https://talabulilm.in/registration.php";

//       let fData = new FormData();
//       fData.append('username',username);
//       fData.append('name',name);
//       fData.append('code',code);

//       axios.post(url,fData)
//       .then(response => alert(response.data))
//       .catch(error => alert(error));
//       }
//       setUsername('');
//       setName('');
//       setCode('');
// }
// const [pageLoading, setPageLoading] = useState(true);
   //  useEffect(() => {   
   //      setTimeout(() => {
   //          setPageLoading(false);
   //      }, 2000);

   //  }, []);
    
   const navigate = useNavigate();

return (
<>
{/* <DashboardSkeleton /> */}
   <section className="Dashboard contain">

   <div className="dashboard-header">
      <div className="dashboard-header-section">
         <span className="ani-move-down">Today's</span>
         <h1>Namaz Time Table</h1>
         {/* <p>Lucknow, Uttar Pradesh, INDIA</p>    */}
         <p>{district}{state ? `, ${state}` : ''}, INDIA</p>   

         
      </div>
      <div className="dashboard-header-section__tt">
         <div className="header-section__timetable">
            <div className="date">
            
               <IoCalendarNumberOutline size={22}/>
               <span>{date} {timeWithSecond}</span>
               
            </div>
         
               {urduTime.map((item, index) => {
               let urduDate;
               if (item.operator === '-') {
                  urduDate = Number(onlydate) - item.number;
               } else if (item.operator === '+') {
                  urduDate = Number(onlydate) + Number(item.number); 
               }
               return (
                   urduDate > 0 &&  urduDate < 31 &&
                  <div key={index} className="urdu-date">
                     <BsMoonStars size={20} />
                     <span>{urduDate} {item.month} {item.hijri}</span>
                  </div>
               );
            })}
            
         </div>
      </div>
   </div>
   <DailyTime districtDetails={districtDetails}/>
   <div className="dash-btn-container">
      <Link to={getMonths()}  title="view the monthly time table">
      <button className="btn-btn-secondary">Monthly View</button>
      </Link>
   </div>
      </section>

<section className="Subgrid">
   <div className="subGrid-section contain">
      <div className="subGrid-section__first">
           {/* Quran Shareef padhne ki fazilat */}
           <div className="contentSection-card">
            <h2 className="contentSection_title">
               Quran Shareef Padhne Ki Fazilat
            </h2>
            <p className="contentSection_title--content">
               “ Rozana Quran-E-Azeem ki tilawat kiya karo isliye ke
               Hadees Shareef me Aaya hai Rasool Allah صَلَّى اللَّهُ عَلَيْهِ وَسَلَّم ne farmaya Quran padha karo isliye ke 
               ye Quran qayamat ke din Quran padhne walo ki shafa'at karega.
            </p>
            <span className="referenceTag">[ Muslim Shareef : 804 ]</span>
            <p className="contentSection_title--content">
               Hazrat Ayesha Siddiqa radiallahu anha riwayat karti hai ke NABI-E-KAREEM صَلَّى اللَّهُ عَلَيْهِ وَسَلَّم ne farmaya,
               Quran ka mahir moazzaz wa mohtaram farishto aur 
               moazzam wa mukarram Ambiya ke sath hoga aur wo shakhs jo....
            </p>
            <div className="dash-btn-container">
               <button className="btn-btn-secondary" onClick={() => navigate('/quran-shareef-padhne-ki-fazilat')}>Read More</button>
            </div>
         </div>
           {/*Islam Me Betiyon Ka Maqam aur Unke Huqooq      */}
           <div className="contentSection-card">
            <h2 className="contentSection_title">
            Islam Me Betiyon Ka Maqam aur Unke Huqooq
            </h2>
            <p className="contentSection_title--content">
            Islam ek mukammal zindagi ka nizaam hai jo insaniyat ke har pehlu ko bahut tafseel ke sath samjhata hai. Islam ne betiyon ko na sirf izzat aur maqam diya, balke unki har zarurat ka bhi khyal rakha.Nabi-E-Kareem ﷺ ne betiyon ke hawale se kayi irshadaat bayan farmaye hain. Betiyon ki paidaish se leker unki tarbiyat, muhabbat, shaadi aur unke huqooq tak islam ne roushan rahnumai ki hai.neeche kuch ahaades aur Qur'an ki roushni me betiyon ki ahmiyat bayan ki gayi hai.
            </p>
            <p className="contentSection_subheading font-weight-bold">1. Beti ki Paidaish Ko Barkat Aur Rahmat Qarar Dena</p>
            <p className="contentSection_title--content">
            Islam ne betiyon ki paidaish ko nafrat ki jagah ek barkat aur rahmat qarar diya. Jahalat ke daur me log betiyon ko zinda dafna dete the, lekin Islam ne is jahalat ko khatam kiya. Islam ne beti ki paidaish ko na sirf barkat samjha, balke jannat ka zariya banaya.  
            </p>
            <div className="dash-btn-container">
               <button className="btn-btn-secondary" onClick={() => navigate('/beti-ki-azmat')}>Read More</button>
            </div>
         </div>
         {/* Wuzu ka bayan */}
         {/* <div className="contentSection-card">
            <h2 className="contentSection_title">
               Wuzu ka Bayan
            </h2>
            <p className="contentSection_title--content">
               wuzu me 4 faraiz hain.
            </p>
            <ul className="contenetList">
               <li>1. Chehra Dhona </li>
               <li>2. Kohniyo'n samet dono hatho ko dhona</li>
               <li>3. Chauthai (1/4) sar ka masah karna</li>
               <li>4. Takhno samet dono paao (पाँव) ko dhona.</li>
            </ul>
            <div className="dash-btn-container">
               <button className="btn-btn-secondary"   onClick={() => navigate('/wuzu-ka-bayan')}>Read More</button>
            </div>
         </div> */}
         {/* Eid-ul-Adha */}
         <div className="contentSection-card">
            <h2 className="contentSection_title">
               EID-UL-ADHA
            </h2>
            <h3 className="contentSection_subtitle">QURBAANI KA BAYAAN</h3>
            <p className="contentSection_title--content">
               Qurbaani hazrat saiyyiduna ibaraheem عليه السلام ki sunnat hai jo is ummat ke
               liye baaqi rah gayi aur nabi صلى الله تعالى علي ه وسلم ko qurbaani karne ka 
               hukm diya gaya irshad farmaya ّفَصَل لِرَبِّكَ وَانْحَر‌ْ Tum apne rabb ke liye namaz padho
               aur qurbaani karo.
            </p>
            <span className="referenceTag">[ Surah Al-Kausar aayat no. 2 ]</span>
            <h3 className="contentSection_subtitle">AHAADEES E KAREEMA</h3>
            <p className="contentSection_title--content">
               (1) ummul mumineen hazrat aaishah siddiaqa رضى الله تعالى عنها se riwayat hai
               keh huzur e aqdas صلى الله تعالى عليه وسلم ne irs had farmaya keh yaumunnahar 
               (daswen zilhijjah) me ibn e aadam ka koi amal khuda ke nazdeek khoon bahane 
               ( qurbaani karne) se ziyadah piyaara nahi aur oh jaanwar qiyaamat ke din apne 
               sing aur baal aur khuron ke saath aayega aur qurbaani ka khoon zmeen par girne se 
               qabl khuda ke nazdeek maqaam e qubool me pahunch jaata hai lihaza is ko....
            </p>
            <div className="dash-btn-container">
               <button className="btn-btn-secondary" onClick={() => navigate('/eid-ul-adha')}>Read More</button>
            </div>
         </div>
         {/* AALA HAZRAT */}
         <div className="contentSection-card">
            <h2 className="contentSection_title">
               AALA HAZRAT
            </h2>
            <h3 className="contentSection_subtitle">Imam Ahmad Raza Khan Barelvi Rahmatullāhi Alaihi</h3>
            <h4 className="contentSection_subheading">Alqaab:</h4>
            <p className="contentSection_title--content">
               Aa’la Hazrat, Mujaddid E Deen O Millat, Imam E Ahle Sunnat,Imam E Ishqo Muhabbat,Kanzul Karamat,
               Azeem Ul Barkat, Faazil E Bareilwi.
            </p>
            <h4 className="contentSection_subheading">Aala Hazrat Ki Wiladat:</h4>
            <p className="contentSection_title--content">
               Aap Ki Wilaadat 10 Shawwaal 1272 Hijri (14 June 1856 A.D.) Ko Barely Shareef 
               (Uttar Pradesh) Me Hui.
            </p>
            <div className="dash-btn-container">
               <button className="btn-btn-secondary" onClick={() => navigate('/aala-hazrat')}>Read More</button>
            </div>
         </div>
         {/* SAJDA E TILAWAT */}
         <div className="contentSection-card">
            <h2 className="contentSection_title">
               Sajda-E-Tilaawat Ka Bayaan
            </h2>
            <h3 className="contentSection_subtitle">Qur’an Majeed me 14 aayaten aisi hain ke us 
               aayat ki tilaawat karne ya sunne se Sajda e Tilaawat karna waajib hota hai.
            </h3>
            <h4 className="contentSection_subheading">Sajda-E-Tilawat Kise Kahte hain....?</h4>
            <p className="contentSection_title--content">
               Jab Quran Me Koi Ayat Aisi Aati hai Jisme Ki Allah Ne Sajda Karne Ka hukm Dia ho Us Ayat 
               ko Ayaate Sajda Kehte hain Quraan Me Aisi Ayaaten Kul 14 Jagah hai Aur Sajde ki Pahli
               Ayat 9 me Paare me hai.   
            </p>
            <p className="contentSection_title--content">
               Sajda-e-Tilawat Kaise Kare..? Sadae-e-Tilawat Usi Tarah kia Jaata hai Jaisa ki hum Namaz 
               Me Sajde kerte hain Par Isme Sirf Yeh Fark hai ki Isme Aapko Sirf Ek Sajda karna hai Aur 
               Namaz me 2 Sajde hote hain.
            </p>
            {/* <div className="dash-btn-container">
               <Link to='aalahazrat' title="">
               <button className="btn-btn-secondary">Read More</button>
               </Link>
            </div> */}
         </div>
        
      </div>
      <div className="subGrid-section__second">
         <div className="waqiaat contentSection-card">
            <h2 className="contentSection_title">Principles of an Ideal Society</h2>
            <SliderSection />
            {/* <div className="registration-form">
               <div className="registration-form__item">
                  <input type="text" name="username" placeholder="Enter Your UserName" value={username} onChange={(e) =>               
                  setUsername(e.target.value)} />
                  <input type="text" name="name" placeholder="Enter Your Name" value={name} onChange={(e) => setName(e.target.value)} />
                  <input type="text" name="code" placeholder="Enter Code"  value={code} onChange={(e) => setCode(e.target.value)} />
                  <input type="button" name="send" id="send" value={'Submit'} onClick={() => {handleSubmit()}} disabled />
               </div>
            </div> */}
            {/* <div className="waqiaat--section">
               <marquee direction="up" >
                  <ul>
                     <a href="google.com">
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                     </a>
                     <a href="google.com">
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                     </a>
                     <a href="google.com">
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                     </a>
                     <a href="google.com">
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                     </a>
                     <a href="google.com">
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                     </a>
                     <a href="google.com">
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                     </a>
                     <a href="google.com">
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. </li>
                     </a>
                  </ul>
               </marquee>
            </div> */}
         </div>
         
      </div>
   </div>
  
</section>
</>
)
}

export default Dashboard;
import React, { useState } from 'react'
import { MdArrowBackIosNew } from "react-icons/md";
import { DefaultNaflnamaz, HindiNaflNamaz, UrduNaflNamaz } from './Accordion/Naflnamaz';
function ShabeQadr() {
    const[language,setLanguage]=useState('DefaultNaflnamaz');
  
    const handleLanguageChange  = (event) => {
      setLanguage(event.target.value);
    }
    const renderSelectedComponent = () => {
      switch (language) {
          case 'UrduNaflNamaz':
              return <UrduNaflNamaz />;
          case 'HindiNaflNamaz':
              return <HindiNaflNamaz />;
          default:
              return <DefaultNaflnamaz />;
      }
  };
return (
<div className='contain'>
   <div className="page-header">
      <span className="backBtn" onClick={()=>
         window.history.back()}>
         <MdArrowBackIosNew />
      </span>
      <div className="page-header__content">
         <h2 className="pagetitle">Shabe Qadr Ki Fazilat</h2>
      </div>
   </div>
   <div className="contentSection-card">
      <p className="contentSection_title--content">
         Huzoor Nabi-E-Kareem صَلَّى اللَّهُ عَلَيْهِ وَسَلَّم ne irshad farmaya :
      </p>
      <p className="contentSection_title--content">
         Shabe Qadr ko Ramzan-ul-Mubarak ke Aakhiri Ashre ki taak (odd) raato (i.e. 21,23,25,27 and 29 ) me dhoondo.
      </p>
      <span className="referenceTag">[ Bukhari Shareef: 2017 ]</span>
      <p className="contentSection_title--content">
         Huzoor Nabi-E-Kareem صَلَّى اللَّهُ عَلَيْهِ وَسَلَّم ne irshad farmaya :
      </p>
      <p className="contentSection_title--content">
         Jis shaksh ne is Raat Me Imaan Aur Ikhlaas Ke Sath Shabe Bedaari (Raat Jaag kar) Ibaadat Ki 
         To Allah paak Uske pichle sare gunaah Bakhsh Deta hai.            
      </p>
      <span className="referenceTag">[ Bukhari Shareef: 35 ]</span>
      <p className="contentSection_title--content">
         Huzoor Nabi-E-Kareem صَلَّى اللَّهُ عَلَيْهِ وَسَلَّم ne irshad farmaya :  
      </p>
      <p className="contentSection_title--content">
         Ye Mahina ( Ramzan-ul-Mubarak) jo tum par aaya hai isme ek aisi raat hai jo hazaar mahino se behtar hai,
         jo shaksh isse mahroom raha wo har bhalaai se mahroom raha aur iski bhalaai se mahroom wahi rahega jo
         waqai mahroom ho.
      </p>
      <span className="referenceTag">[ ibne Majah : 1644 ]</span>
      <h3 className="contentSection_subtitle">   Shabe Qadr ki Dua</h3>
      <p className="contentSection_title--content">
         Hazrat Ayesha Siddiqa (radi allahu anha ) ne Huzoor Nabi-E-Kareem صَلَّى اللَّهُ عَلَيْهِ وَسَلَّم se
         poocha , Agar mujhe maloom ho jaye ki Shabe Qadr konsi raat hai to kya Dua karu...?
      </p>
      <p className="contentSection_title--content">
         Huzoor Nabi-E-Kareem صَلَّى اللَّهُ عَلَيْهِ وَسَلَّم ne irshad farmaya :
      </p>
      <h2 className="contentSection_subtitle duaeShabeQadr"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
      <span className="referenceTag">[ Tirmizi Shareef: 3513 ]</span>
      <h3 className="contentSection_subtitle mb-20">Nawafil Namaze :</h3>
      <div className='changeLangSection'>
            <label htmlFor="language">Change Language:</label>
              <select name="language" id="language" value={language} onChange={handleLanguageChange}>
                <option value="DefaultNaflnamaz">Default</option>
                <option value="UrduNaflNamaz">Urdu</option>  
                <option value="HindiNaflNamaz">Hindi</option>  
              </select>
      </div>
      {renderSelectedComponent()}

   </div>
</div>
)
}
export default ShabeQadr
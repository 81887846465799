import React, { useRef, useState } from 'react'
import duroodeibraheemi from "../../Images/durrodePaak.webp";
import istighfar from "../../Images/istighfar.webp";
import kalma3 from "../../Images/kalma3.webp";
import kalma1 from "../../Images/kalma1.webp";
import kalma2 from "../../Images/kalma2.webp";
import tasbeeh from "../../Images/tasbeeh.webp";
import dhikr from "../../Images/dhikr.webp";
import Surah_Nashrah from "../../Images/surahNashrah.webp";
import Surah_Qadr from "../../Images/Surah_Qadr.webp";
import salatTasbeeh2 from "../../Images/shab2.webp";
import salatTasbeeh from "../../Images/salat-tasbeeh.webp";
// import suratAudio from "../../Assets/Audio/surat-alfalaq.mp3";





const Accordion = ({ title, children, defaultOpen = false  }) => {
  const [isOpen, setOpen] = useState(defaultOpen);

  return (
    <div className="accordion-wrapper">
      
      <div
        className={`accordion-title ${isOpen ? "open" : ""}`}
        onClick={() => setOpen(!isOpen)}
        >
        {title}
      </div>
      <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  );
};

export const DefaultNaflnamaz = () => {
    return (
      <div className="wrapper contentSection-card defaultNaflNamaz">
      <Accordion title="21vi Shab-e-Qadr" className="open" defaultOpen={true}>
        <div className='nafl_namaze__item contentSection-card'>
          <span>7 Martaba  </span>
          <div className='nafl_namaze__description'>
              <span>7 martaba ye dua padhein.</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <span>7 Martaba  </span>
          <div className='nafl_namaze__description'>
              <span>7 martaba Surah Qadr (Inna Anzalnahu) padhein.</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">Surah Qadr :</h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>4 Rakat  </span>
          <div className='nafl_namaze__description'>
              <span><strong>Rakatein: </strong> 4 Rakat Nafl (2-2 Rakat karke)</span>
              <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Qadr (Inna Anzalnahu) aur Surah Ikhlas (Qul Huwallahu Ahad) 1-1 martaba padhein. Baad Salam ke 70 martaba Durood-e-Pak padhein. (Afzal Durood, Durood-e-Ibrahimi hai) 
              </span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-20">Durood-e-Ibrahimi :</h3>
        <img loading='lazy' title='Duroode Ibraheemi' src={duroodeibraheemi} alt="duroode ibraheemi" className='duroodeibraheemi' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
        <span>2 Rakat  </span>
        <div className='nafl_namaze__description'>
            <span><strong>Rakatein: </strong> 2 Rakat Nafl</span>
            <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Qadr (Inna Anzalnahu) 1-1 martaba aur Surah Ikhlas (Qul Huwallahu Ahad) 3-3 martaba padhein. Baad Salam ke 70 martaba Istighfar  padhein.
            </span>
        </div>
      </div>
      <h3 className="contentSection_subtitle ">Istighfar  :</h3>
      <img loading='lazy' title='istighfar' src={istighfar} alt="istagfarImg"  />
      <div className='nafl_namaze__item contentSection-card mt-20 mb-20 salatutTasbeeh'>
          <span className='rakatCounts'>4 Rakat  </span>
          <div className='nafl_namaze__description'>
            <span><strong>Salatut Tasbeeh  </strong> ki Namaz</span>
            <span><strong>Rakatein: </strong> 4 Rakat Nafl </span>
            <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Neeche image me dekhe.
            </span>
          </div>
        </div>
       <h3 className="contentSection_subtitle mb-20">Salatut Tasbeeh  :</h3>
       <img title='img4' src={salatTasbeeh2} alt='naflNamaz4' loading='lazy' className='max-w-400'/>
       <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>Durood Shareef</span>
           <div className='nafl_namaze__description'>
              <span>1000 Martaba</span>
                <span className='nafl_namaze__failat'>
                        Iske alawa <b>Qaza-e-Umri</b>, Surah Yaseen, Surah Mulk, Surah Rahman, Surah Muzzammil, Istighfar (Astaghfirullah) aur Kalma Shareef padh sakte hain! Jis qadr aap nafl namaz padh sakein, wo padhein!
                </span>
            </div>
        </div>
      </Accordion>
      <Accordion title="23vi Shab-e-Qadr">
      <div className='nafl_namaze__item contentSection-card'>
          <span>7 Martaba  </span>
          <div className='nafl_namaze__description'>
              <span>7 martaba ye dua padhein.</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <span>7 Martaba  </span>
          <div className='nafl_namaze__description'>
              <span>7 martaba Surah Qadr (Inna Anzalnahu) padhein.</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">Surah Qadr :</h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>4 Rakat  </span>
          <div className='nafl_namaze__description'>
              <span><strong>Rakatein: </strong> 4 Rakat Nafl (2-2 Rakat karke)</span>
              <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Qadr (Inna Anzalnahu) 1-1 martaba aur Surah Ikhlas (Qul Huwallahu Ahad) 3-3 martaba padhein.
              </span>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>8 Rakat  </span>
          <div className='nafl_namaze__description'>
              <span><strong>Rakatein: </strong> 8 Rakat Nafl (2-2 Rakat karke)</span>
              <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Qadr (Inna Anzalnahu) aur Surah Ikhlas (Qul Huwallahu Ahad) 1-1 martaba padhein. Baad Salam ke 70 martaba Kalma-e-Tamjeed (Teesra Kalma) padhein.
              </span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">Kalma-e-Tamjeed (Teesra Kalma) :</h3>
        <img loading='lazy' title='img' src={ kalma3} alt=" kalma3Img" />
        <div className='nafl_namaze__item contentSection-card mt-20 mb-20 salatutTasbeeh'>
          <span className='rakatCounts'>4 Rakat  </span>
          <div className='nafl_namaze__description'>
            <span><strong>Salatut Tasbeeh  </strong> ki Namaz</span>
            <span><strong>Rakatein: </strong> 4 Rakat Nafl </span>
            <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Neeche image me dekhe.
            </span>
          </div>
        </div>
       <h3 className="contentSection_subtitle mb-20">Salatut Tasbeeh  :</h3>
       <img title='img4' src={salatTasbeeh2} alt='naflNamaz4' loading='lazy' className='max-w-400'/>
       <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>Durood Shareef</span>
           <div className='nafl_namaze__description'>
              <span>1000 Martaba</span>
                <span className='nafl_namaze__failat'>
                        Iske alawa <b>Qaza-e-Umri</b>, Surah Yaseen, Surah Mulk, Surah Rahman, Surah Muzzammil, Istighfar (Astaghfirullah) aur Kalma Shareef padh sakte hain! Jis qadr aap nafl namaz padh sakein, wo padhein!
                </span>
            </div>
        </div>
      </Accordion>
      <Accordion title="25vi Shab-e-Qadr">
      <div className='nafl_namaze__item contentSection-card'>
          <span>7 Martaba  </span>
          <div className='nafl_namaze__description'>
              <span>7 martaba ye dua padhein.</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <span>7 Martaba  </span>
          <div className='nafl_namaze__description'>
              <span>7 martaba Surah Qadr (Inna Anzalnahu) padhein.</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">Surah Qadr :</h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>4 Rakat  </span>
            <div className='nafl_namaze__description'>
                <span><strong>Rakatein: </strong> 4 Rakat Nafl (2-2 Rakat karke)</span>
                <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Qadr (Inna Anzalnahu) 1-1 martaba aur Surah Ikhlas (Qul Huwallahu Ahad) 5 - 5 martaba padhein. Baad Salam ke 100 martaba Kalma-e-Tayyab (Pahla Kalma) padhein. 
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle mb-10">Kalma-e-Tayyab (Pahla Kalma)</h3>
          <img loading='lazy' title='Kalmae tayyab' src={ kalma1} alt=" Kalmae tayya" />
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>4 Rakat  </span>
            <div className='nafl_namaze__description'>
                <span><strong>Rakatein: </strong> 4 Rakat Nafl (2-2 Rakat karke)</span>
                <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Qadr (Inna Anzalnahu) 3-3 martaba aur Surah Ikhlas (Qul Huwallahu Ahad) 3-3 martaba padhein. Baad Salam ke 70 martaba Istighfar  padhein.
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle ">Istighfar  :</h3>
          <img loading='lazy' title='istighfar' src={istighfar} alt="istagfarImg"  />
  
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>2 Rakat  </span>
            <div className='nafl_namaze__description'>
                <span><strong>Rakatein: </strong> 2 Rakat Nafl</span>
                <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Qadr (Inna Anzalnahu) 1-1 martaba aur Surah Ikhlas (Qul Huwallahu Ahad) 15-15 martaba padhein. Baad Salam ke 70 martaba Kalma-e-Shahadat (Doosra Kalma) padhein.
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle mb-20">Kalma-e-Shahadat (Doosra Kalma)</h3>
          <img loading='lazy' title='Kalma-E-Shahadat' src={kalma2} alt=" Kalma-E-Shahadat" className='max-w-400' />
          <div className='nafl_namaze__item contentSection-card mt-20 mb-20 salatutTasbeeh'>
          <span className='rakatCounts'>4 Rakat  </span>
          <div className='nafl_namaze__description'>
            <span><strong>Salatut Tasbeeh  </strong> ki Namaz</span>
            <span><strong>Rakatein: </strong> 4 Rakat Nafl </span>
            <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Neeche image me dekhe.
            </span>
          </div>
        </div>
       <h3 className="contentSection_subtitle mb-20">Salatut Tasbeeh  :</h3>
       <img title='img4' src={salatTasbeeh2} alt='naflNamaz4' loading='lazy' className='max-w-400'/>
       <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>Durood Shareef</span>
           <div className='nafl_namaze__description'>
              <span>1000 Martaba</span>
                <span className='nafl_namaze__failat'>
                        Iske alawa <b>Qaza-e-Umri</b>, Surah Yaseen, Surah Mulk, Surah Rahman, Surah Muzzammil, Istighfar (Astaghfirullah) aur Kalma Shareef padh sakte hain! Jis qadr aap nafl namaz padh sakein, wo padhein!
                </span>
            </div>
        </div>
      </Accordion>
      <Accordion title="27vi Shab-e-Qadr">
      <div className='nafl_namaze__item contentSection-card'>
          <span>7 Martaba  </span>
          <div className='nafl_namaze__description'>
              <span>7 martaba ye dua padhein.</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <span>7 Martaba  </span>
          <div className='nafl_namaze__description'>
              <span>7 martaba Surah Qadr (Inna Anzalnahu) padhein.</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">Surah Qadr :</h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>12 Rakat  </span>
            <div className='nafl_namaze__description'>
                <span><strong>Rakatein: </strong> 12 Rakat Nafl (2-2 Rakat karke)</span>
                <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Qadr (Inna Anzalnahu) 1-1 martaba aur Surah Ikhlas (Qul Huwallahu Ahad) 15-15 martaba padhein. Baad Salam ke 70 martaba Istighfar  padhein.
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle ">Istighfar  :</h3>
          <img loading='lazy' title='istighfar' src={istighfar} alt="istagfarImg"  />
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>2 Rakat  </span>
            <div className='nafl_namaze__description'>
                <span><strong>Rakatein: </strong> 2 Rakat Nafl</span>
                <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Qadr (Inna Anzalnahu) 3-3 martaba aur Surah Ikhlas (Qul Huwallahu Ahad) 27-27 martaba padhein.
                </span>
            </div>
          </div>
  
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>4 Rakat  </span>
            <div className='nafl_namaze__description'>
                <span><strong>Rakatein: </strong> 4 Rakat Nafl (2-2 Rakat karke)</span>
                <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Takasur (Alhaakumut Takasur) 1-1 martaba aur Surah Ikhlas (Qul Huwallahu Ahad) 3-3 martaba padhein.
                </span>
            </div>
          </div>
          <div className='nafl_namaze__item contentSection-card mt-20 mb-20'>
            <span>2 Rakat  </span>
            <div className='nafl_namaze__description'>
                <span><strong>Rakatein: </strong> 2 Rakat Nafl </span>
                <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Ikhlas (Qul Huwallahu Ahad) 7-7 martaba padhein. Baad Salam ke 70 martaba ye Tasbeeh padhein.
                </span>
            </div>
          </div>
          <img loading="lazy" title="istighfar" src={tasbeeh} alt="istighfar" className="invert max-w-400" />
          <div className='nafl_namaze__item contentSection-card mt-20 mb-20'>
            <span>2 Rakat  </span>
            <div className='nafl_namaze__description'>
                <span><strong>Rakatein: </strong> 2 Rakat Nafl </span>
                <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Nashrah (Alam Nashrah) 1-1 martaba Surah Ikhlas (Qul Huwallahu Ahad) 3-3 martaba padhein. Baad Salam ke 27 martaba Surah Qadr (Inna Anzalnahu) padhein.
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle mb-20">Surah Nashrah (Alam Nashrah)</h3>
          <img loading='lazy' title='img' src={Surah_Nashrah} alt="Surah_NashrahImg"  className='max-w-400'/> 
  
          <div className='nafl_namaze__item contentSection-card mt-20 mb-5'>
            <span>4 Rakat  </span>
            <div className='nafl_namaze__description'>
                <span><strong>Rakatein: </strong> 4 Rakat Nafl (2-2 Rakat karke)</span>
                <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong>Har Rakat me Surah Qadr (Inna Anzalnahu) 3-3 martaba aur Surah Ikhlas (Qul Huwallahu Ahad) 50-50 martaba padhein. Baad Salam ke Sajde me sar rakh kar ek martaba ye Kalimat padhein.
                </span>
            </div>
          </div>
          <img loading='lazy' title='img' src={dhikr} alt="dhikrImg"  />       
          <div className='nafl_namaze__item contentSection-card mt-20 mb-20 salatutTasbeeh'>
          <span className='rakatCounts'>4 Rakat  </span>
          <div className='nafl_namaze__description'>
            <span><strong>Salatut Tasbeeh  </strong> ki Namaz</span>
            <span><strong>Rakatein: </strong> 4 Rakat Nafl </span>
            <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Neeche image me dekhe.
            </span>
          </div>
        </div>
       <h3 className="contentSection_subtitle mb-20">Salatut Tasbeeh  :</h3>
       <img title='img4' src={salatTasbeeh2} alt='naflNamaz4' loading='lazy' className='max-w-400'/>
       <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>Durood Shareef</span>
           <div className='nafl_namaze__description'>
              <span>1000 Martaba</span>
                <span className='nafl_namaze__failat'>
                        Iske alawa <b>Qaza-e-Umri</b>, Surah Yaseen, Surah Mulk, Surah Rahman, Surah Muzzammil, Istighfar (Astaghfirullah) aur Kalma Shareef padh sakte hain! Jis qadr aap nafl namaz padh sakein, wo padhein!
                </span>
            </div>
        </div>          
      </Accordion>
      <Accordion title="29vi Shab-e-Qadr">
      <div className='nafl_namaze__item contentSection-card'>
          <span>7 Martaba  </span>
          <div className='nafl_namaze__description'>
              <span>7 martaba ye dua padhein.</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <span>7 Martaba  </span>
          <div className='nafl_namaze__description'>
              <span>7 martaba Surah Qadr (Inna Anzalnahu) padhein.</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">Surah Qadr :</h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>4 Rakat  </span>
            <div className='nafl_namaze__description'>
                <span><strong>Rakatein: </strong> 4 Rakat Nafl (2-2 Rakat karke)</span>
                <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Qadr (Inna Anzalnahu) 1-1 martaba aur Surah Ikhlas (Qul Huwallahu Ahad) 3 - 3 martaba padhein. Baad Salam ke 70 martaba Surah Nashrah (Alam Nashrah) padhein. 
                </span>
            </div>
        </div>
        <h3 className="contentSection_subtitle mb-20">Surah Nashrah (Alam Nashrah)</h3>
        <img loading='lazy' title='img' src={Surah_Nashrah} alt="Surah_NashrahImg" className='max-w-400' /> 
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>4 Rakat  </span>
            <div className='nafl_namaze__description'>
                <span><strong>Rakatein: </strong> 4 Rakat Nafl (2-2 Rakat karke)</span>
                <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Har Rakat me Surah Fatiha ke baad Surah Qadr (Inna Anzalnahu) 1-1 martaba aur Surah Ikhlas (Qul Huwallahu Ahad) 5-5 martaba padhein. Baad Salam ke 100 martaba Durood-e-Pak padhein. (Afzal Durood, Durood-e-Ibrahimi hai) 
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle mb-20">Durood-e-Ibrahimi :</h3>
          <img loading='lazy' title='Duroode Ibraheemi' src={duroodeibraheemi} alt="duroode ibraheemi" className='duroodeibraheemi' />
          <div className='nafl_namaze__item contentSection-card mt-20 mb-20 salatutTasbeeh'>
          <span className='rakatCounts'>4 Rakat  </span>
          <div className='nafl_namaze__description'>
            <span><strong>Salatut Tasbeeh  </strong> ki Namaz</span>
            <span><strong>Rakatein: </strong> 4 Rakat Nafl </span>
            <span className='nafl_namaze__failat'><strong> Tareeqa :  </strong> Neeche image me dekhe.
            </span>
          </div>
        </div>
       <h3 className="contentSection_subtitle mb-20">Salatut Tasbeeh  :</h3>
       <img title='img4' src={salatTasbeeh2} alt='naflNamaz4' loading='lazy' className='max-w-400'/>
       <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>Durood Shareef</span>
           <div className='nafl_namaze__description'>
              <span>1000 Martaba</span>
                <span className='nafl_namaze__failat'>
                        Iske alawa <b>Qaza-e-Umri</b>, Surah Yaseen, Surah Mulk, Surah Rahman, Surah Muzzammil, Istighfar (Astaghfirullah) aur Kalma Shareef padh sakte hain! Jis qadr aap nafl namaz padh sakein, wo padhein!
                </span>
            </div>
        </div>
      </Accordion>
    </div>
    )
  }

  
  export const UrduNaflNamaz = () => {
    // const audioRef = useRef(null);
  
    // const handlePlayPause = () => {
    //   if (audioRef.current) {
    //     if (audioRef.current.paused) {   
    //       audioRef.current.play();
    //     } else {
    //       audioRef.current.pause();
    //     }
    //   }
    // };
    return (
    <div className="wrapper contentSection-card UrduNaflNamaz urduFont">
      <Accordion   title={<div className='d-flex flex-direction-row-reverse gap-10'><span>۲۱ </span> <span>ویں شبِ قدر</span></div>} className="open" defaultOpen={true}>
        <div className='nafl_namaze__item contentSection-card'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>7</span> مرتبہ</div>
          <div className='nafl_namaze__description'>
              <span>7 مرتبہ یہ دعا پڑھیں۔</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>7</span> مرتبہ</div>
          <div className='nafl_namaze__description'>
              <span>7 مرتبہ سورہ قدر (إِنَّا أَنْزَلْنَاهُ) پڑھیں-</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">: سورہ قدر </h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
      <div className='nafl_namaze__item contentSection-card mt-20'>
            <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>
            <div className='nafl_namaze__description'>
                <span><strong> رکعتیں :</strong> 4 رکعات نفل (۲-۲ رکعات کرکے)</span>
                <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعد سورہ قدر (إِنَّا أَنْزَلْنَاهُ) ۱-۱ مرتبہ اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) ۱-۱ مرتبہ پڑھیں ! بعد سلام کے 70 مرتبہ درودِ  پاک پڑھیں۔
                (افضل درود، درودِ ابراہیمی ہے)
                </span>
            </div>
        </div>
        <h3 className="contentSection_subtitle mb-20">درودِ ابراہیمی  </h3>
        <img loading='lazy' title='Duroode Ibraheemi' src={duroodeibraheemi} alt="duroode ibraheemi" className='duroodeibraheemi' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>۲</span> رکعات</div>
          <div className='nafl_namaze__description'>
              <span><strong>رکعتیں : </strong> 2 رکعات  نفل </span>
              <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعد سورہ قدر (إِنَّا أَنْزَلْنَاهُ) 1-1 مرتبہ اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) 3-3 مرتبہ پڑھیں ! بعد سلام کے 70 مرتبہ استغفار پڑھیں!
              </span>
          </div>
         </div>
      <h3 className="contentSection_subtitle">استغفار</h3>
      <img loading='lazy' title='istighfar' src={istighfar} alt="istagfarImg"  />
      <div className='nafl_namaze__item contentSection-card mt-20 salatutTasbeeh'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>
          <div className='nafl_namaze__description'>
              <span><strong>              صلوۃ التسبیح </strong> کی نماز</span>
              <span><strong>رکعتیں : </strong> 4 رکعات  نفل </span>
              <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> نیچے  image میں دیکھیں -    </span>
          </div>
      </div>
      <h3 className="contentSection_subtitle mb-10">صلوۃ التسبیح </h3>
      <img loading='lazy' title='salatTasbeeh' src={salatTasbeeh} alt="salatTasbeeh" className='max-w-400' />
      <div className='nafl_namaze__item contentSection-card mt-20 salatutTasbeeh'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'> درود شریف</div>
          <div className='nafl_namaze__description'>
              <span>1000 مرتبہ</span>
              <span className='nafl_namaze__failat'> اس کے علاوہ   قَضائے عُمری، سورہ یٰسین، سورہ ملک، سورہ رحمان، سورہ مزمل، استغفار (أستغفرالله) اور کلمہ شریف پڑھ سکتے ہیں! جس قدر آپ نفل نماز پڑھ سکیں، وہ پڑھیں!</span>
          </div>
      </div>
      </Accordion>
      <Accordion title={<div className='d-flex flex-direction-row-reverse gap-10'><span>۲۳ </span> <span>ویں شبِ قدر</span></div>}>
      <div className='nafl_namaze__item contentSection-card'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>7</span> مرتبہ</div>
          <div className='nafl_namaze__description'>
              <span>7 مرتبہ یہ دعا پڑھیں۔</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>7</span> مرتبہ</div>
          <div className='nafl_namaze__description'>
              <span>7 مرتبہ سورہ قدر (إِنَّا أَنْزَلْنَاهُ) پڑھیں-</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">: سورہ قدر </h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>
          <div className='nafl_namaze__description'>
              <span><strong>رکعتیں : </strong> 4 رکعات نفل (۲-۲ رکعات کرکے)</span>
              <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعد سورہ قدر (إِنَّا أَنْزَلْنَاهُ) ۱-۱ مرتبہ اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) ۳-۳ مرتبہ پڑھیں !
              </span>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card mt-20'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>۸</span> رکعات</div>

          <div className='nafl_namaze__description'>
              <span><strong>رکعتیں : </strong> ۸ رکعات  نفل (۲-۲ رکعات کرکے)</span>
              <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعد سورہ قدر (إِنَّا أَنْزَلْنَاهُ) اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) ۱-۱ مرتبہ پڑھیں ! بعد سلام کے 70 مرتبہ کلمۂ تمجید (تیسرا کلمہ) پڑھیں!
              </span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">کلمۂ تمجید (تیسرا کلمہ)</h3>
        <img loading='lazy' title='img' src={ kalma3} alt=" kalma3Img" />
        <div className='nafl_namaze__item contentSection-card mt-20 salatutTasbeeh'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>
          <div className='nafl_namaze__description'>
              <span><strong>              صلوۃ التسبیح </strong> کی نماز</span>
              <span><strong>رکعتیں : </strong> 4 رکعات  نفل </span>
              <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> نیچے  image میں دیکھیں -    </span>
          </div>
      </div>
      <h3 className="contentSection_subtitle mb-10">صلوۃ التسبیح </h3>
      <img loading='lazy' title='salatTasbeeh' src={salatTasbeeh} alt="salatTasbeeh" className='max-w-400' />
      <div className='nafl_namaze__item contentSection-card mt-20 salatutTasbeeh'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'> درود شریف</div>
          <div className='nafl_namaze__description'>
              <span>1000 مرتبہ</span>
              <span className='nafl_namaze__failat'> اس کے علاوہ   قَضائے عُمری، سورہ یٰسین، سورہ ملک، سورہ رحمان، سورہ مزمل، استغفار (أستغفرالله) اور کلمہ شریف پڑھ سکتے ہیں! جس قدر آپ نفل نماز پڑھ سکیں، وہ پڑھیں!</span>
          </div>
      </div>
      </Accordion>
      <Accordion title={<div className='d-flex flex-direction-row-reverse gap-10'><span>۲۵ </span> <span>ویں شبِ قدر</span></div>}>
      <div className='nafl_namaze__item contentSection-card'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>7</span> مرتبہ</div>
          <div className='nafl_namaze__description'>
              <span>7 مرتبہ یہ دعا پڑھیں۔</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>7</span> مرتبہ</div>
          <div className='nafl_namaze__description'>
              <span>7 مرتبہ سورہ قدر (إِنَّا أَنْزَلْنَاهُ) پڑھیں-</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">: سورہ قدر </h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>

            <div className='nafl_namaze__description'>
                <span><strong>رکعتیں : </strong> 4 رکعات نفل (۲-۲ رکعات کرکے)</span>
                <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعد سورہ قدر (إِنَّا أَنْزَلْنَاهُ) ۱-۱ مرتبہ اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) ۵ - ۵ مرتبہ پڑھیں ! بعد سلام کے 100 مرتبہ کلمۂ طیب (پہلا کلمہ) پڑھیں۔
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle mb-10">کلمۂ طیب (پہلا کلمہ)</h3>
          <img loading='lazy' title='Kalmae tayyab' src={ kalma1} alt=" Kalmae tayya" />
          <div className='nafl_namaze__item contentSection-card mt-20'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>
            <div className='nafl_namaze__description'>
                <span><strong>رکعتیں : </strong> 4 رکعات نفل (۲-۲ رکعات کرکے)</span>
                <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعد سورہ قدر (إِنَّا أَنْزَلْنَاهُ) ۳-۳ مرتبہ اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) ۳-۳ مرتبہ پڑھیں ! بعد سلام کے 70 مرتبہ استغفار پڑھیں!
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle">استغفار</h3>
          <img loading='lazy' title='istighfar' src={istighfar} alt="istagfarImg"  />
  
          <div className='nafl_namaze__item contentSection-card mt-20'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>۲</span> رکعات</div>
            <div className='nafl_namaze__description'>
                <span><strong>رکعتیں : </strong> ۲ رکعات  نفل </span>
                <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعد سورہ قدر (إِنَّا أَنْزَلْنَاهُ)  ۱-۱ مرتبہ اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) ۱۵ - ۱۵ مرتبہ پڑھیں ! بعد سلام کے 70 مرتبہ کلمۂ شہادت (دوسرا کلمہ) پڑھیں!
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle mb-20">کلمۂ شہادت (دوسرا کلمہ)</h3>
          <img loading='lazy' title='Kalma-E-Shahadat' src={kalma2} alt=" Kalma-E-Shahadat" className='max-w-400'/>
          <div className='nafl_namaze__item contentSection-card mt-20 salatutTasbeeh'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>
          <div className='nafl_namaze__description'>
              <span><strong>              صلوۃ التسبیح </strong> کی نماز</span>
              <span><strong>رکعتیں : </strong> 4 رکعات  نفل </span>
              <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> نیچے  image میں دیکھیں -    </span>
          </div>
      </div>
      <h3 className="contentSection_subtitle mb-10">صلوۃ التسبیح </h3>
      <img loading='lazy' title='salatTasbeeh' src={salatTasbeeh} alt="salatTasbeeh" className='max-w-400' />
      <div className='nafl_namaze__item contentSection-card mt-20 salatutTasbeeh'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'> درود شریف</div>
          <div className='nafl_namaze__description'>
              <span>1000 مرتبہ</span>
              <span className='nafl_namaze__failat'> اس کے علاوہ   قَضائے عُمری، سورہ یٰسین، سورہ ملک، سورہ رحمان، سورہ مزمل، استغفار (أستغفرالله) اور کلمہ شریف پڑھ سکتے ہیں! جس قدر آپ نفل نماز پڑھ سکیں، وہ پڑھیں!</span>
          </div>
      </div>
      </Accordion>
      <Accordion title={<div className='d-flex flex-direction-row-reverse gap-10'><span>۲۷ </span> <span>ویں شبِ قدر</span></div>}>
      <div className='nafl_namaze__item contentSection-card'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>7</span> مرتبہ</div>
          <div className='nafl_namaze__description'>
              <span>7 مرتبہ یہ دعا پڑھیں۔</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>7</span> مرتبہ</div>
          <div className='nafl_namaze__description'>
              <span>7 مرتبہ سورہ قدر (إِنَّا أَنْزَلْنَاهُ) پڑھیں-</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">: سورہ قدر </h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>۱۲</span> رکعات</div>
            <div className='nafl_namaze__description'>
                <span><strong>رکعتیں : </strong> ۱۲ رکعات  نفل (۲-۲ رکعات کرکے)</span>
                <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعد سورہ قدر (إِنَّا أَنْزَلْنَاهُ)  ۱-۱ مرتبہ اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) ۱۵ - ۱۵ مرتبہ پڑھیں ! بعد سلام کے 70 مرتبہ استغفار پڑھیں!
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle mb-20">استغفار </h3>
          <img loading='lazy' title='istighfar' src={istighfar} alt="istagfarImg"  />
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>۲</span> رکعات</div>
            <div className='nafl_namaze__description'>
                <span><strong>رکعتیں : </strong> ۲ رکعات  نفل </span>
                <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعد سورہ قدر (إِنَّا أَنْزَلْنَاهُ) ۳-۳ مرتبہ اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) 27-27 مرتبہ پڑھیں !
                </span>
            </div>
          </div>
  
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>
            <div className='nafl_namaze__description'>
                <span><strong>رکعتیں : </strong> 4 رکعات نفل (۲-۲ رکعات کرکے)</span>
                <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعد سورہ تکاثر (اَلْهٰكُمُ التَّكَاثُرُ) ۱-۱ مرتبہ اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) ۳-۳ مرتبہ پڑھیں !
                </span>
            </div>
          </div>
          <div className='nafl_namaze__item contentSection-card mt-20 mb-20'>
            <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>۲</span> رکعات</div>
            <div className='nafl_namaze__description'>
                <span><strong>رکعتیں : </strong> ۲ رکعات  نفل  </span>
                <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعدسورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) 7-7 مرتبہ پڑھیں ! بعد سلام کے 70 مرتبہ یہ تسبیح پڑھیں!
                </span>
            </div>
          </div>
          <img loading="lazy" title="istighfar" src={tasbeeh} alt="istighfar" className="invert max-w-400" />
          <div className='nafl_namaze__item contentSection-card mt-20 mb-20'>
            <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>۲</span> رکعات</div>
            <div className='nafl_namaze__description'>
                <span><strong>رکعتیں : </strong> ۲ رکعات  نفل  </span>
                <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعدسورہ نَشْرَحْ (أَلَمْ نَشْرَحْ) ۱-۱ مرتبہ سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ)  ۳-۳ مرتبہ پڑھیں ! بعد سلام کے 27 مرتبہ سورہ قدر (إِنَّا أَنْزَلْنَاهُ) پڑھیں!
                </span>
            </div>
          </div>    
          <h3 className="contentSection_subtitle mb-20">سورہ نَشْرَحْ (أَلَمْ نَشْرَحْ)</h3>
          <img loading='lazy' title='img' src={Surah_Nashrah} alt="Surah_NashrahImg" className='max-w-400' /> 
  
          <div className='nafl_namaze__item contentSection-card mt-20 mb-5'>
            <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>
            <div className='nafl_namaze__description'>
                <span><strong>رکعتیں : </strong> 4 رکعات نفل (۲-۲ رکعات کرکے)</span>
                <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ قدر (إِنَّا أَنْزَلْنَاهُ) ۳-۳ مرتبہ اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) ۵۰ - ۵۰ مرتبہ پڑھیں ! بعد سلام کے سجدے میں سر رکھ کر ایک مرتبہ یہ کلمات پڑھیں۔
                </span>
            </div>
          </div>
          <img loading='lazy' title='img' src={dhikr} alt="dhikrImg"  /> 
          <div className='nafl_namaze__item contentSection-card mt-20 salatutTasbeeh'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>
          <div className='nafl_namaze__description'>
              <span><strong>              صلوۃ التسبیح </strong> کی نماز</span>
              <span><strong>رکعتیں : </strong> 4 رکعات  نفل </span>
              <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> نیچے  image میں دیکھیں -    </span>
          </div>
      </div>
      <h3 className="contentSection_subtitle mb-10">صلوۃ التسبیح </h3>
      <img loading='lazy' title='salatTasbeeh' src={salatTasbeeh} alt="salatTasbeeh" className='max-w-400' />
      <div className='nafl_namaze__item contentSection-card mt-20 salatutTasbeeh'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'> درود شریف</div>
          <div className='nafl_namaze__description'>
              <span>1000 مرتبہ</span>
              <span className='nafl_namaze__failat'> اس کے علاوہ   قَضائے عُمری، سورہ یٰسین، سورہ ملک، سورہ رحمان، سورہ مزمل، استغفار (أستغفرالله) اور کلمہ شریف پڑھ سکتے ہیں! جس قدر آپ نفل نماز پڑھ سکیں، وہ پڑھیں!</span>
          </div>
      </div>                
      </Accordion>
      <Accordion title={<div className='d-flex flex-direction-row-reverse gap-10'><span>۲۹</span> <span>ویں شبِ قدر</span></div>}>
      <div className='nafl_namaze__item contentSection-card'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>7</span> مرتبہ</div>
          <div className='nafl_namaze__description'>
              <span>7 مرتبہ یہ دعا پڑھیں۔</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>7</span> مرتبہ</div>
          <div className='nafl_namaze__description'>
              <span>7 مرتبہ سورہ قدر (إِنَّا أَنْزَلْنَاهُ) پڑھیں-</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">: سورہ قدر </h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
            <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>
            <div className='nafl_namaze__description'>
                <span><strong>  رکعتیں : </strong> 4 رکعات نفل (۲-۲ رکعات کرکے)</span>
                <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعد سورہ قدر (إِنَّا أَنْزَلْنَاهُ)  ۱-۱  مرتبہ اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) ۳-۳ مرتبہ پڑھیں ! بعد سلام کے 70 مرتبہ سورہ نَشْرَحْ (أَلَمْ نَشْرَحْ) پڑھیں۔
                </span>
            </div>
        </div>
        <h3 className="contentSection_subtitle mb-20">سورہ نَشْرَحْ (أَلَمْ نَشْرَحْ)</h3>
        <img loading='lazy' title='img' src={Surah_Nashrah} alt="Surah_NashrahImg"  className='max-w-400' /> 
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>
            <div className='nafl_namaze__description'>
                <span><strong>رکعتیں : </strong> 4 رکعات نفل (۲-۲ رکعات کرکے)</span>
                <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> ہر رکعت میں سورہ فاتحہ کے بعد سورہ قدر (إِنَّا أَنْزَلْنَاهُ)  ۱-۱ مرتبہ اور سورہ اخلاص (قُلْ هُوَ اللّٰهُ اَحَدٌ) ۵-۵  مرتبہ پڑھیں ! بعد سلام کے 100 مرتبہ درودِ پاک پڑھیں۔(افضل درود، درودِ ابراہیمی ہے!)
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle mb-20">درودِ ابراہیمی  </h3>
          <img loading='lazy' title='Duroode Ibraheemi' src={duroodeibraheemi} alt="duroode ibraheemi" className='duroodeibraheemi' />
          <div className='nafl_namaze__item contentSection-card mt-20 salatutTasbeeh'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'><span>4</span> رکعات</div>
          <div className='nafl_namaze__description'>
              <span><strong>              صلوۃ التسبیح </strong> کی نماز</span>
              <span><strong>رکعتیں : </strong> 4 رکعات  نفل </span>
              <span className='nafl_namaze__failat'><strong> طریقہ :  </strong> نیچے  image میں دیکھیں -    </span>
          </div>
      </div>
      <h3 className="contentSection_subtitle mb-10">صلوۃ التسبیح </h3>
      <img loading='lazy' title='salatTasbeeh' src={salatTasbeeh} alt="salatTasbeeh" className='max-w-400' />
      <div className='nafl_namaze__item contentSection-card mt-20 salatutTasbeeh'>
          <div className='rakatCounts d-flex gap-10 justify-content-center flex-direction-row-reverse'> درود شریف</div>
          <div className='nafl_namaze__description'>
              <span>1000 مرتبہ</span>
              <span className='nafl_namaze__failat'> اس کے علاوہ   قَضائے عُمری، سورہ یٰسین، سورہ ملک، سورہ رحمان، سورہ مزمل، استغفار (أستغفرالله) اور کلمہ شریف پڑھ سکتے ہیں! جس قدر آپ نفل نماز پڑھ سکیں، وہ پڑھیں!</span>
          </div>
      </div>
      </Accordion>
    </div>
    )
  }

  export const HindiNaflNamaz = () => {
    // const audioRef = useRef(null);
  
    // const handlePlayPause = () => {
    //   if (audioRef.current) {
    //     if (audioRef.current.paused) {
    //       audioRef.current.play();
    //     } else {
    //       audioRef.current.pause();
    //     }
    //   }
    // };
    return (
    <div className="wrapper contentSection-card hindiNaflNamaz">
      <Accordion title="21वीं शबे क़द्र" className="open" defaultOpen={true}>
      <div className='nafl_namaze__item contentSection-card'>
          <span>7 मरतबा  </span>
          <div className='nafl_namaze__description'>
              <span>7 मरतबा ये दुआ पढ़ें।</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <span>7 मरतबा  </span>
          <div className='nafl_namaze__description'>
              <span>7 मरतबा सूरह क़द्र (इऩ्ना अनज़ल नाहु) पढ़ें।</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">सूरह क़द्र :</h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />  
        <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>4 रकात  </span>
          <div className='nafl_namaze__description'>
              <span><strong>रकअतें: </strong> 4 रकात नफ़्ल (2-2 रकात करके)</span>
              <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह क़द्र (इऩ्ना अनज़ल नाहु) और सूरह इखलास (कुल हुवल लाहू अहद) 1-1 मरतबा पढ़े ! बाद सलाम के 70 मरतबा दुरूदे पाक पढ़ें। (अफ़ज़ल दुरूद,  दुरूदे इब्राहीमी है !) 
              </span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-20">दुरूदे इब्राहीमी  :</h3>
        <img loading='lazy' title='Duroode Ibraheemi' src={duroodeibraheemi} alt="duroode ibraheemi" className='duroodeibraheemi' />
        {/* <div className="audioWrapper mt-10">
          <div className="audioplayer-volume">
              <div className="audioplayer-volume-button" title="">
                <a href="javascript:void(0);" onClick={handlePlayPause}> 
                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 384 512" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80L0 432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path></svg>
                </a>
              </div>  
          </div>
          <h3 className="mb-5" style={{flex:"0 0 60%"}}>Surah Falaq</h3>
          <div className="audioplayer">
            <audio ref={audioRef} preload="auto" style={{ width: "0px", height: "0px", visibility: "hidden" }}>
              <source src={suratAudio} type="audio/mp3" />
            </audio>
          </div>
        </div> */}
        <div className='nafl_namaze__item contentSection-card mt-20'>
        <span>2 रकात  </span>
        <div className='nafl_namaze__description'>
            <span><strong>रकअतें: </strong> 2 रकात नफ़्ल</span>
            <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह क़द्र  (इऩ्ना अनज़ल नाहु) 1-1 मरतबा और सूरह इखलास (कुल हुवल लाहू अहद) 3-3 मरतबा पढ़े ! बाद सलाम के 70 मरतबा इस्तिगफ़ार  पढ़ें।
            </span>
        </div>
      </div>
      <h3 className="contentSection_subtitle">इस्तिगफ़ार  :</h3>
      <img loading='lazy' title='istighfar' src={istighfar} alt="istagfarImg"  />
      <div className='nafl_namaze__item contentSection-card mt-20 mb-20 salatutTasbeeh'>
          <span className='rakatCounts'>4 रकात  </span>
          <div className='nafl_namaze__description'>
            <span><strong>सलातुत तस्बीह </strong> की नमाज़:</span>
            <span><strong>रकअतें: </strong> 4 रकअत नफ़्ल। </span>
            <span className='nafl_namaze__failat'><strong> तरीका :  </strong> नीचे image में देखे |
            </span>
          </div>
        </div>
       <h3 className="contentSection_subtitle mb-20">सलातुत तस्बीह  :</h3>
       <img title='img4' src={salatTasbeeh2} alt='naflNamaz4' loading='lazy' className='max-w-400'/>
       <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>दुरूद शरीफ़</span>
           <div className='nafl_namaze__description'>
              <span>1000 मरतबा</span>
                <span className='nafl_namaze__failat'>
                        इसके अलावा <b>क़ज़ा ए उमरी</b>, सूरह यासीन, सूरह मुल्क, सूरह रहमान, सूरह मुज़म्मिल, इस्तिग़फ़ार (अस्तग़फिरुल्लाह) और कलमा शरीफ पढ़ सकते हैं ! जिस क़द्र आप नफ़्ल नमाज़ पढ़ सके वो पढ़े !
                </span>
            </div>
        </div>
      </Accordion>
      <Accordion title="23वीं शबे क़द्र">
      <div className='nafl_namaze__item contentSection-card'>
          <span>7 मरतबा  </span>
          <div className='nafl_namaze__description'>
              <span>7 मरतबा ये दुआ पढ़ें।</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <span>7 मरतबा  </span>
          <div className='nafl_namaze__description'>
              <span>7 मरतबा सूरह क़द्र (इऩ्ना अनज़ल नाहु) पढ़ें।</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">सूरह क़द्र :</h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>4 रकात  </span>
          <div className='nafl_namaze__description'>
              <span><strong>रकअतें: </strong> 4 रकात नफ़्ल (2-2 रकात करके)</span>
              <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह क़द्र (इऩ्ना अनज़ल नाहु) 1-1 मरतबा और सूरह इखलास (कुल हुवल लाहू अहद) 3-3 मरतबा पढ़े !
              </span>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>8 रकात  </span>
          <div className='nafl_namaze__description'>
              <span><strong>रकअतें: </strong> 8 रकात नफ़्ल (2-2 रकात करके)</span>
              <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह क़द्र (इऩ्ना अनज़ल नाहु) और सूरह इखलास (कुल हुवल लाहू अहद) 1-1 मरतबा पढ़े ! बाद सलाम के 70 मरतबा कलमा-ए-तमजीद (तीसरा कलमा) पढ़ें।
              </span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">कलमा-ए-तमजीद (तीसरा कलमा)</h3>
        <img loading='lazy' title='img' src={ kalma3} alt=" kalma3Img" />
        <div className='nafl_namaze__item contentSection-card mt-20 mb-20 salatutTasbeeh'>
          <span className='rakatCounts'>4 रकात  </span>
          <div className='nafl_namaze__description'>
            <span><strong>सलातुत तस्बीह </strong> की नमाज़</span>
            <span><strong>रकअतें: </strong> 4 रकअत नफ़्ल। </span>
            <span className='nafl_namaze__failat'><strong> तरीका :  </strong> नीचे image में देखे |
            </span>
          </div>
        </div>
       <h3 className="contentSection_subtitle mb-20">सलातुत तस्बीह  :</h3>
       <img title='img4' src={salatTasbeeh2} alt='naflNamaz4' loading='lazy' className='max-w-400'/>
       <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>दुरूद शरीफ़</span>
           <div className='nafl_namaze__description'>
              <span>1000 मरतबा</span>
                <span className='nafl_namaze__failat'>
                        इसके अलावा <b>क़ज़ा ए उमरी</b>, सूरह यासीन, सूरह मुल्क, सूरह रहमान, सूरह मुज़म्मिल, इस्तिग़फ़ार (अस्तग़फिरुल्लाह) और कलमा शरीफ पढ़ सकते हैं ! जिस क़द्र आप नफ़्ल नमाज़ पढ़ सके वो पढ़े !
                </span>
            </div>
        </div>
      </Accordion>
      <Accordion title="25वीं शबे क़द्र">
      <div className='nafl_namaze__item contentSection-card'>
          <span>7 मरतबा  </span>
          <div className='nafl_namaze__description'>
              <span>7 मरतबा ये दुआ पढ़ें।</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <span>7 मरतबा  </span>
          <div className='nafl_namaze__description'>
              <span>7 मरतबा सूरह क़द्र (इऩ्ना अनज़ल नाहु) पढ़ें।</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">सूरह क़द्र :</h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>4 रकात  </span>
            <div className='nafl_namaze__description'>
                <span><strong>रकअतें: </strong> 4 रकात नफ़्ल (2-2 रकात करके)</span>
                <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह क़द्र (इऩ्ना अनज़ल नाहु) 1-1 मरतबा और सूरह इखलास (कुल हुवल लाहू अहद) 5 - 5 मरतबा पढ़े ! बाद सलाम के 100 मरतबा कलमा-ए-तय्यब (पहला कलमा) पढ़ें। 
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle mb-10">कलमा-ए-तय्यब (पहला कलमा)</h3>
          <img loading='lazy' title='Kalmae tayyab' src={ kalma1} alt=" Kalmae tayya" />
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>4 रकात  </span>
            <div className='nafl_namaze__description'>
                <span><strong>रकअतें: </strong> 4 रकात नफ़्ल (2-2 रकात करके)</span>
                <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह क़द्र (इऩ्ना अनज़ल नाहु) 3-3 मरतबा और सूरह इखलास (कुल हुवल लाहू अहद) 3-3 मरतबा पढ़े ! बाद सलाम के 70 मरतबा इस्तिगफ़ार  पढ़ें।
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle">इस्तिगफ़ार  :</h3>
          <img loading='lazy' title='istighfar' src={istighfar} alt="istagfarImg"  />
  
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>2 रकात  </span>
            <div className='nafl_namaze__description'>
                <span><strong>रकअतें: </strong> 2 रकात नफ़्ल</span>
                <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह क़द्र (इऩ्ना अनज़ल नाहु) 1-1 मरतबा और सूरह इखलास (कुल हुवल लाहू अहद) 15-15 मरतबा पढ़े ! बाद सलाम के 70 मरतबा कलमा-ए-शहादत (दूसरा कलमा) पढ़ें।
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle mb-20">कलमा-ए-शहादत (दूसरा कलमा)</h3>
          <img loading='lazy' title='Kalma-E-Shahadat' src={kalma2} alt=" Kalma-E-Shahadat" className='max-w-400'  />
          <div className='nafl_namaze__item contentSection-card mt-20 mb-20 salatutTasbeeh'>
          <span className='rakatCounts'>4 रकात  </span>
          <div className='nafl_namaze__description'>
            <span><strong>सलातुत तस्बीह </strong> की नमाज़</span>
            <span><strong>रकअतें: </strong> 4 रकअत नफ़्ल। </span>
            <span className='nafl_namaze__failat'><strong> तरीका :  </strong> नीचे image में देखे |
            </span>
          </div>
        </div>
       <h3 className="contentSection_subtitle mb-20">सलातुत तस्बीह  :</h3>
       <img title='img4' src={salatTasbeeh2} alt='naflNamaz4' loading='lazy' className='max-w-400'/>
       <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>दुरूद शरीफ़</span>
           <div className='nafl_namaze__description'>
              <span>1000 मरतबा</span>
                <span className='nafl_namaze__failat'>
                        इसके अलावा <b>क़ज़ा ए उमरी</b>, सूरह यासीन, सूरह मुल्क, सूरह रहमान, सूरह मुज़म्मिल, इस्तिग़फ़ार (अस्तग़फिरुल्लाह) और कलमा शरीफ पढ़ सकते हैं ! जिस क़द्र आप नफ़्ल नमाज़ पढ़ सके वो पढ़े !
                </span>
            </div>
        </div>
      </Accordion>
      <Accordion title="27वीं शबे क़द्र">
      <div className='nafl_namaze__item contentSection-card'>
          <span>7 मरतबा  </span>
          <div className='nafl_namaze__description'>
              <span>7 मरतबा ये दुआ पढ़ें।</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <span>7 मरतबा  </span>
          <div className='nafl_namaze__description'>
              <span>7 मरतबा सूरह क़द्र (इऩ्ना अनज़ल नाहु) पढ़ें।</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">सूरह क़द्र :</h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>12 रकात  </span>
            <div className='nafl_namaze__description'>
                <span><strong>रकअतें: </strong> 12 रकात नफ़्ल (2-2 रकात करके)</span>
                <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह क़द्र (इऩ्ना अनज़ल नाहु) 1-1 मरतबा और सूरह इखलास (कुल हुवल लाहू अहद) 15-15 मरतबा पढ़े ! बाद सलाम के 70 मरतबा इस्तिगफ़ार  पढ़ें।
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle">इस्तिगफ़ार  :</h3>
          <img loading='lazy' title='istighfar' src={istighfar} alt="istagfarImg"  />
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>2 रकात  </span>
            <div className='nafl_namaze__description'>
                <span><strong>रकअतें: </strong> 2 रकात नफ़्ल</span>
                <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह क़द्र (इऩ्ना अनज़ल नाहु) 3-3 मरतबा और सूरह इखलास (कुल हुवल लाहू अहद) 27-27 मरतबा पढ़े !
                </span>
            </div>
          </div>
  
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>4 रकात  </span>
            <div className='nafl_namaze__description'>
                <span><strong>रकअतें: </strong> 4 रकात नफ़्ल (2-2 रकात करके)</span>
                <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह तकासुर (अल्हाकुमुत तकासुर) 1-1 मरतबा और सूरह इखलास (कुल हुवल लाहू अहद) 3-3 मरतबा पढ़े !
                </span>
            </div>
          </div>
          <div className='nafl_namaze__item contentSection-card mt-20 mb-20'>
            <span>2 रकात  </span>
            <div className='nafl_namaze__description'>
                <span><strong>रकअतें: </strong> 2 रकात नफ़्ल </span>
                <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह इखलास (कुल हुवल लाहू अहद) 7-7 मरतबा पढ़े ! बाद सलाम के 70 मरतबा ये तस्बीह पढ़ें।
                </span>
            </div>
          </div>
          <img loading="lazy" title="istighfar" src={tasbeeh} alt="istighfar" className="invert max-w-400" />
          <div className='nafl_namaze__item contentSection-card mt-20 mb-20'>
            <span>2 रकात  </span>
            <div className='nafl_namaze__description'>
                <span><strong>रकअतें: </strong> 2 रकात नफ़्ल </span>
                <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह नशरह (अलम नशरह) 1-1 मरतबा सूरह इखलास (कुल हुवल लाहू अहद) 3-3 मरतबा पढ़े ! बाद सलाम के 27 मरतबा सूरह क़द्र (इऩ्ना अनज़ल नाहु) पढ़ें।
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle mb-20">सूरह नशरह (अलम नशरह)</h3>
          <img loading='lazy' title='img' src={Surah_Nashrah} alt="Surah_NashrahImg" className='max-w-400' /> 
  
          <div className='nafl_namaze__item contentSection-card mt-20 mb-5'>
            <span>4 रकात  </span>
            <div className='nafl_namaze__description'>
                <span><strong>रकअतें: </strong> 4 रकात नफ़्ल (2-2 रकात करके)</span>
                <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह क़द्र (इऩ्ना अनज़ल नाहु) 3-3 मरतबा और सूरह इखलास (कुल हुवल लाहू अहद) 50-50 मरतबा पढ़े ! बाद सलाम के सज्दे में सर रख कर एक मरतबा ये कलीमत पढ़े।
                </span>
            </div>
          </div>
          <img loading='lazy' title='img' src={dhikr} alt="dhikrImg"  />   
          <div className='nafl_namaze__item contentSection-card mt-20 mb-20 salatutTasbeeh'>
          <span className='rakatCounts'>4 रकात  </span>
          <div className='nafl_namaze__description'>
            <span><strong>सलातुत तस्बीह </strong> की नमाज़</span>
            <span><strong>रकअतें: </strong> 4 रकअत नफ़्ल। </span>
            <span className='nafl_namaze__failat'><strong> तरीका :  </strong> नीचे image में देखे |
            </span>
          </div>
        </div>
       <h3 className="contentSection_subtitle mb-20">सलातुत तस्बीह  :</h3>
       <img title='img4' src={salatTasbeeh2} alt='naflNamaz4' loading='lazy' className='max-w-400'/>
       <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>दुरूद शरीफ़</span>
           <div className='nafl_namaze__description'>
              <span>1000 मरतबा</span>
                <span className='nafl_namaze__failat'>
                        इसके अलावा <b>क़ज़ा ए उमरी</b>, सूरह यासीन, सूरह मुल्क, सूरह रहमान, सूरह मुज़म्मिल, इस्तिग़फ़ार (अस्तग़फिरुल्लाह) और कलमा शरीफ पढ़ सकते हैं ! जिस क़द्र आप नफ़्ल नमाज़ पढ़ सके वो पढ़े !
                </span>
            </div>
        </div>              
      </Accordion>
      <Accordion title="29वीं शबे क़द्र">
      <div className='nafl_namaze__item contentSection-card'>
          <span>7 मरतबा  </span>
          <div className='nafl_namaze__description'>
              <span>7 मरतबा ये दुआ पढ़ें।</span>
              <h2 className="contentSection_subtitle duaeShabeQadr mt-0"> اللَّهُمَّ إِنَّكَ عَفُوٌّ كَرِيمٌ تُحِبُّ الْعَفْوَ فَاعْفُ عَنِّي</h2>
          </div>
        </div>
        <div className='nafl_namaze__item contentSection-card'>
          <span>7 मरतबा  </span>
          <div className='nafl_namaze__description'>
              <span>7 मरतबा सूरह क़द्र (इऩ्ना अनज़ल नाहु) पढ़ें।</span>
          </div>
        </div>
        <h3 className="contentSection_subtitle mb-10">सूरह क़द्र :</h3>
        <img loading='lazy' title='istighfar' src={Surah_Qadr} alt="Surah Qadr" className='max-w-400' />
        <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>4 रकात  </span>
            <div className='nafl_namaze__description'>
                <span><strong>रकअतें: </strong> 4 रकात नफ़्ल (2-2 रकात करके)</span>
                <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह क़द्र (इऩ्ना अनज़ल नाहु) 1-1 मरतबा और सूरह इखलास (कुल हुवल लाहू अहद) 3 - 3 मरतबा पढ़े ! बाद सलाम के 70 मरतबा सूरह नशरह (अलम नशरह) पढ़ें। 
                </span>
            </div>
        </div>
        <h3 className="contentSection_subtitle mb-20">सूरह नशरह (अलम नशरह)</h3>
        <img loading='lazy' title='img' src={Surah_Nashrah} alt="Surah_NashrahImg" className='max-w-400' /> 
          <div className='nafl_namaze__item contentSection-card mt-20'>
            <span>4 रकात  </span>
            <div className='nafl_namaze__description'>
                <span><strong>रकअतें: </strong> 4 रकात नफ़्ल (2-2 रकात करके)</span>
                <span className='nafl_namaze__failat'><strong> तरीका :  </strong> हर रकत में सूरह फातिहा के बाद सूरह क़द्र (इऩ्ना अनज़ल नाहु) 1-1 मरतबा और सूरह इखलास (कुल हुवल लाहू अहद) 5-5 मरतबा पढ़े ! बाद सलाम के 100 मरतबा दुरूदे पाक पढ़ें। (अफ़ज़ल दुरूद, दुरूदे इब्राहीमी है !)
                </span>
            </div>
          </div>
          <h3 className="contentSection_subtitle mb-20">दुरूदे इब्राहीमी  :</h3>
          <img loading='lazy' title='Duroode Ibraheemi' src={duroodeibraheemi} alt="duroode ibraheemi" className='duroodeibraheemi' />
          <div className='nafl_namaze__item contentSection-card mt-20 mb-20 salatutTasbeeh'>
          <span className='rakatCounts'>4 रकात  </span>
          <div className='nafl_namaze__description'>
            <span><strong>सलातुत तस्बीह </strong> की नमाज़</span>
            <span><strong>रकअतें: </strong> 4 रकअत नफ़्ल। </span>
            <span className='nafl_namaze__failat'><strong> तरीका :  </strong> नीचे image में देखे |
            </span>
          </div>
        </div>
       <h3 className="contentSection_subtitle mb-20">सलातुत तस्बीह  :</h3>
       <img title='img4' src={salatTasbeeh2} alt='naflNamaz4' loading='lazy' className='max-w-400'/>
       <div className='nafl_namaze__item contentSection-card mt-20'>
          <span>दुरूद शरीफ़</span>
           <div className='nafl_namaze__description'>
              <span>1000 मरतबा</span>
                <span className='nafl_namaze__failat'>
                        इसके अलावा <b>क़ज़ा ए उमरी</b>, सूरह यासीन, सूरह मुल्क, सूरह रहमान, सूरह मुज़म्मिल, इस्तिग़फ़ार (अस्तग़फिरुल्लाह) और कलमा शरीफ पढ़ सकते हैं ! जिस क़द्र आप नफ़्ल नमाज़ पढ़ सके वो पढ़े !
                </span>
            </div>
        </div>
      </Accordion>
    </div>
    )
  }

